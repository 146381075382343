import React, { useEffect, useState, useCallback, useMemo, useContext } from "react";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import moment from "moment";
import ProjectContext from "../../ProjectContext.js";

import GenAIUsageChart from './GenAIUsageChart';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import GenaiFeatureUsageService from "../../services/api/gen-ai-feature-usage-service.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

const RECORDS_PER_PAGE = "";

const dateRangeOptions = [
  { value: "yesterday", label: "Yesterday" },
  { value: "last7days", label: "Last 7 Days" },
  { value: "last30days", label: "Last 30 Days" },
  { value: "3months", label: "3 Months Ago" },
  { value: "6months", label: "6 Months Ago" },
  { value: "1year", label: "A Year Ago" }
];

const GenaiFeatureUsage = () => {
  const projectDetails = useContext(ProjectContext);
  const history = useHistory();
  if (projectDetails.id == "") {
    history.push("/project/allprojects");
  }
  const project_id = projectDetails.id;
  const INITIAL_FORM_STATE = {
    api_type: [],
    credit_consumed: "",
    from_date: moment().utc().subtract(7, "days").startOf("day").toDate(),
    to_date: moment().utc().endOf("day").toDate(),
    project_id: [project_id],
    subscription_id: "",
  };

  const [genAIFeatureUsage, setGenAIFeatureUsage] = useState([]);
  const [formState, setFormState] = useState(INITIAL_FORM_STATE);
  const [dateRange, setDateRange] = useState({ value: "last7days", label: "Last 7 Days" });
  const [activePage, setActivePage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [apiType, setApiType] = useState([]);

  const handleFormChange = useCallback((field, value) => {
    setFormState((prev) => ({ ...prev, [field]: value }));
  }, []);

  const handleDateRangeChange = useCallback((selectedOption) => {
    setDateRange(selectedOption);
    let fromDate = moment().utc();
    let toDate = moment().utc().endOf("day");

    switch (selectedOption?.value) {
      case "yesterday":
        fromDate = moment().utc().subtract(1, "days").startOf("day");
        toDate = moment().utc().subtract(1, "days").endOf("day");
        break;
      case "last7days":
        fromDate = moment().utc().subtract(7, "days").startOf("day");
        break;
      case "last30days":
        fromDate = moment().utc().subtract(30, "days").startOf("day");
        break;
      case "3months":
        fromDate = moment().utc().subtract(3, "months").startOf("day");
        break;
      case "6months":
        fromDate = moment().utc().subtract(6, "months").startOf("day");
        break;
      case "1year":
        fromDate = moment().utc().subtract(1, "years").startOf("day");
        break;
      default:
        return;
    }

    setFormState((prev) => ({
      ...prev,
      from_date: fromDate.toDate(),
      to_date: toDate.toDate(),
    }));
  }, []);


  const handleFilterReset = useCallback(() => {
    setFormState(INITIAL_FORM_STATE);
    setActivePage("");
    setDateRange({ value: "last7days", label: "Last 7 Days" }); // Reset the date range filter
  }, []);

  // This function fetches Gen AI feature usage based on filters
  const fetchGenAIUsage = useCallback(async () => {
    setIsLoading(true);
    try {
      console.log("Fetching data with filters:", formState);
      const result = await GenaiFeatureUsageService.getGenAIFeatureUsage(
        formState,
        activePage,
        RECORDS_PER_PAGE
      );
      console.log(result)
      if (result?.status) {
        setGenAIFeatureUsage(result.data.result);
      }
    } catch (error) {
      console.error("Error fetching GenAI usage:", error);
    } finally {
      setIsLoading(false);
    }
  }, [formState, activePage]);

  // This function fetches the API types and project details only once
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      // Fetch API types
      const apiResult = await GenaiFeatureUsageService.getGenAIApiType();
      if (apiResult?.status) {
        setApiType(apiResult.data);
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Fetch data once on component mount
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Fetch GenAI usage whenever formState changes
  useEffect(() => {
    fetchGenAIUsage();
  }, [formState, activePage, fetchGenAIUsage]);

  const handleFilterSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setActivePage(1); // Reset to the first page when filters change
      fetchGenAIUsage();
    },
    [fetchGenAIUsage]
  );

  const apiTypeOptions = useMemo(
    () => apiType.map((type) => ({ value: type, label: type })),
    [apiType]
  );

  return (
    <div className="content mt-30">
      <Container>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <CardTitle tag="h4">GenAI Feature Usage</CardTitle>
                </div>

                <Form onSubmit={handleFilterSubmit}>
                  <Row>
                    <Col md="3">
                      <label>Select API Type</label>
                      <Select
                        isMulti
                        isClearable
                        placeholder="Select API Type"
                        options={apiTypeOptions}
                        value={apiTypeOptions.filter((option) =>
                          formState.api_type.includes(option.value)
                        )}
                        onChange={(selectedOptions) =>
                          handleFormChange(
                            "api_type",
                            selectedOptions
                              ? selectedOptions.map((opt) => opt.value)
                              : []
                          )
                        }
                      />
                    </Col>
                    <Col md="3">
                      <label>Date Range</label>
                      <Select
                        isClearable
                        placeholder="Select Range"
                        options={dateRangeOptions}
                        value={dateRange}
                        onChange={handleDateRangeChange}
                      />
                    </Col>
                    <Col md="3">
                    <label>From Date</label>
                      <ReactDatetime
                        value={moment(formState.from_date)}
                        onChange={(date) =>
                          handleFormChange("from_date", moment(date).toDate())
                        }
                        inputProps={{
                          className: "form-control",
                          placeholder: "From Date",
                        }}
                      />
                    </Col>
                    <Col md="3">
                    <label>To Date</label>
                      <ReactDatetime
                        value={moment(formState.to_date)}
                        onChange={(date) =>
                          handleFormChange("to_date", moment(date).toDate())
                        }
                        inputProps={{
                          className: "form-control",
                          placeholder: "To Date",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md="6">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading..." : "Search"}
                      </Button>
                      <Button
                        className="btn-round"
                        color="warning"
                        type="button"
                        onClick={handleFilterReset}
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardHeader>

              <CardBody>
                <GenAIUsageChart data={genAIFeatureUsage} allApiTypes={apiType} />
              </CardBody>

            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GenaiFeatureUsage;