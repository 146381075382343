import React, { useState, useRef, useMemo } from 'react';
import { useIntersection } from '../helpers/intersectionObserver';

const LazyLoadImage = ({ url, isframe = "", imageResolution = "", failCallback, style={} }) => {
  const [isInView, setIsInView] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const imgRef = useRef();

  const imgeRes = useMemo(() => {
    if (typeof imageResolution === "string") {
      try {
        return JSON.parse(imageResolution);
      } catch (error) {
        console.error("Invalid JSON in imageResolution:", error);
        return {};
      }
    }
    return imageResolution || {};
  }, [imageResolution]);

  useIntersection(imgRef, () => {
    setIsInView(true);
  });

  const handleOnLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div
      ref={imgRef}
      style={{width:'100%',height: '100%',
        ...(isframe && { position: "absolute" }),
        ...(imgeRes.top && {
          top: `${imgeRes.top}px`,
          left: `${imgeRes.left}px`,
          right: `${imgeRes.right}px`,
          transform: imgeRes.scale ? `scale(${imgeRes.scale})` : undefined, // Proper scale usage
        }),
      }}
    >
      {isInView && (
        <img
            style={{width:'100%',height:'100%',objectFit:'cover', ...style}}
          src={url}
          onLoad={handleOnLoad}
          onError={failCallback}
        />
      )}
      {!isLoaded?
       <div className='template_loader'></div> 
        :null
      }
    </div>
  );

};

export default LazyLoadImage;