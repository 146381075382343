import React, { useContext, useEffect, useState } from 'react'
import { Button, Alert, Spinner, Card, CardBody, CardHeader, CardTitle, FormGroup, Input, Label, Col, Row, Table, Form } from 'reactstrap'
import ProjectContext from '../../ProjectContext'
import notificationsService from "../../services/api/notifications-service";
import Select from 'react-select';
import SweetAlert from "react-bootstrap-sweetalert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IzootoSegment from './IzootoSegment';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

const NotificationCard = (props) => {
  let message = '';
  let guid = '';
  let slug = '';
  let type = 'general';
  if (props.location.state && props.location.state.guid) {
    message = props.location.state.message;
    guid = props.location.state.guid;
    slug = props.location.state.slug;
    type = 'article_promotion';
  }

  const projectDetails = useContext(ProjectContext);
  const projectId = projectDetails.id;
  const platformLookup = {
    WEB: '1',
    ANDROID: '2',
    IOS: '3',
    all: "all"
  }

  const [messageState, setMessageState] = useState("");
  const [messageerror, setMessageError] = useState("");
  // const [segmentState, setSegmentState] = useState("");
  // const [segmentError, setSegmentError] = useState("");
  const [categoryState, setCategoryState] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [webViewState, setWebViewState] = useState("");
  const [webViewError, setWebViewError] = useState("");
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const [showLoader, setShowLoader] = useState(false);
  const [formdetails, setFormDetails] = useState({ title: projectDetails.name, message: message, projectid: projectId, guid: guid, slug: slug, type: type, platform: "", segmentId: "", messageService: '', cat_guid: "", webViewLink: "", scheduled_at: "instant" });
  const [checkedPlatforms, setCheckedPlatforms] = useState([]);
  const [showSaveButton, setShowSaveButton] = useState(true);

  const [showWebView, setShowWebView] = useState(false)
  const [isIzooto, setIsIzooto] = useState(true)
  const [platformObject, setPlatformObject] = useState({})
  const [availablePlatforms, setAvailablePlatforms] = useState([])
  const [showSegmentDropDown, setShowSegmentDropDown] = useState({
    WEB: false,
    ANDROID: false,
    IOS: false
  })
  const [segmentId, setSegmentId] = useState({
    WEB: "",
    ANDROID: "",
    IOS: ""
  })
  const [segmentState, setSegmentState] = useState({
    WEB: "",
    ANDROID: "",
    IOS: ""
  })
  const [segmentError, setSegmentError] = useState({
    WEB: "",
    ANDROID: "",
    IOS: ""
  })
  const [scheduleDateError, setScheduleDateError] = useState("")
  const [scheduleDateState, setScheduleDateState] = useState("")
  const [showscehduleDate, setShowScheduleDate] = useState(false)

  const [titleState, setTitleState] = useState("")
  const [titleError, setTitleError] = useState("")
  const [titleMessage, setTitleMessage] = useState(null)
  const [genrateTitleLoader, setGenrateTitleLoader] = useState(false)

  useEffect(() => {
    (async () => {
      if (props.platformObject) {
        setPlatformObject(props.platformObject);
        const allPlatforms = Object.keys(props.platformObject);
        setAvailablePlatforms(allPlatforms)
        setCheckedPlatforms(allPlatforms)
        setFormDetails({
          ...formdetails,
          platform: allPlatforms
        })
      }
    })()
  }, [])

  const showMessage = (text, type, color) => {
    setTitleMessage({ text, type, color });
    setTimeout(() => setTitleMessage(null), 5000);
  };

  const generateTitle = async () => {
    setGenrateTitleLoader(true)
    try {
      const response = await notificationsService.generateNotificationsTitle({
        project_id: projectId,
        message: formdetails.message,
      });
      console.log(response)
      if (response && response.status && response?.data?.title) {
        setFormDetails({
          ...formdetails,
          title: response?.data?.title,
        })
        showMessage("Title generated successfully", "success", "green");
      } else {
        console.log(response.error ?? "something went wrong")
        setFormDetails({
          ...formdetails,
          title: projectDetails.name,
        })
        showMessage(response?.error?.message || "Something went wrong", "error", "red");
      }

    } catch (error) {
      console.log(error)
      setFormDetails({
        ...formdetails,
        title: projectDetails.name,
      })
      showMessage(error.message, "error", "red");
    }
    finally {
      setGenrateTitleLoader(false)
    }

  }
  const handleCategoriesChange = (category) => {
    console.log(category)
    setFormDetails({
      ...formdetails,
      cat_guid: category.id,
      message: category.desc ? category.desc : category.name
    })
    setMessageError("")
    setMessageState("")
    setCategoryState("");
    setCategoryError("")
  }

  const handleChange = (event) => {
    setFormDetails({
      ...formdetails,
      [event.target.id]: (event.target.value),
    });

    if (event.target.id === "message") {
      setMessageError("")
      setMessageState("")
    }
    if (event.target.id === "title") {
      setTitleState("")
      setTitleError("")
    }
    if (event.target.id === "webViewLink") {
      setWebViewState("");
      setWebViewError("");
    }
  }

  const validateForm = async () => {
    let validated = true;
    if (formdetails.message.trim() === '') {
      setMessageState("has-danger");
      setMessageError("This field is required");
      validated = false;
    } else {

      if (formdetails.message.length > 160) {
        setMessageState("has-danger");
        setMessageError("Maximum 160 characters are allowed.");
        validated = false;
      } else {
        setMessageState("has-success");
        setMessageError("");
      }
    }

    if (formdetails.title.trim() === '') {
      setTitleState("has-danger");
      setTitleError("This field is required");
      validated = false;
    }

    let updatedSegmentState = { ...segmentState };
    let updatedSegmentError = { ...segmentError };

    Object.keys(showSegmentDropDown).forEach((platform) => {
      if (showSegmentDropDown[platform] && segmentId[platform] === "") {
        updatedSegmentState[platform] = "has-danger";
        updatedSegmentError[platform] = "Please Select Segment";
        validated = false;
      } else {
        updatedSegmentState[platform] = "";
        updatedSegmentError[platform] = "";
      }
    });

    setSegmentState(updatedSegmentState);
    setSegmentError(updatedSegmentError);

    if (formdetails.type === 'category' && formdetails.cat_guid === "") {
      setCategoryState("has-danger");
      setCategoryError("Please Select Category")
    }
    if (showWebView && formdetails.webViewLink === "") {
      setWebViewState("has-danger");
      setWebViewError("Please enter a web link.");
      validated = false;
    }

    if (formdetails.type === 'general' && formdetails.webViewLink !== "") {

      const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

      if (!urlPattern.test(formdetails.webViewLink)) {
        setWebViewState("has-danger");
        setWebViewError("Please enter a valid URL.");
        validated = false;
      } else {
        setWebViewState("");
        setWebViewError("");
      }
    }

    if (formdetails.scheduled_at !== "instant" && formdetails.scheduled_at === "") {
      setScheduleDateError("Please select a date to schedule");
      setScheduleDateState("has-danger");
      validated = false;
    }

    if (scheduleDateError !== "") {
      validated = false;
    }

    if (!validated) { return false; }


    let platformString = "";
    for (let i in checkedPlatforms) {
      platformString += `${platformLookup[checkedPlatforms[i]]},`
    }
    const formdata = {
      ...formdetails,
      platform: platformString
    }
    let sendNotification = await notificationsService.sendNotification(formdata);
    setShowLoader(true);
    setShowSaveButton(false);
    if (projectDetails.isContractExpired) {
      setShowLoader(false)
      setShowSaveButton(true)
      projectDetails.setContractExpiredAlert(projectDetails);
    }
    else if (sendNotification.status && sendNotification.data) {
      setTimeout(() => {
        setShowLoader(false)
        setAlert({ show: true, type: 'success', message: 'Notification was sent Successfully' });
      }, 1000);

      setTimeout(() => {
        setAlert({ show: false, type: '', message: '' });
        window.location.href = "/notifications/dashboard";
      }, 5000);


    } else {
      if (sendNotification.error) {
        setTimeout(() => {
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({ show: true, type: 'danger', message: 'Due to Quota limit reached.Notification could not be sent.Upgrade plan.' });
        }, 1000);
      } else {
        setTimeout(() => {
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({ show: true, type: 'danger', message: 'Notification could not be sent, Please try again.' });
        }, 1000);
      }
      setTimeout(() => {
        setAlert({ show: false, type: '', message: '' });
      }, 5000);
    }
  }
  const handleSubmit = function (e) { validateForm(); e.preventDefault() }

  const handlePlatformChange = (value, checked) => {

    if (!(formdetails.type === "article_promotion") && (value === "all" || value === "WEB")) {
      if (checked) {
        setFormDetails({
          ...formdetails,
          type: "general"
        })
      }
    }
    if (value === 'all') {
      if (checked) {
        setCheckedPlatforms(availablePlatforms);
      }
    } else {
      let newPlatforms;
      if (checked) {
        newPlatforms = [...checkedPlatforms, value];
      } else {
        newPlatforms = checkedPlatforms.filter(item => item !== value && item !== "all");
      }
      if (newPlatforms.length) {
        setCheckedPlatforms(newPlatforms)
      }
    }
  }
  const handleScreenTypeChange = (value) => {
    setFormDetails({
      ...formdetails,
      type: value,
      message: "",
      guid: "",
      slug: "",
      cat_guid: "",
      webViewLink: "",
    })
    setShowWebView(false)
    setMessageError("")
    setMessageState("")
    setCategoryState("");
    setCategoryError("")
  }

  const handleWebViewChange = (e) => {
    setShowWebView(e.target.checked);
  }

  const handleScheduleTimeChange = async (event) => {
    const selectedTime = moment(event._d);

    const now = moment();
    if (selectedTime.isBefore(now, "minute")) {
      setScheduleDateError("Selected time must be in the future.");
      setScheduleDateState("has-danger");
      return;
    }
    setFormDetails({
      ...formdetails,
      scheduled_at: selectedTime.utc()
    });
    setScheduleDateError("");
    setScheduleDateState("");
  };

  const disablePastDates = (current) => {
    const now = moment().subtract(1, "days");
    return current.isSameOrAfter(now, "minute");;
  }
  const handleScheduleNotificationButtonClick = async (value) => {
    const isInstant = value === "instant";
    setShowScheduleDate(!isInstant);
    setScheduleDateError("");
    setScheduleDateState("");
    setFormDetails({
      ...formdetails,
      scheduled_at: isInstant ? "instant" : ""
    });
  };

  let platFormElements = [];
  if (availablePlatforms) {
    const platforms = availablePlatforms;
    for (const key of platforms) {
      platFormElements.push(
        <div className='form-check fs'>
          <Label check key={key}>
            <Input
              checked={checkedPlatforms.includes(key)}
              value={key}
              id="platform"
              name="platform"
              type="checkbox"
              onChange={(e) => handlePlatformChange(key, e.target.checked)}
            />
            {key} ({platformObject[key]}) <span className="form-check-sign" />
          </Label>
        </div>
      )
    }
  }

  const screenTypeArray = [
    {
      label: "General",
      value: "general"
    },

    {
      label: "Live TV",
      value: "live_tv"
    },
    {
      label: "Unread Screen",
      value: "unread_screen"
    },
    {
      label: "Category Screen",
      value: "category"
    },
  ]

  let categoryElements = [];
  for (let category of screenTypeArray) {
    categoryElements.push(
      <div className='form-check-radio fs custom-radio'>
        <Label check >
          <Input
            checked={formdetails.type === (category.value)}
            value={formdetails.type}
            id="category"
            name="category"
            type="radio"
            onChange={(e) => handleScreenTypeChange(category.value)}
          />
          {category.label} <span className="form-check-sign" />
        </Label>

      </div>
    )
  }
  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4" className="heding_dsh_h4">Send Push Notification</CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontol form" action="" id="sendNotification" method="POST" onSubmit={handleSubmit}>
            <div className='consFom fulconsFom'>
              <Label className="lblNme">Platform</Label>
              <div className="inptFom mb0">
                <FormGroup>
                  <div className='form-check fs'>
                    <Label check key="all">
                      <Input
                        value="all"
                        id="platform"
                        name="platform"
                        type="checkbox"
                        onChange={(e) => handlePlatformChange("all", e.target.checked)}
                        checked={checkedPlatforms.length === availablePlatforms.length}
                      />
                      ALL <span className="form-check-sign" />
                    </Label>
                  </div>
                  {platFormElements}
                </FormGroup>
              </div>
            </div>

            {!(checkedPlatforms.includes("WEB")) && (
              <div className='consFom fulconsFom'>
                <Label className="lblNme">Target Screen</Label>
                <div className="inptFom mb0">
                  <FormGroup>
                    {categoryElements}
                    {formdetails.type === 'category' && (
                      <div>
                        <Select
                          options={props.categories}
                          placeholder="Select Category"
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.name}
                          onChange={handleCategoriesChange}
                        />
                        {categoryState === "has-danger" && (
                          <Label className="error" style={{ color: 'red' }}>
                            {categoryError}
                          </Label>
                        )}
                      </div>
                    )}
                  </FormGroup>
                </div>
              </div>
            )}

            {(formdetails.type === "general" && (
              <div className='consFom fulconsFom '>
                <Label className="lblNme">Web Link</Label>
                <div className='inptFom mb2'>
                  <FormGroup>
                    <div className='form-check fs'>
                      <Label check>
                        <Input
                          id="webViewLink"
                          name="webViewLink"
                          type="checkbox"
                          onChange={handleWebViewChange}
                          checked={showWebView}
                        />
                        Send web link notification  <span className="form-check-sign" />
                      </Label>
                    </div>
                  </FormGroup>
                </div>
                {showWebView && (
                  <div className='inptFom'>
                    <FormGroup className={webViewState}>
                      <Input
                        name="webViewLink"
                        id="webViewLink"
                        type="url"
                        onChange={handleChange}
                        value={formdetails.webViewLink}
                        placeholder="Enter your link here..."
                        className="form-control"
                      />
                      {webViewState === "has-danger" ? (
                        <Label className="error">
                          {webViewError}
                        </Label>
                      ) : null}
                    </FormGroup>

                  </div>)}
              </div>
            ))}

            <div className='consFom fulconsFom'>
              <div inputFom style={{ display: 'flex', marginBottom: '10px' , gap: '10px' }}>


                <Label className="lblNme">Title</Label>
                {genrateTitleLoader ? (
                  <Spinner size="sm" color="primary" />
                ) : (
                  <>
                    <Button
                      className={`btn-round btnBlue`}
                      onClick={formdetails.message ? generateTitle : undefined}
                      color={formdetails.message ? "info" : "dark"}
                      size="sm"
                      type="button"
                      // style={{ marginLeft: '10px', marginBottom: '5px' }}
                      disabled={!formdetails.message}
                      data-tip={!formdetails.message ? "Write message to generate title" : "Generate title using AI"}
                    >
                      ✨ Generate
                    </Button>
                    <ReactTooltip place="top" effect="solid" />
                  </>)}

              </div>
              <div className="inptFom">

                <FormGroup className={titleState} style={{ marginBottom: '10px' }}>
                  <Input
                    name="title"
                    id="title"
                    type="text"
                    onChange={handleChange}
                    value={formdetails.title}
                    placeholder="Enter your title here..."
                    style={{
                      fontSize: '14px',
                      padding: '8px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  />
                  <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                    {titleState === "has-danger" && (
                      <Label className="error" style={{ color: "red", fontSize: "12px" }}>
                        {titleError}
                      </Label>
                    )}
                    {titleMessage && (
                      <Label className={titleMessage.type} style={{ color: titleMessage.color, fontSize: "12px" }}>
                        {titleMessage.text}
                      </Label>
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>

            <div className='consFom fulconsFom'>
              <Label className="lblNme">Message</Label>
              <div className="inptFom">
                <FormGroup className={messageState}>
                  <Input
                    name="message"
                    id="message"
                    type="textarea"
                    onChange={handleChange}
                    value={formdetails.message}
                    placeholder="Enter your message here..."
                  />
                  {messageState === "has-danger" ? (
                    <Label className="error" style={{ color: 'red' }}>
                      {messageerror}
                    </Label>
                  ) : null}
                </FormGroup>
              </div>
            </div>



            {checkedPlatforms.map((key) =>
              platformObject[key] === "izooto" && (
                <IzootoSegment
                  key={key}
                  propsPlatform={key}
                  formdetails={formdetails}
                  setFormDetails={setFormDetails}
                  showSegmentDropDown={showSegmentDropDown}
                  setShowSegmentDropDown={setShowSegmentDropDown}
                  segmentId={segmentId}
                  setSegmentId={setSegmentId}
                  segmentState={segmentState}
                  setSegmentState={setSegmentState}
                  segmentError={segmentError}
                  setSegmentError={setSegmentError}
                />
              )
            )}


            <div className='consFom fulconsFom'>
              <Label className="lblName">Timing</Label>
              <div className='inputFom'>
                <div className='form-check-radio fs custom-radio'>
                  <Label check>
                    <Input
                      checked={formdetails.scheduled_at === "instant"}
                      value="instant"
                      name="schedule"
                      type="radio"
                      onClick={(e) => handleScheduleNotificationButtonClick("instant")}
                    />
                    Send Immediately
                    <span className="form-check-sign" />
                  </Label>
                </div>
                <div className='form-check-radio fs custom-radio'>
                  <Label check>
                    <Input
                      value="schedule"
                      name="schedule"
                      type="radio"
                      onClick={(e) => handleScheduleNotificationButtonClick("schedule")}
                    />
                    Schedule
                    <span className="form-check-sign" />
                  </Label>
                  {showscehduleDate && <>
                    <Row>
                      <Label>Schedule Time <span class="text-danger"> * </span></Label>
                      <FormGroup className={scheduleDateState}>
                        <Col>
                          <ReactDatetime
                            name="scheduleTime"
                            id="scheduleTime"
                            isValidDate={disablePastDates}
                            onChange={handleScheduleTimeChange}
                            dateFormat={"DD/MM/YYYY"}
                            closeOnSelect
                          />
                        </Col>
                        {scheduleDateState ===
                          "has-danger" ? (
                          <label className="error">
                            {scheduleDateError}
                          </label>
                        ) : null}
                      </FormGroup>

                    </Row>
                  </>}
                </div>
              </div>
            </div>
            <div className="dflLft">
              {showSaveButton ? <Button
                className="btn-round btnBlue"
                type="button"
                onClick={validateForm}
              >
                Send Notification
              </Button> : ''}
              {alert.show ? <Alert color={alert.type}>
                {alert.message}
              </Alert> : ''}
              {showLoader ? <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner> : ''}
            </div>
          </Form>
        </CardBody>

      </Card>

      <ToastContainer autoClose={5000} position='bottom-right' />
    </>
  )
}

export default NotificationCard