import Customizations from "./views/customizations/Customizations.js";
import CreateProject from "./views/projects/CreateProject.js";
import AllProjects from "./views/projects/AllProjects.js";
import ProjectSettings from "./views/projects/ProjectSettings.js";
import Categories from "./views/content/Categories.js";
import EditCategoryMeta from "./views/content/EditCategoryMeta.js";
import Articles from "./views/content/Articles.js";
import Users from "./views/projects/Users.js";
import InvitedUsers from "./views/projects/InvitedUsers.js";
import Authorize from "./views/projects/Authorize.js";
import Home from "./views/dashboard/Home";
import StoryBoard from "./views/webstory/StoryBoard";
import StoriesList from "./views/webstory/StoriesList";
import StoryPageList from "./views/webstory/StoryPageList";
import StoryTemplate from "./views/webstory/StoryTemplate";
import Provider from "./views/notifications/Provider.js";
import NotificationsDashboard from "./views/notifications/Dashboard.js";
import ProjectSuspended from "./views/projects/ProjectSuspended.js";
import PlanDetails from "./views/plan/PlanDetails.js";
import Plan from "./views/plan/Plans";
import Checkout from "./views/plan/Checkout.js";
import WPWebstories from "./views/content/WPWebstories.js";
import AppsManager from "./views/sortd-apps/AppsManager.js";
import PaidArticleTransactions from "./views/paidarticles/PaidArticleTransactions.js";
import Features from "./views/features/Features.js";
import AppCustomization from "./views/sortd-apps/customizations/Customizations.js";
import AppConfigVersions from "./views/sortd-apps/appconfig/AppConfigVersion.js";
import AndroidAppBuild from "./views/sortd-apps/android/AndroidAppBuild.js";
import IOSAppBuild from "./views/sortd-apps/iOS/IOSAppBuild.js";
import Subscribe from "./views/plan/Subscription.js";
import ArticleDetails from "./views/content/ArticleDetails";
import Transactions from "./views/plan/Transactions.js";
import Authors from "./views/content/Authors.js";
import EditAuthor from "./views/content/EditAuthor.js";
import Subscriptions from "./views/plan/Subscriptions.js";
import SubscriptionDetails from "./views/subscription/SubscriptionDetails.js";
import PayManual from "./views/subscription/PayManual";
import PageNotMatched from "./views/dashboard/PageNotMatched.js";

import jwplayerNav from "./views/jwplayer/jwplayerNav.js";

import JwplayerNavForSingleMedia from "./views/jwplayer/JwplayerNavForSingleMedia.js";
import Chapter from "./views/jwplayer/Chapter.js";
import CTVAppGeneralSettings from './views/ctv/CTVGeneralSettings.js'
import CTVCustomizations from "./views/ctv/CTVCustomizations.js";
import CTVAppManager from "./views/ctv/CTVmanager.js";
import ReorderSection from "./views/ctv/ReorderSection.js";
import Dashboard from "./views/connectedtv/Dashboard.js";
import ManageCategories from "./views/connectedtv/ManageCategories.js";
import ContentLibrary from "./views/connectedtv/ContentLibrary.js";
import AddCategory from "./views/connectedtv/AddCategory.js";
import EditCategory from './views/connectedtv/EditCategory.js';
import ReorderCategories from './views/connectedtv/ReorderCategories.js';
import ImportVideosDashboard from "./views/connectedtv/ImportVideosDashboard.js";
import ImportVideo from "./views/connectedtv/ImportVideo.js";
import EditVideo from "./views/connectedtv/EditVideo.js";
import EditImportVideo from "./views/connectedtv/EditVideoImporter.js";
import ImporterLogs from "./views/connectedtv/ImporterLogs.js";
import AndroidTVCustomizations from "./views/connectedtv/AndroidTVConfig/Customizations.js";
import IosTVCustomizations from "./views/connectedtv/IosTVConfig/Customizations.js";
import AndroidConfigVersion from "./views/connectedtv/AndroidTVConfig/AndroidConfigVersions.js";
import IosConfigVersion from "./views/connectedtv/IosTVConfig/IosConfigVersions.js";

import AnalysePageHeaders from './views/pageanalyse/index.js'
import Taxonomies from "./views/content/Taxonomies.js";
import GamesListing from "./views/games/GamesListing.js";
import CreateGames from "./views/games/CreateGames.js";
import GamesSettings from "./views/games/GamesSetting.js";
import GamesDashboard from "./views/games/Dashboard.js";
import RedirectionUrls from "./views/redirectionurls/RedirectionUrls.js";
import UserProfileDashboard from "./views/usersprofile/UserProfileDashboard.js";

import UserProfileDashboard2 from "./views/usersprofile/UserProfileDashboard2.js";
import UserProfileDetailedView from "./views/usersprofile/UserProfileDetailedView.js";
import MembershipSubscriptions from "./views/membership/MembershipSubscriptions.js";
import MembershipTransactions from "./views/membership/MembershipTransactions.js";
import GenaiFeatureUsage from "./views/GenaiFeatureUsage/GenaiFeatureUsage.js";
var routes = [
    // {
    //   path: "/home",
    //   name: "Dashboard",
    //   icon: "nc-icon nc-bank",
    //   component: Dashboard,
    //   layout: "/dashboard",
    // },
    {
        path: "/home",
        name: "Dashboard",
        icon: "nc-icon nc-bank",
        component: Home,
        layout: "/dashboard",
        webstoryOnlyRoute: true,
    },

    {
        collapse: true,
        visible: false,
        name: "Webstory",
        icon: "nc-icon nc-single-02",
        state: "webstory",
        views: [
            {
                path: "/webstory/templates",
                name: "Story Builder",
                icon: "nc-icon nc-bank",
                component: StoryTemplate,
                layout: "/dashboard",
                webstoryOnlyRoute: true,
            },
            {
                path: "/webstory/list/:tabParams",
                name: "Story List",
                icon: "nc-icon nc-bank",
                component: StoriesList,
                layout: "/contentsettings",
                webstoryOnlyRoute: true,
            },
            {
                path: "/webstory/:slug/:storyId",
                name: "Story Builder",
                icon: "nc-icon nc-bank",
                component: StoryPageList,
                layout: "/dashboard",
                visible: false,
                webstoryOnlyRoute: true,
            },
            {
                path: "/storyboard/:slug/:page/:storyId",
                name: "Story Builder",
                icon: "nc-icon nc-bank",
                component: StoryBoard,
                layout: "/dashboard",
                visible: false,
                webstoryOnlyRoute: true,
            },
        ],
    },
    {
        path: "/analyse-url",
        name: "Analyse Webpage",
        icon: "nc-icon nc-globe",
        component: AnalysePageHeaders,
        layout: "/project",
    },
    // {
    //   path: "/payment",
    //   name: "Payment Settings",
    //   icon: "nc-icon nc-money-coins",
    //   component: Customizations,
    //   layout: "/user",
    // },
    {
        path: "/users",
        name: "Subuser Management",
        icon: "nc-icon nc-circle-10",
        component: Users,
        layout: "/project",
        webstoryOnlyRoute: true,
    },
   
    // {
    //   path: "/platforms",
    //   name: "Platform Settings",
    //   icon: "nc-icon nc-app",
    //   component: Customizations,
    //   layout: "/contentsettings",
    // },
    {
        collapse: true,
        visible: true,
        name: "Content Settings",
        icon: "nc-icon nc-chart-pie-36",
        state: "componentsContentCollapse",
        webstoryOnlyRoute: true,
        views: [
            {
                path: "/categories",
                name: "Categories",
                mini: "C",
                component: Categories,
                layout: "/contentsettings",
                webstoryOnlyRoute: true,
            },
            {
                path: "/taxonomytypes",
                name: "Taxonomy Types",
                mini: "T",
                component: Taxonomies,
                layout: "/contentsettings",
                webstoryOnlyRoute: true,
            },
            {
                path: "/authors",
                name: "Authors",
                mini: "A",
                component: Authors,
                layout: "/contentsettings",
                webstoryOnlyRoute: true,
            },
            {
                path: "/articles",
                name: "Articles",
                mini: "A",
                component: Articles,
                layout: "/contentsettings",
            },
            {
                path: "/wpwebstories",
                name: "WPWebstories",
                mini: "WP",
                component: WPWebstories,
                layout: "/contentsettings",
            },
            {
                path: "/webstory/list/published",
                name: "Web Story",
                mini: "W",
                component: StoriesList,
                layout: "/contentsettings",
                webstoryOnlyRoute: true,
            },
        ],
    },
    {
        collapse: true,
        visible: false,
        name: "Author Details",
        icon: "nc-icon nc-single-02",
        state: "author",
        views: [
            {
                path: "/editauthor",
                visible: false,
                component: EditAuthor,
                layout: "/contentsettings",
                webstoryOnlyRoute: true,
            },
        ],
    },
    // {
    //   path: "/categories",
    //   name: "Content Settings",
    //   icon: "nc-icon nc-chart-pie-36",
    //   component: Categories,
    //   layout: "/contentsettings",
    // },
    {
        collapse: true,
        visible: false,
        name: "Article Management",
        icon: "nc-icon nc-single-02",
        state: "componentsArticleCollapse",
        views: [
            {
                visible: false,
                path: "/article-details/:articleId",
                name: "Article Details",
                mini: "A",
                component: ArticleDetails,
                layout: "/contentsettings",
            },
        ],
    },
    {
        path: "/customizations",
        name: "Customizations",
        icon: "nc-icon nc-controller-modern",
        component: Customizations,
        layout: "/config",
        webstoryOnlyRoute: true,
    },
    {
        path: "/dashboard",
        name: "Notifications",
        // icon: "nc-icon nc-alert-circle-i",
        icon: "nc-icon nc-bell-55",
        component: NotificationsDashboard,
        layout: "/notifications",
    },
    {
        collapse: true,
        visible: false,
        name: "Project Management",
        icon: "nc-icon nc-single-02",
        state: "componentsProjectCollapse",
        views: [
            {
                path: "/allprojects",
                name: "Select",
                mini: "S",
                component: AllProjects,
                layout: "/project",
                webstoryOnlyRoute: true,
            },
            {
                path: "/pagenotfound",
                name: "Select",
                mini: "S",
                visible: false,
                component: PageNotMatched,
                layout: "/project",
                webstoryOnlyRoute: true,
            },
            {
                path: "/create",
                name: "Create",
                mini: "C",
                component: CreateProject,
                layout: "/project",
                webstoryOnlyRoute: true,
            },
            {
                path: "/settings",
                name: "Project Settings",
                mini: "S",
                component: ProjectSettings,
                layout: "/project",
                webstoryOnlyRoute: true,
            },
            {
                path: "/invitedusers",
                name: "Project Invited Users",
                mini: "S",
                component: InvitedUsers,
                layout: "/project",
                webstoryOnlyRoute: true,
            },
            {
                path: "/authorize",
                name: "Authorize",
                mini: "S",
                component: Authorize,
                layout: "/project",
                webstoryOnlyRoute: true,
            },
            {
                path: "/suspended",
                name: "Project Suspended",
                mini: "S",
                component: ProjectSuspended,
                layout: "/project",
                webstoryOnlyRoute: true,
            },
            {
                path: "/provider",
                name: "Provider",
                mini: "P",
                component: Provider,
                layout: "/notifications",
            },
            {
                path: "/plans",
                name: "Plan",
                mini: "R",
                component: Plan,
                layout: "/dashboard",
                webstoryOnlyRoute: true,
            },
            {
                //path: "/checkout?projectId=:projectId&planId=:planId",
                path: "/checkout",
                name: "Plan",
                mini: "R",
                component: Checkout,
                layout: "/plan",
                webstoryOnlyRoute: true,
            },
            {
                //path: "/subscribe?projectId=:projectId&planId=:planId",
                path: "/subscribe",
                name: "Plan",
                mini: "R",
                component: Subscribe,
                layout: "/plan",
                webstoryOnlyRoute: true,
            },
            {
                path: "/edit-category-meta",
                visible: false,
                component: EditCategoryMeta,
                layout: "/contentsettings",
                webstoryOnlyRoute: true,
            },
        ],
    },
    {
        path: "/details",
        name: "Plan Details",
        icon: "nc-icon nc-bulb-63",
        component: PlanDetails,
        layout: "/plan",
        webstoryOnlyRoute: true,
    },
    // {
    //   path: "/transactions",
    //   name: "Paid Articles Sales",
    //   visible: false,
    //   icon: "nc-icon nc-money-coins",
    //   component: PaidArticleTransactions,
    //   layout: "/paidarticles",
    // },
    {
        collapse: true,
        visible: true,
        name: "Transactions",
        icon: "nc-icon nc-money-coins",
        state: "componentsTransactionCollapse",
        views: [
            {
                path: "/transactions",
                name: "Plan Transactions",
                // visible: false,
                mini: "P",
                component: Transactions,
                layout: "/plan",
            },
            {
                path: "/subscriptions",
                name: "Subscriptions",
                // visible: false,
                mini: "P",
                component: Subscriptions,
                layout: "/plan",
            },
            {
                path: "/transactions",
                name: "Paid Articles Sales",
                // visible: false,
                mini: "PA",
                component: PaidArticleTransactions,
                layout: "/paidarticles",
            },
        ],
    },
    {
        collapse: true,
        visible: true,
        name: "Subscription",
        icon: "nc-icon nc-money-coins",
        state: "componentsSubscriptionCollapse",
        views: [
            {
                path: "/detail",
                name: "Subscriptions",
                visible: true,
                mini: "S",
                component: SubscriptionDetails,
                layout: "/subscriptions",
            },
        ],
    },
    {
        collapse: true,
        visible: false,
        name: "Subscription",
        icon: "nc-icon nc-single-02",
        state: "subscriptionState",
        views: [
            {
                path: "/pay",
                name: "Subscription Pay Manual",
                icon: "nc-icon nc-bank",
                component: PayManual,
                layout: "/subscriptions",
            },
        ],
    },
    {
        path: "/dashboard",
        name: "Features",
        icon: "nc-icon nc-globe",
        component: Features,
        layout: "/features",
    },
    {
        path: "/manager",
        name: "Sortd Apps",
        icon: "nc-icon nc-mobile",
        component: AppsManager,
        layout: "/apps",
        webstoryOnlyRoute: true,
    },

    {
        path: "/uploadvideo",
        name: "Videos",
        icon: "nc-icon nc-mobile",
        component: jwplayerNav,
        layout: "/video",
        webstoryOnlyRoute: true,
    },
    {
        path: "/dashboard",
        name: "Connected TV",
        icon: "nc-icon nc-tv-2",
        component: Dashboard,
        layout: "/ctv",
        webstoryOnlyRoute: false,
    },
    {
        path: "/redirection-urls",
        name: "Redirection URLs",
        icon: "nc-icon nc-send",
        component: RedirectionUrls,
        layout: "/project",
        webstoryOnlyRoute: true,
    },
    // {
    //     collapse: true,
    //     visible: true,
    //     name:"Connected TV",
    //     icon:"nc-icon nc-tv-2",
    //     state:"ctvState",
    //     views:[
    //         {
    //             path:"/dashboard",
    //             name:"Book Demo",
    //             mini:"B",
    //             component:Dashboard,
    //             layout:"/ctv"
    //         },
    //         {
    //             path: "/manager",
    //             name: "Manage Design",
    //             mini: "D",
    //             component: CTVAppManager,
    //             layout: "/ctv",
    //         },
    //         {
    //             path:"/manage-categories",
    //             name:"Manage Categories",
    //             mini:"M",
    //             component:ManageCategories,
    //             layout:"/ctv"
    //         },
    //         {
    //             path: "/content-library",
    //             name:"Content Library",
    //             mini:"C",
    //             component:ContentLibrary,
    //             layout:"/ctv"
    //         },
    //         {
    //             path: "/videos-importer",
    //             name:"Videos Import",
    //             mini:"V",
    //             component:ImportVideosDashboard,
    //             layout:"/ctv"
    //         },
    //     ]
    // },
    {
        path: "/add-category",
        component: AddCategory,
        layout: "/ctv",
        webstoryOnlyRoute: true,
        visible: false,
    },
    {
        path: "/import-video",
        component: ImportVideo,
        layout: "/ctv",
        webstoryOnlyRoute: true,
        visible: false,
    },
    {
        path: "/edit-import-video",
        component: EditImportVideo,
        layout: "/ctv",
        webstoryOnlyRoute: true,
        visible: false,
    },
    {
        path: "/edit-video",
        component: EditVideo,
        layout: "/ctv",
        webstoryOnlyRoute: true,
        visible: false,
    },
    {
        path: "/edit-category",
        component: EditCategory,
        layout: "/ctv",
        webstoryOnlyRoute: true,
        visible: false,
    },
    {
        path: "/reorder-category",
        component: ReorderCategories,
        layout: "/ctv",
        webstoryOnlyRoute: true,
        visible: false,
    },
    {
        path: "/settings",
        component: CTVAppGeneralSettings,
        layout: "/ctv",
        webstoryOnlyRoute: true,
        visible: false,
    },
    {
        path: "/customizations",
        visible: false,
        component: CTVCustomizations,
        layout: "/ctv",
        webstoryOnlyRoute: false,
    },
    {
        path:"/config/android-customizations",
        visible:false,
        component:AndroidTVCustomizations,
        layout:"/ctv",
    },
    {
        path:"/config/versions",
        visible:false,
        component:AndroidConfigVersion,
        layout:"/ctv",
    },
    {
        path:"/config/ios-versions",
        visible:false,
        component:IosConfigVersion,
        layout:"/ctv",
    },
    {
        path:"/config/ios-customizations",
        visible:false,
        component:IosTVCustomizations,
        layout:"/ctv",
    },
    {
        path: "/reorder",
        visible: false,
        component: ReorderSection,
        layout: "/ctv",
        webstoryOnlyRoute: false,
    },
    {
        path: "/:mediaId/createchapter/:trackId",
        visible: false,
        component: Chapter,
        layout: "/video",
        // webstoryOnlyRoute: true,
    },
    {
        path: "/:mediaId/createchapter/",
        visible: false,
        component: Chapter,
        layout: "/video",
        // webstoryOnlyRoute: true,
    },
    {
        path: "/importer-logs",
        visible: false,
        component: ImporterLogs,
        layout: "/ctv",
        webstoryOnlyRoute: false,
    },
    {
        collapse: true,
        visible: false,
        name: "Sortd Apps",
        icon: "nc-icon nc-single-02",
        state: "sortdApps",
        views: [
            {
                path: "/ios/dashboard",
                name: "Sortd Apps",
                component: IOSAppBuild,
                layout: "/apps",
                webstoryOnlyRoute: true,
            },
            {
                path: "/config/versions",
                name: "Sortd Apps",
                component: AppConfigVersions,
                layout: "/apps",
                webstoryOnlyRoute: true,
            },
            {
                path: "/config/appcustomizations",
                name: "Sortd Apps",
                component: AppCustomization,
                layout: "/apps",
                webstoryOnlyRoute: true,
            },
            {
                path: "/android/dashboard",
                name: "Sortd Apps",
                component: AndroidAppBuild,
                layout: "/apps",
                webstoryOnlyRoute: true,
            },
            {
                path: "/media/:id",
                name: "Videos",
                icon: "nc-icon nc-mobile",
                component: JwplayerNavForSingleMedia,
                layout: "/analytics",
                webstoryOnlyRoute: true,
            },
        ],
    },
    {
        path: "/dashboard",
        name: "Games",
        icon: "nc-icon nc-controller-modern",
        component: GamesDashboard,
        layout: "/games",
        webstoryOnlyRoute: true,
    },
    {
        path: "/list",
        visible: false,
        name: "Games List",
        icon: "nc-icon nc-controller-modern",
        component: GamesListing,
        layout: "/games",
        webstoryOnlyRoute: true,
    },
    {
        path: "/create",
        visible: false,
        name: "Create Game",
        icon: "nc-icon nc-controller-modern",
        component: CreateGames,
        layout: "/games",
        webstoryOnlyRoute: true,
    },
    {
        path: "/settings",
        visible: false,
        name: "Games Settings",
        icon: "nc-icon nc-controller-modern",
        component: GamesSettings,
        layout: "/games",
        webstoryOnlyRoute: true,
    },
    {
        collapse: true,
        visible: false,
        name: "Subscription",
        icon: "nc-icon nc-single-02",
        state: "subscriptionState",
        views: [
            {
                path: "/ios/dashboard",
                name: "Sortd Apps",
                component: IOSAppBuild,
                layout: "/apps",
                webstoryOnlyRoute: true,
            },
        ],
    },
    {
        path: "/user-profiles",
        name: "User Profiles",
        visible:true,
        icon: "nc-icon nc-single-02",
        // component: UserProfileDashboard,
        component: UserProfileDashboard2,
        layout: "/dashboard",
      },

      {
        collapse: true,
        visible: true,
        name: "Membership",
        icon: "nc-icon nc-money-coins",
        state: "componentsSubscriptionCollapse",
        views: [
            {
                path: "/subscriptions",
                name: "Membership",
                // visible: false,
                mini: "P",
                component: MembershipSubscriptions,
                layout: "/membership",
            },
            {
                path: "/transactions",
                name: "Transaction",
                // visible: false,
                mini: "P",
                component: MembershipTransactions,
                layout: "/membership",
            },
        ],
    },

      {
        collapse: true,
        visible: false,
        name: "Dummy",
        icon: "nc-icon nc-single-02",
        state: "dummy",
        views: [
            {
                path: "/userDetailed",
                visible: false,
                component: UserProfileDetailedView,
                layout: "/dashboard",
                webstoryOnlyRoute: true,
            },
        ],
    },

    {
        path: "/gen-ai-feature-usage",
        name: "GenAI Feature Usage",
        visible:true,
        icon: "nc-icon nc-single-02",
        component: GenaiFeatureUsage,
        layout: "/dashboard",
    },
     
    // {
    //   path: "/transactions",
    //   name: "Paid Articles Sales",
    //   visible: false,
    //   icon: "nc-icon nc-money-coins",
    //   component: PaidArticleTransactions,
    //   layout: "/paidarticles",
    // },
    // {
    //   path: "/dashboard",
    //   name: "Features",
    //   icon: "nc-icon nc-globe",
    //   component: Features,
    //   layout: "/features",
    // },
];
export default routes;
