import React, { useState, useEffect ,useContext} from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { 
    templates,
    getAppIcon, 
    getDefaultFont,
    loadFonts 
} from "./webstory-config";

import moment from 'moment';
import Pagination from "react-js-pagination";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    Card,
    CardImg,
    CardBody,
    CardHeader,
    Row,
    CardTitle,
    Modal,
    ModalBody,
    Button,
    FormGroup,
    Label,
    Input,
    Alert,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner,
    Container,
    Nav,
    Col
} from 'reactstrap';
import Select from "react-select";

import { faImage, faEllipsisV, faPlus } from '@fortawesome/free-solid-svg-icons';
import whatsappImage from "../../assets/img/whatsapp.png";
import facebookImage from "../../assets/img/facebook.png";
import twitterImage from "../../assets/img/twitter.png";
import telegramImage from "../../assets/img/telegram.png";
import copyToClipboardImage from "../../assets/img/copy.png";
import tickImage from "../../assets/img/tick.png";


import ProjectContext from '../../ProjectContext';
import UserContext from '../../UserContext';

import ReactHtmlParser from 'react-html-parser';
import webstoryService from "../../services/api/web-story-service";

import './storyBuilder.css';
import projectService from '../../services/api/projects-service';
import contentService from "../../services/api/content-service";

import publishedIcon from '../../assets/img/webstory/published.png';
import scheduledIcon from '../../assets/img/webstory/scheduled.png';
import LazyLoadImage from "./components/LazyLoadImage";
  

const defaultActionState = {status:false, payload:null};
const draftFilters = { status: true, published: false};
const publishedFilters = { status: true, published: true}
const deletedFilters = { status: false }
const records = 10;

const StoriesList = () => {

    const history = useHistory();

    // contexts 
    const projectDetails = useContext(ProjectContext);
    const userDetails = useContext(UserContext);
    const { tabParams } = useParams();
    
    if(projectDetails.id==''){
        history.push("/project/allprojects");
    }
    
    //states 
    const [title,setTitle] = useState('');
    const [stories,setStories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categoryObject, setCatObject] = useState({});

    const [showTitleModal, setShowTitleModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(defaultActionState);
    const [publishModal, setPublishModal] = useState(defaultActionState);

    const [tabState, setTabState] = useState(tabParams?tabParams:'published');
    const [activePage, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0) ;
    const [filterState, setFilterState ]= useState({});

    const [alertState, setAlertState] = useState({status:false,message:''});
    const [loading, setLoading] = useState(true);
    const [demoHost, setDemoHost] = useState("");
    const [publicHost, setPublicHost] = useState("");
    const [appIcon, setAppIcon] = useState('');
    const [defaultFont, setDefaultFont] = useState('');
    const [projectSettings, setProjectSettings] = useState({});
    

    // hooks
     useEffect(()=>{
        (async ()=>{
            const response = await projectService.getProjectDomain(projectDetails.id);
            if(response.status && response.data) {
                setDemoHost(response.data.demo_host);
                if(response.data.demo_host === response.data.public_host) {
                    setPublicHost(response.data.public_host.split('//')[1]);
                } else {
                    setPublicHost(response.data.public_host);
                }
            }
            console.log(projectDetails);
            await getCategories();

            const res1 = await getDefaultFont(projectDetails.id);
            if(res1 && !res1.err) {
                setDefaultFont(res1);
            } else {
                setDefaultFont('sans-serif');
            }

            const res = await getAppIcon(projectDetails.id);
              if(res && !res.err) {
                  setAppIcon(res);
            }

            const res2 = await projectService.getProjectDetails(projectDetails.id);
            if(res2.status && res2.data){
                setProjectSettings(res2.data.settings);
            }

        })();

    },[]);

    useEffect(()=>{
        console.log(activePage);
        arrangeListByTab(tabState, activePage);
    },[activePage]);

    useEffect(()=>{
        console.log('filterState', filterState);
        arrangeListByTab(tabState, activePage);
    },[filterState]);

    useEffect(() => {
        toggleTab(tabParams);
    }, [tabParams])

    useEffect(() => {
        (async () => {
           await loadFonts(defaultFont);
        })();
    
    }, [defaultFont]);

    // utils 
    const closeTitleModal = () => setShowTitleModal(false);
    
    const navigate = (url, response={}) =>{
        history.push({
          pathname: url,
          response: response
       });
    }

    const generateCategoryObject = (catArr) => { //support upto cat_type = 2, O(n^2)
        let obj = {};
        catArr.forEach(cat => {
            obj[cat._id] = {
                "name": cat.name,
                "alias": cat.alias 
            }
            if(cat.sub_categories && cat.sub_categories.length){
                cat.sub_categories.forEach(subcat => {
                    obj[subcat._id] = {
                        "name": subcat.name,
                        "alias": subcat.alias 
                    }
                });
            }         
        });
        return obj;
    }

    const getCategorySlug = (story) => {
        let slug = "uncategorized";
        if(story.primary_cat_slug){
            return story.primary_cat_slug;
        }

        let validCat = false;
        for(let i = 0; i < story.categories.length; i++){
            if(categoryObject[story.categories[i]._id]){
                validCat = story.categories[i];
                break;
            }
        }
        if(validCat){
            slug = validCat.alias || validCat.name.toLowerCase().split(/\s+/).join('-');
        }
        return slug;
    }

    const getWebstoryUrl = (story, format = 1) => { //format = 1 => publicHost
                                                    //format = 2 => demohost
        let host = "https://" + publicHost;
        if(format === 2){
            host = demoHost;
        }

        let storyKeyword = "webstory";
        if(projectDetails.id === "65097b5f87d0061bde0f8763"){
            storyKeyword = "visual-stories"
        }

        let url = host + `/${storyKeyword}/${story.slug}/${story.guid}`;

        if(projectSettings && projectSettings.enable_category_in_article){
            let catSlug = getCategorySlug(story);
            url = host + `/${storyKeyword}/${catSlug}/${story.slug}/${story.guid}`;
        }

        return url;
    }
    const createStoryBars = (page_count) => {
        let storyBars = [];
        for (let i = 0; i < page_count; i++) {
            storyBars.push(<div key={i} style={{width:(100/(page_count))+'%',height:2,background:'white',marginLeft:5}}></div>);
        }
        return storyBars;
    }
    const toggle = (story) => { 
        const newStoryData = stories.map((e,i)=>{
            if(story.webstory_id === e.webstory_id) {
                return {...e, dropdown:!e.dropdown};
            } else {
                return e;
            }
        });
        console.log(newStoryData)
        setStories([...newStoryData]);
    }
    /**
     * This is the main central function that can change every state as per our needs 
     */
    const toggleTab = (tabId) => {
        setTabState(tabId);
        setLoading(true);
        setPage(1);
        arrangeListByTab(tabId, 1);
        history.push(`/contentsettings/webstory/list/${tabId}`);
    }
    const arrangeListByTab = (tab, pageNo) => {
        switch (tab) {
            case 'drafts':
                getAllWebStories(projectDetails.id, {...draftFilters, ...filterState}, pageNo, records)
                break;
            case 'published':
                getAllWebStories(projectDetails.id, {...publishedFilters, ...filterState}, pageNo, records)
                break;
            case 'hidden':
                getAllWebStories(projectDetails.id, deletedFilters, pageNo, records)
                break;
            default:
                getAllWebStories(projectDetails.id, draftFilters, pageNo, records)
                break;
        }
    }
    const filterByCategory = (categoryArr)=> {
        console.log('categoryArr', categoryArr )
        if(categoryArr!=null && categoryArr.length ) {
            let value = categoryArr.map(e=>e.value);
            setFilterState({categoryIds:value})
        } else {
            setFilterState({});
        }
    }

    // api calls 
    const getAllWebStories = async (projectId, filtersData, page, records)=>{
        const response = await  webstoryService.getAllWebStories({projectId, filtersData, page, records});
        setLoading(false);
        if(!response.status || !response.data) {
            setAlertState({status:true, message: 'Error fetching data'})
        } else {
            console.log(response.data)
            const storiesData = response.data.webstories.map(e=>{
                return {...e, dropdown:false}
            });
            setTotalCount(response.data.count);
            setStories(storiesData);
        }
    }

    const createNewWebstory = async ()=> {

        const newWebstoryData = await webstoryService.createNewWebstory({
            articleData: {
                project_id:projectDetails.id,
                author:userDetails.name,
                title:title,
                type : "webstory",
                published :false,
                status: true,
                guid : Date.now()
            }
        });
        if(projectDetails.isContractExpired){
            projectDetails.setContractExpiredAlert(projectDetails);
        }
        else if(!newWebstoryData.status) {
            setAlertState({status:true, message: 'Error adding New Story'})
        } else {
            const newWebstory = newWebstoryData.data;
            console.log(newWebstory);

            var newStoryPagePayload = {
                article_id: newWebstory._id,
                order: 1,
                template_name:'default'
            }
            const response = await webstoryService.createWebstoryPage(newStoryPagePayload)
            closeTitleModal();
            
            console.log(response)
            if(projectDetails.isContractExpired){
                projectDetails.setContractExpiredAlert(projectDetails);
            }
            else if(!response.status) {
                setAlertState({status:true, message:'Failed to Add Story Page'});
            } else {
                navigate(`/dashboard/storyboard/${newWebstory.slug}/1/${newWebstory._id}`, response)
            }
        }
    }
    const duplicateWebstory = async (webstoryId) => {
        setLoading(true);
        if(projectDetails.isContractExpired){
            setLoading(false);
            projectDetails.setContractExpiredAlert(projectDetails);
            return;
        }
        let webstory = await webstoryService.getWebstoryById({
            storyId: webstoryId
        });
        webstory.data.author = userDetails.name; 
        webstory.data.title = webstory.data.title + "-copy";
        webstory.data.published = false;
        webstory.data.status = true;
        webstory.data.guid = Date.now();
        webstory.data.slug = "";
        webstory.data.type = "webstory";
        webstory.data.project_id = projectDetails.id;
        delete webstory.data.createdAt;
        delete webstory.data.updatedAt;
        delete webstory.data.scheduled;
        const webstoryPages = await webstoryService.getWebstoryPagesInDefaultFormat({
            articleId: webstoryId,
            projectId: projectDetails.id
        })
        const newWebstoryData = await webstoryService.createNewWebstory({
            articleData: webstory.data
        });
        if(!newWebstoryData.status) {
            setTimeout(()=>{
                setLoading(false)
            },2000);
            setAlertState({status:true, message: 'Error adding New Story'})
        } else {
            const newWebstory = newWebstoryData.data;

            for(let i = 0; i < webstoryPages.data.length; i++){
                let page = webstoryPages.data[i];
                let newStoryPagePayload = {
                    article_id: newWebstory._id,
                    order: page.order,
                    template_name: page.template_name
                }
                const response = await webstoryService.createWebstoryPage(newStoryPagePayload)
                if(!response.status){
                    setAlertState({status:true, message:'Failed to Add Story Page'});
                    break;
                }
                let payload = {
                    "storyPageData": {
                        "id": response.data,
                        "page_meta": [],
                        "template_name": page.template_name,
                        "auto_advance_page": page.auto_advance_page
                    },
                    "projectId": projectDetails.id
                }
                page.page_meta.forEach(metaData => {
                    let newMeta = {};
                    let metaKeys = Object.keys(metaData);
                    metaKeys.forEach(key => {
                        if(key === "_id"){
                            return;
                        }
                        newMeta[key] = metaData[key];
                    })
                    payload.storyPageData.page_meta.push(newMeta);
                })

                const savePage = await webstoryService.saveWebstoryPage(payload);
                if(!savePage.status){
                    setAlertState({status:true, message:'Failed to Add Story Page'});
                    break;
                }
            }
            navigate(`/dashboard/storyboard/${newWebstory.slug}/1/${newWebstory._id}`, {});
        }
        
    }
    const deleteWebstory = async (webstoryId)=>{
        console.log(webstoryId)
        const response = await  webstoryService.deleteWebstoryArticle(webstoryId,projectDetails.id);
        console.log(response)
        if(projectDetails.isContractExpired){
            projectDetails.setContractExpiredAlert(projectDetails);
        }
        else if(response.status){
            arrangeListByTab(tabState, activePage);
            setDeleteModal({...deleteModal ,status:false, payload:null});
        } else {
            setAlertState({status:true, message: 'Error Deleting Story'})
        }
    }
    const publishWebstory = async (storyId)=>{
        console.log(storyId)
        console.log(projectDetails.id)
        const response = await  webstoryService.publishWebstory({storyId, projectId:projectDetails.id});
        if(projectDetails.isContractExpired){
            projectDetails.setContractExpiredAlert(projectDetails);
        }
        else if(response.status){
            arrangeListByTab(tabState, activePage);
            setPublishModal(defaultActionState);
        } else {
            setAlertState({status:true, message: 'Error Publishing Story'})
        }
    }
    const unPublishWebstory = async (storyId)=>{
        console.log(storyId)
        const response = await  webstoryService.unPublishWebstory(storyId,projectDetails.id);
        if(projectDetails.isContractExpired){
            projectDetails.setContractExpiredAlert(projectDetails);
        }
        else if(response.status){
            arrangeListByTab(tabState, activePage);
            setPublishModal(defaultActionState);
        } else {
            setAlertState({status:true, message: 'Error Unpublishing Story'});
        }
    }
    const getCategories = async()=>{
        try {
            const response = await  contentService.getCategories(projectDetails.id);
            if(!response.status) {
            } else {
                let categ = [];
                response.data.forEach(e=>{
                    categ.push({value:e._id, label:e.name})
                });
                console.log(categ)
                setCategories(categ);
                let catObj = generateCategoryObject(response.data);
                setCatObject(catObj);
            }
        } catch(err){
            console.log('Failed to get Categories Data',err);
        }
    }
    const copyToClipboard = async(event, story)=>{
        console.log(event.target.style)
        let target = event.target;
        // let shareUrl = `https://${publicHost}/webstory/${story.slug}/${story.guid}`;
        let shareUrl = getWebstoryUrl(story, 1);
        if(publicHost===demoHost) {
            // shareUrl = `${demoHost}/webstory/${story.slug}/${story.guid}`;
            shareUrl = getWebstoryUrl(story, 2);
        }
        navigator.clipboard.writeText(shareUrl);
        target.style.backgroundImage = `url(${tickImage})`;
        setTimeout(() => {
            target.style.backgroundImage = `url(${copyToClipboardImage})`;
        }, 1000);
    }

    const getFontFor = (value) => {
        loadFonts(value);
        return '';
    }

    const isScheduleDateTimeValid = (date) => {
        if(!date){
            return false;
        }
        const now = moment();
        const selected = moment(date);

        if (selected.isBefore(now, 'day')) {
            return false;
        } else if (selected.isSame(now, 'day')) {
            const isValidInterval = selected.minute() % 15 === 0;
            const isFutureTime = selected.isAfter(now);
    
            if (!isValidInterval || !isFutureTime) {
                return false;
            }
        }
        return true;
    }

    return (
        <div className="content m-0 p-0 w-100" >
            <Alert isOpen={alertState.status} color="danger">
                {alertState.message}
            </Alert>
            <Container>
            {loading?<div className="loading-overlay">
                            <Spinner animation="border" />
                    </div>:null}
                <Card style={{borderRadius:0}}>
                    <CardHeader>
                        <CardTitle tag="h4" className="heding_tab_h4 w-100">
                            <Row>
                                <Col md="6">
                                    Webstories   
                                </Col>
                                <div className="d-flex w-50 justify-content-between">
                                    <Select
                                        className="react-select primary w-50"
                                        classNamePrefix="react-select"
                                        placeholder="select categories"
                                        name="categoryids"
                                        id="categoryids"
                                        isMulti="true"
                                        // value = {selectedCategory}
                                        options={categories}
                                        onChange={(arr) => filterByCategory(arr)}
                                    />
                               
                                    <Button
                                        style={{float:'right', margin:0,width:'35%'}} 
                                        className="btn-smb "
                                        type="button"
                                        onClick={()=>navigate(`/dashboard/webstory/templates`)}>Create New Web Story</Button>

                                </div>
                            </Row>  
                                   
                        </CardTitle>
                        
                    </CardHeader>
                    <hr/>

                    <Nav tabs style={{textAlign:'center'}}>
                        <div  
                            onClick={() => { toggleTab('published'); }}
                            className={tabState==='published'?"story-tabs story-active-tab":"story-tabs"}
                        >
                                Published
                        </div>
                        <div  
                            onClick={() => { toggleTab('drafts'); }}
                            className={tabState==='drafts'?"story-tabs story-active-tab":"story-tabs"}
                        >
                                Drafts
                        </div>
                       
                        {/* <div  
                            onClick={() => { toggleTab('deleted'); }}
                            className="story-tabs"
                            style={tabState==='deleted'?{ borderBottom:'1px solid black'}:{}}
                        >
                                Deleted
                        </div> */}
                    </Nav>
                    {stories.length !== 0?
                        <div className="pagBox">
                            <hr/>
                            <div className="pagNo">
                                [ {totalCount!==0?(activePage-1)*records+1:totalCount} - {(activePage-1)*records+ stories.length} ] of {totalCount}
                            </div> 
                            <div className="pagDgt">
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={records}
                                    totalItemsCount={totalCount}
                                    pageRangeDisplayed={5}
                                    onChange={e=>setPage(e)}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </div>
                        </div>
                    :null}
                    <div className="story-list-container">
                    
                    {stories.length === 0?
                   <Card  >
                        <CardTitle tag='h4'  style={{textTransform:'capitalize'}}>No {tabState} Webstories Found! Start Now  !!!</CardTitle>
                        {/* <CardBody> */}
                            <Button
                                    // style={{float:'right', margin:0}} 
                                    className="btn-smb"
                                    type="button"
                                    onClick={()=>navigate(`/dashboard/webstory/templates`)}>Create New Web Story</Button>
                        {/* </CardBody> */}
                       
                    
                   </Card>
                    :null}
                        {stories.map((story,index)=>(
                            <div key={index} className=" story-list-card col-8 col-md-5 col-lg-2 m-3" style={{minHeight:364}}>
                                {getFontFor(story.font)}
                                
                                <Card  
                                    className="story-card grid-view-container"
                                    
                                    title="Click to Edit Story"
                                    >
                                    <div  
                                        className="position-relative h-100" 
                                        style={{overflow: "hidden",...(story.background_color? {background: story.background_color,filter: `brightness(${story.background_options ? story.background_options.brightness : 1}) contrast(${story.background_options ? story.background_options.contrast : 1})`}  : {}),}}
                                        onClick={()=>{navigate(`/dashboard/storyboard/${story.slug}/1/${story.webstory_id}`)}}
                                        >
                                        {/* {story.background_image? <CardImg
                                            alt="Card image cap"
                                            src={}
                                            top
                                            height="100%"
                                            width="100%"
                                            style={{ objectFit:'cover', borderRadius:2}}
                                        />:null} */}
                                             {story.background_image ? (
                                                <>
                                                <LazyLoadImage style={{filter:`brightness(${story.background_options ? story.background_options.brightness:1}) contrast(${story.background_options?story.background_options.contrast:1})`,}}
                                                    key={story.background_image}
                                                    url={story.background_image}
                                                    isframe= {story.frames}
                                                    imageResolution ={story.imageResolution}
                                                />
                                                {story.frames &&(<LazyLoadImage
                                                    key={`/frames/${story.frames}.webp`} // Unique key
                                                    url={`/frames/${story.frames}.webp`} 
                                                    isframe={story.frames}
                                                />)}  
                                                </>
                                            ) : null}
                                        {story.background_image_by_url?(
                                        <>
                                            <LazyLoadImage style={{filter:`brightness(${story.background_options?story.background_options.brightness:1}) contrast(${story.background_options?story.background_options.contrast:1})`}}
                                                key={story.background_image_by_url}
                                                url={story.background_image_by_url}
                                                isframe={story.frames}
                                                imageResolution ={story.imageResolution}
                                            />
                                            {story.frames &&(<LazyLoadImage
                                                key={`/frames/${story.frames}.webp`} // Unique key
                                                url={`/frames/${story.frames}.webp`} 
                                                isframe={story.frames}
                                            />)}  
                                        </>
                                        ):null}
                                        {story.background_video? 
                                            <video key={story.background_video} 
                                            style={{objectFit:'cover', height:'100%', width:'100%', borderRadius:2, filter:`brightness(${story.background_options?story.background_options.brightness:1}) contrast(${story.background_options?story.background_options.contrast:1})`}} 
                                            className='videoTag' muted>
                                                <source src={story.background_video} type='video/mp4' />
                                            </video>:null
                                        }
                                        {(!story.background_image && !story.background_color && !story.background_video && !story.background_image_by_url)?
                                        <div className="no_image_available">
                                            <FontAwesomeIcon  className="m-1" style={{cursor:'pointer',fontSize: 43}} icon={faImage} />
                                            <CardTitle tag='h6' style={{marginTop:9}}>No Image Available</CardTitle>
                                        </div>
                                        :null}

                                         {( story.show_author)?<div className="webstory-author-name">
                                            by {story?.author}
                                        </div>:null}
                                        {/* <div style={{width:'100%',position: "absolute",top:14 ,display:"flex",padding: '0 10px'}} >
                                                {createStoryBars(story.page_count)}
                                        </div> */}
                                        {/* {story.published? <div className="published-strip">
                                            Published
                                        </div>:null} */}
                                    </div>
                                    <div 
                                    className="grid-view-content-container" 
                                    style={templates[story.template_name]?.css.containerCss}
                                    onClick={()=>{navigate(`/dashboard/storyboard/${story.slug}/1/${story.webstory_id}`)}}>
                                         { templates[story.template_name]?.css.logoCss ?
                                                <img style={templates[story.template_name]?.css.logoCss} src={appIcon? appIcon:templates[story.template_name]?.logo}/>
                                            :null}
                                        <div className='ql-snow' style={templates[story.template_name]?.css.qlSnowCorrectionCss? templates[story.template_name]?.css.qlSnowCorrectionCss:{}}>
                                            <div 
                                                style={{ fontFamily:`'${story.font?story.font:defaultFont}'`, ...templates[story.template_name]?.css.headlinesCss,
                                                ...(story.direction ? { direction: story.direction } : {}),
                                                ...(story.textAlign ? { textAlign: story.textAlign } : {}),
                                            }}
                                                dangerouslySetInnerHTML={{__html:  story.headlines ? story.headlines:'' }}
                                                className="ql-editor">
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{position:'absolute', right:10, bottom:0, zIndex:100}}>
                                        <Dropdown isOpen={story.dropdown} toggle={()=>{toggle(story)}}>
                                            <DropdownToggle className="p-0 dropdown-toggle-btn" >
                                                <FontAwesomeIcon  className="m-1" style={{cursor:'pointer'}} icon={faEllipsisV} />
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                {story.published && !isScheduleDateTimeValid(story.article_published_on) ? ( 
                                                     demoHost !== publicHost ?
                                                    // <a href={ `https://${publicHost}/webstory/${story.slug}/${story.guid}`} target="_blank">
                                                    <a href={getWebstoryUrl(story, 1)} target="_blank">
                                                        <DropdownItem>
                                                                Preview (Live Url)
                                                        </DropdownItem>
                                                    </a> 
                                                :
                                                <DropdownItem style={{opacity:0.5, whiteSpace: 'break-spaces'}}>
                                                    Preview (Live Url Not Configured)
                                                </DropdownItem>):null}

                                                {story.published && !isScheduleDateTimeValid(story.article_published_on) ?
                                                    // <a href={ `${demoHost}/webstory/${story.slug}/${story.guid}`}target="_blank">
                                                    <a href={getWebstoryUrl(story, 2)}target="_blank">
                                                        <DropdownItem>
                                                                Preview
                                                        </DropdownItem>
                                                    </a> 
                                                :null}
                                                <DropdownItem onClick={()=>{navigate(`/dashboard/storyboard/${story.slug}/1/${story.webstory_id}`)}}>
                                                    Edit Story
                                                </DropdownItem>
                                                
                                                {story.published && !isScheduleDateTimeValid(story.article_published_on) ? (demoHost !== publicHost ? (
                                                    <a href={"https://whiz.sortd.mobi/?url=" + getWebstoryUrl(story, 1)} target="_blank">
                                                        <DropdownItem>
                                                            Create Video
                                                        </DropdownItem>
                                                    </a>
                                                ) : (
                                                    <a href={"https://whiz.sortd.mobi/?url=" + getWebstoryUrl(story, 2)} target="_blank">
                                                        <DropdownItem>
                                                            Create Video
                                                        </DropdownItem>
                                                    </a>
                                                )): null}

                                                {story.published ? (
                                                    <DropdownItem onClick={()=>{duplicateWebstory(story.webstory_id)}}>
                                                        Duplicate Story
                                                    </DropdownItem>
                                                    ) : null
                                                }       

                                                {story.published && !isScheduleDateTimeValid(story.article_published_on) ? <DropdownItem 
                                                onClick={()=>setPublishModal({...publishModal, status:true, payload:story})}> 
                                                    Unpublish
                                                </DropdownItem>:null}
                                                <DropdownItem onClick={()=>{setDeleteModal({...deleteModal,status:true,payload:story.webstory_id})}}>
                                                    Delete
                                                </DropdownItem>
                                                
                                                
                                                
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </Card>
                                <div className="wbstry-title-container" 
                                style={{
                                    ...(story.direction ? { direction: story.direction } : {}),
                                    ...(story.textAlign ? { textAlign: story.textAlign } : {}),
                                    }}>
                                    <h6 className="webstory-title" title={ReactHtmlParser(story.title)}><b>{ReactHtmlParser(story.title)}</b> </h6>
                                    <small className="text-muted">
                                        {moment(story.article_modified_on?story.article_modified_on:story.updatedAt).fromNow()}
                                    </small>
                                    
                                </div>
                                <div className="time-bar">
                                    { story.published? 
                                    <h6 style={{fontSize:10, cursor:'pointer'}} title={moment(story.article_published_on).format('DD MMM YYYY hh:mm A')}>
                                        Published: {moment(story.article_published_on).fromNow()}
                                    </h6>:null}

                                    <h6 style={{fontSize:10, cursor:'pointer'}} title= {moment(story.article_modified_on).format('DD MMM YYYY hh:mm A')}>
                                        Modified : {moment(story.article_modified_on?story.article_modified_on:story.updatedAt).fromNow()}
                                    </h6>
                                    
                                    {!isScheduleDateTimeValid(story.article_published_on) ?
                                        <div class="social_icons">
                                            {/* <a id="whatsapp" title ="Share on Whatsapp" target="_blank" data-action="share/whatsapp/share" href={`https://web.whatsapp.com/send?text=https://${publicHost}/webstory/${story.slug}/${story.guid}`} >  */}
                                            <a id="whatsapp" title ="Share on Whatsapp" target="_blank" data-action="share/whatsapp/share" href={`https://web.whatsapp.com/send?text=${getWebstoryUrl(story, 1)}`} > 
                                                <img width="27" height="27" alt="Whatsapp share" src={whatsappImage} />
                                            </a>
                                            {/* <a id="facebook" title ="Share on Facebook" target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=https://${publicHost}/webstory/${story.slug}/${story.guid}`}>  */}
                                            <a id="facebook" title ="Share on Facebook" target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${getWebstoryUrl(story, 1)}`}> 
                                                <img width="24" height="24" alt="facebook share" src={facebookImage} /> 
                                            </a>
                                            {/* <a id="twitter" title ="Share on Twitter" target="_blank" href={`https://twitter.com/intent/tweet?text=${story.title}&url=https://${publicHost}/webstory/${story.slug}/${story.guid}`}>  */}
                                            <a id="twitter" title ="Share on Twitter" target="_blank" href={`https://twitter.com/intent/tweet?text=${story.title}&url=${getWebstoryUrl(story, 1)}`}> 
                                                <img width="24" height="24" alt="tweet button" src={twitterImage} /> 
                                            </a>
                                            {/* <a id="telegram" title ="Share on Telegram" target="_blank" href={`https://t.me/share/url?url=https://${publicHost}/webstory/${story.slug}/${story.guid}?text=${story.title}`}> */}
                                            <a id="telegram" title ="Share on Telegram" target="_blank" href={`https://t.me/share/url?url=${getWebstoryUrl(story, 1)}?text=${story.title}`}>
                                                <img width="24" height="24" alt="telegram share" src={telegramImage} /> 
                                            </a>
                                            <a id="shareButton" 
                                            title ="Copy to clipboard" 
                                            style={{backgroundImage:`url(${copyToClipboardImage})`, width:24, height:24}}
                                            onClick={(e) => {copyToClipboard(e, story)}}> 
                                            </a>
                                        </div>
                                        :
                                        null
                                    }

                                </div>
                                {story.published? <img style={{top: 0, position: 'absolute', width: '78%'}} src={isScheduleDateTimeValid(story.article_published_on) ? scheduledIcon : publishedIcon} />:null}
                                <div className="story-pages-container" title={'Story has '+story.page_count+' pages'}>
                                    {story.page_count}
                                </div>
                            </div>
                        ))}
                    

                    </div>
                </Card>
            </Container>

            <Modal isOpen={showTitleModal} >
                <ModalBody>
                    <div>
                        <FormGroup>
                            <Label for="title">
                                Please Enter A Title for your Web Story
                            </Label>
                            <Input id="title" onChange={event => setTitle(event.target.value)} />
                        </FormGroup>
                    </div>
                    <div style={{display:'flex', justifyContent: 'flex-end'}}>
                        <Button color='danger' onClick={closeTitleModal}>Close</Button>
                        <Button color='success' disabled={title===''? true:false} onClick={createNewWebstory}>Proceed</Button>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={deleteModal.status} >
                <ModalBody>
                    <div>
                       Are you sure you want to Delete ?
                    </div>
                    <div style={{display:'flex', justifyContent: 'flex-end'}}>
                        <Button color='primary' onClick={()=>setDeleteModal(defaultActionState)}>Close</Button>
                        <Button color='danger' onClick={()=>deleteWebstory(deleteModal.payload)}>Delete</Button>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={publishModal.status} >
                <ModalBody>
                    <div>
                       {publishModal.payload && publishModal.payload.published? 
                       'Are you sure you want to UnPublish this Web Story ?':
                       'Are you sure you want to Publish this Web Story ?' 
                       }
                    </div>
                    <div style={{display:'flex', justifyContent: 'flex-end'}}>
                        <Button color='danger' onClick={()=>setPublishModal(defaultActionState)}>Close</Button>
                        {publishModal.payload && publishModal.payload.published? 
                            <Button 
                                color='success' 
                                onClick={()=>{unPublishWebstory(publishModal.payload.webstory_id)}} >
                                    UnPublish
                            </Button> :
                            <Button 
                                color='success' 
                                onClick={()=>{publishWebstory(publishModal.payload.webstory_id)}}>
                                    Publish
                            </Button>
                        }
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default StoriesList;

