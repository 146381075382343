import React, { useContext, useEffect, useMemo, useState, useCallback } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import projectUsersService from "../../services/api/project-users-service";
import ProjectContext from "../../ProjectContext";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faTimes } from "@fortawesome/free-solid-svg-icons";
import membershipService from "../../services/api/membership-service";
import Moment from "react-moment";
import Pagination from "react-js-pagination";
const UserProfileDetailedView = () => {
  const location = useLocation();
  const [user, setUser] = useState(null); // State to store the user object
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [reloadComponent, setReloadComponent] = useState(false);
  const [totalUniqueSubscriptions, setTotalUniqueSubscriptions] = useState(0);

  // Pagination states
  const [activePage, setActivePage] = useState(1);
  const [records, setRecords] = useState(10); // Items per page
  const [totalSubscriptions, setTotalSubscriptions] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [firstRecord, setFirstRecord] = useState(1);
  const [lastRecord, setLastRecord] = useState(5);
  const [renderComponent, setRenderComponent] = useState(true);

  // Subscription Pagination states
  const [subActivePage, setSubActivePage] = useState(1);
  const [subRecords, setSubRecords] = useState(5);
  const [subFirstRecord, setSubFirstRecord] = useState(1);
  const [subLastRecord, setSubLastRecord] = useState(5);
  const [reloadComponent2, setReloadComponent2] = useState(false);

  // Subscriptions state with sample data
  const [subscriptions, setSubscriptions] = useState([]);
  const [transactionsSub, setTransactionsSub] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const getStatusLabel = (status) => {
    switch (status) {
      case 0:
        return "Initiated";
      case 1:
        return "Order Created";
      case -1:
        return "Order Could Not Create";
      case 2:
        return "Completed";
      case -2:
        return "Failed";
      default:
        return "Unknown";
    }
  };

  // Get the userId from the URL search params
  const userId = new URLSearchParams(window.location.search).get("userid");

  const projectDetail = useContext(ProjectContext);
  const projectId = projectDetail.id;
  const [transactionFilter, setTransactionFilter] = useState({
    user_id: userId,
    project_id: projectId,
    subscription_id: "",
  });
  const fetchTransactions = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await membershipService.getTransactions(
        transactionFilter,
        activePage,
        records
      );
      if (response.status && response.data) {
        setTransactionsSub(response.data);
        // const totalCount = await membershipService.getTotalTransactions(
        //   transactionFilter
        // );
        // if (totalCount.status) {
        //   setTotalTransactions(totalCount.data.count);
        // }
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setIsLoading(false);
    }
  }, [transactionFilter, activePage]); // Depend on transactionFilter & activePage

  const handleSubscriptionClick = useCallback((SubscriptionId) => {
    setTransactionFilter((prev) => ({
      ...prev,
      subscription_id: SubscriptionId,
    }));
    setIsModalOpen(true);
  }, []);

  useEffect(() => {
    fetchTransactions();
  }, [transactionFilter.subscription_id, fetchTransactions]);

  const countUniquePlans = (subscriptions) => {
    const uniquePlanIds = new Set(
      subscriptions
        .map((sub) => sub.plan_id) // Extract plan_id
        .filter((planId) => planId) // Remove undefined/null values
    );

    setTotalUniqueSubscriptions(uniquePlanIds.size); // Store unique plan count
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setReloadComponent(Math.random());
  };

  const handlePageChange2 = (pageNumber) => {
    setSubActivePage(pageNumber);
    setReloadComponent2(Math.random());
  };

  const fetchUserSubscriptions = async () => {
    setIsLoading(true);
    const response = await membershipService.getUserSubscriptions(
      projectId,
      userId,
      {},
      subActivePage,
      subRecords
    );
    console.log("These are user Memberships:- ", response);

    if (response.status && response.data) {
      let subscriptions = response.data.subscriptions;
      setSubscriptions(response.data.subscriptions);
      setTotalSubscriptions(response.data.count)
      countUniquePlans(subscriptions);
      if (subscriptions && subscriptions.length) {
        setSubFirstRecord((subActivePage - 1) * subRecords + 1);
        setSubLastRecord((subActivePage - 1) * subRecords + subscriptions.length);
      } else {
        setSubFirstRecord((subActivePage - 1) * subRecords);
        setSubLastRecord((subActivePage - 1) * subRecords + subscriptions.length);
      }
      setIsLoading(false);
    }
  };

  const fetchUserTransactions = async () => {
    setIsLoading(true);
    const filters = {
      user_id: userId,
      project_id: projectId
    };
    console.log("These are filters:- ", filters);
    const response = await membershipService.getTransactions(
      filters,
      activePage,
      records
    );
    console.log("These are user transactions:- ", response);

    if (response.status && response.data) {
      let transactions = response.data;
      setTransactions(response.data);
      const totalCount = await membershipService.getTotalTransactions(
        filters
      );
      if (totalCount.status) {
        setTotalTransactions(totalCount.data.count);
      }
      // setTotalTransactions(transactions.length);
      if (transactions && transactions.length) {
        setFirstRecord((activePage - 1) * records + 1);
        setLastRecord((activePage - 1) * records + transactions.length);
      } else {
        setFirstRecord((activePage - 1) * records);
        setLastRecord((activePage - 1) * records + transactions.length);
      }
    }
  };

  useEffect(() => {
    fetchUserSubscriptions();
  }, [reloadComponent2]);

  useEffect(() => {
    fetchUserTransactions();
  }, [reloadComponent]);

  const getStatusText = (status) => {
    switch (status) {
      case 0:
        return "Initiated";
      case -1:
        return "Order could not created";
      case 2:
        return "Success";
      case -2:
        return "Failed";
      default:
        return "";
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        setIsLoading(true);
        const result = await projectUsersService.fetchUsers({
          project_id: projectId,
          user_id : userId
        });

        if (result && result.status === false) {
          setError("No users found");
        } else {
          // Find the user by matching userId
          const matchedUser = result.data.users.find(
            (user) => user.user_id === userId
          );

          if (matchedUser) {
            setUser(matchedUser);
          } else {
            setError("User not found");
          }
        }
      } catch (err) {
        console.error("Error fetching users:", err);
        setError("Error fetching users");
      } finally {
        setIsLoading(false);
      }
    };

    if (userId) {
      fetchUserDetails();
    }
  }, [userId]); // Re-run if userId changes

  const { totalSpending, monthlySpending } = useMemo(() => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    let total = 0;
    let monthly = 0;

    transactions.forEach(({ amount, status, createdat }) => {
      if (status === 2) {
        const amountInRupees = amount / 100;
        total += amountInRupees;

        const txnDate = new Date(createdat);
        if (
          txnDate.getMonth() === currentMonth &&
          txnDate.getFullYear() === currentYear
        ) {
          monthly += amountInRupees;
        }
      }
    });

    return { totalSpending: total, monthlySpending: monthly };
  }, [transactions]);

  if (!user) {
    return <div>No user found!</div>;
  }

  console.log("User:- ", user);

  return (
    <div className="content mt-2">
      <Container fluid className="py-4">
        <Row className="align-items-stretch">
          {/* User Profile Card */}
          <Col className="ml-auto mr-auto d-flex flex-column" md="3">
            <Card className="card-signup text-center dp-box flex-grow-1">
              <CardBody>
                <div className="mb-3">
                  <img
                    src={
                      user?.picture
                        ? user.picture
                        : "https://t3.ftcdn.net/jpg/09/64/89/20/360_F_964892089_vioRltmAxaoQEBLtYtChVBxIzDWwhA3T.jpg"
                    }
                    alt={`${user.name}`}
                    className="rounded-circle me-3 mb-2"
                    style={{
                      width: "60px",
                      height: "60px",
                      objectFit: "cover",
                    }}
                    onError={(e) => {
                      e.target.src =
                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIf4R5qPKHPNMyAqV-FjS_OTBB8pfUV29Phg&s";
                    }}
                  />
                  <div>
                    <h5 className="text-xl font-bold mb-2">{user.name}</h5>
                    <p className="text-sm text-gray-500 mb-0">{user.email}</p>
                    <p className="mb-0 text-base text-gray-500">
                      {user.phone_number || ""}
                    </p>
                  </div>
                </div>

                {/* Responsive Subscription Info */}
                <div
                  className="d-flex justify-content-center align-items-center text-center"
                  style={{
                    flexWrap: "wrap",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <div className="d-flex flex-column align-items-center gap-2">
                    <div
                      className="rounded-circle"
                      style={{
                        width: "15px",
                        height: "15px",
                        backgroundColor: totalSubscriptions > 0 ? "green" : "red",
                      }}
                    ></div>
                    <small className="text-muted">Status</small>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <div className="fw-bold fs-6">
                      {user.createdAt
                        ? new Date(user.createdAt).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                        : "-"}
                    </div>
                    <small className="text-muted">Created</small>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <div className="fw-bold fs-6">
                      {totalSubscriptions || "0"}
                    </div>
                    <small className="text-muted">Membership</small>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          {/* Subscription History Card */}
          <Col className="ml-auto mr-auto d-flex flex-column" md="6">
            <Card className="text-centercdp-box dp-box flex-grow-1">
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <CardTitle tag="h5">Membership History</CardTitle>
                </div>
                {totalSubscriptions > 0 ? (
                  <div className="pagBox">
                    <div className="pagNo">
                      {subFirstRecord} - {subLastRecord} of {totalSubscriptions}
                    </div>
                    <div className="pagDgt">
                      <Pagination
                        activePage={subActivePage}
                        itemsCountPerPage={subRecords}
                        totalItemsCount={totalSubscriptions}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange2.bind(this)}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                  </div>
                ) : null}
              </CardHeader>

              {!renderComponent ? (
                <div className="text-center">
                  <Spinner
                    className="text-center"
                    animation="border"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : null}

              <CardBody style={{ height: "25.5vh", overflowY: "auto" }}>
                <Table responsive>
                  <thead className="text-primary">
                    {renderComponent && (
                      <tr>
                        <th>#</th>
                        <th width="200px">Membership_ID</th>
                        <th width="200px">Plan_ID</th>
                        <th width="200px">Start Date</th>
                        <th width="200px">End Date</th>
                        <th width="150px">Status</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {subscriptions && subscriptions.length ? (
                      subscriptions.map((details, index) => (

                        <tr key={details.pg_subscription_id}>
                          <td>{subFirstRecord + index}</td>
                          <td
                            style={{ cursor: "pointer", color: "#219ebc" }}
                            onClick={() => handleSubscriptionClick(details._id)}
                          >
                            {details.pg_subscription_id || ""}
                          </td>
                          <td>{details.pg_plan_id || ""}</td>
                          <td>
                            {details?.start_at
                              ? moment
                                .unix(details?.start_at)
                                .format("DD MMM YYYY")
                              : ""}
                          </td>
                          <td>
                            {details?.end_at
                              ? moment
                                .unix(details?.end_at)
                                .format("DD MMM YYYY")
                              : ""}
                          </td>
                          <td>{details.status}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        {/* Adjust colSpan to the total number of columns in your table */}
                        <td colSpan="7" className="text-center">
                          <CardTitle tag="h4">
                            No Membership History Found
                          </CardTitle>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>

          {/* Spending Info Cards */}
          <Col className="ml-auto mr-auto d-flex flex-column" md="3">
            <Card className="card-signup text-center dp-box flex-grow-1">
              <CardBody className="d-flex flex-column justify-content-center">
                <div
                  className="d-flex justify-content-evenly align-items-center"
                  style={{ flexWrap: "wrap", gap: "10px" }}
                >
                  <div className="text-primary fs-4">
                    <i className="fa fa-bar-chart" />
                  </div>
                  <div>
                    <small className="text-muted d-block">Total spent</small>
                    <h5 className="mb-0 fs-8">Rs {totalSpending}</h5>
                  </div>
                </div>
              </CardBody>
            </Card>

            <Card className="card-signup text-center dp-box flex-grow-1">
              <CardBody className="d-flex flex-column justify-content-center">
                <div
                  className="d-flex justify-content-evenly align-items-center"
                  style={{ flexWrap: "wrap", gap: "10px" }}
                >
                  <div className="text-primary fs-4">
                    <i className="fa fa-bar-chart" />
                  </div>
                  <div>
                    <small className="text-muted d-block">This Month</small>
                    <h5 className="mb-0 fs-8">Rs {monthlySpending}</h5>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Card className="text-centercdp-box dp-box">
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <CardTitle tag="h5">Transaction History</CardTitle>
            </div>
            {totalTransactions > 0 ? (
              <div className="pagBox">
                <div className="pagNo">
                  {firstRecord} - {lastRecord} of {totalTransactions}
                </div>
                <div className="pagDgt">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={records}
                    totalItemsCount={totalTransactions}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange.bind(this)}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              </div>
            ) : null}
          </CardHeader>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    {renderComponent && (
                      <tr>
                        <th width="150px">Sno.</th>
                        <th width="200px">Transaction_ID</th>
                        <th width="300px">Membership ID</th>
                        <th width="200px">Type</th>
                        <th width="150px">Amount</th>
                        <th width="300px">Status</th>
                        <th width="300px">Transaction Date</th>
                        <th width="150px">Action</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {transactions && transactions.length ? (
                      transactions.map((details, index) => (
                        <tr
                          key={details.pg_txn_id}
                          className={
                            details.state === "Failed" ? "table-danger" : ""
                          }
                        >
                          <td>{firstRecord + index}</td>
                          <td>{details.pg_txn_id}</td>
                          <td>{details?.subscription_id?.pg_subscription_id || ""}</td>
                          <td>
                            {details.type === "2" ? "Recurring" : "One-time"}
                          </td>
                          <td>INR {details.amount / 100}/-</td>
                          <td>{getStatusLabel(details.status)}</td>
                          <td>
                            {moment(details.createdat).format("DD MMM YYYY")}
                          </td>
                          <td className="text-center">
                            {/* Add your action button or other details here */}
                            <Button
                              color="info"
                              size="sm"
                              // disabled={isLoadingToken}
                              onClick={() => {
                                setSelectedTransaction(details);
                                setModalOpen(true);
                              }}
                            >
                              {/* {isLoadingToken ? <Spinner size="sm" /> : <FontAwesomeIcon icon={faInfo} />} */}
                              <FontAwesomeIcon icon={faInfo} />
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        {/* Adjust colSpan to the total number of columns in your table */}
                        <td colSpan="7" className="text-center">
                          <CardTitle tag="h4">
                            No Transaction History Found
                          </CardTitle>
                        </td>
                      </tr>
                    )}

                    {/* Uncomment and adjust if needed */}
                    {/* {renderComponent && (!transactions || !transactions.length) && (
                                        <tr>
                                            <td colSpan="9" className="text-center">
                                                <CardTitle tag="h4">No Transaction Found</CardTitle>
                                            </td>
                                        </tr>
                                    )} */}
                  </tbody>
                </Table>
              </CardBody>
            </Col>
          </Row>
        </Card>
        <Modal
          isOpen={isModalOpen}
          toggle={() => setIsModalOpen(false)}
          size="lg"
        >
          <ModalBody className="p-3 position-relative">
            <div
              className="position-absolute d-flex align-items-center justify-content-center"
              style={{
                right: "12px",
                top: "8px",
                cursor: "pointer",
                zIndex: 1,
                backgroundColor: "red",
                borderRadius: "50%",
                width: "25px",
                height: "25px",
              }}
              onClick={() => setIsModalOpen(false)}
            >
              <FontAwesomeIcon icon={faTimes} className="text-white" />
            </div>

            {/* Transaction Details Section */}
            <div className="mb-3">
              <h5 className="mb-3">Transaction Details</h5>

              <div className="border rounded">
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-left">#</th>
                      <th className="text-left">User Name</th>
                      <th className="text-left">Transaction Amount(INR)</th>
                      <th className="text-left">Membership ID</th>
                      <th className="text-left">Transaction Id</th>
                      <th className="text-left">Transaction Date</th>
                      <th className="text-left">Type</th>
                      <th className="text-center">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionsSub.length > 0 ? (
                      transactionsSub.map((transaction, index) => {
                        const rowNumber =
                          records * (activePage - 1) + index + 1;
                        return (
                          <tr key={transaction.id}>
                            <td className="text-left">{rowNumber}</td>
                            <td className="text-left">
                              {transaction?.user_id?.name || ""}
                            </td>
                            <td className="text-left">
                              ₹{(transaction?.amount || 0) / 100}
                            </td>
                            <td className="text-left">
                              {transaction?.subscription_id
                                ?.pg_subscription_id || ""}
                            </td>
                            <td className="text-left">
                              {transaction.pg_txn_id || ""}
                            </td>
                            <td className="text-left">
                              <Moment format="DD MMM YYYY hh:mm:ss a">
                                {transaction.createdat}
                              </Moment>
                            </td>
                            <td className="text-left">
                              {transaction.type === "1"
                                ? "One time"
                                : "Recurring"}
                            </td>
                            <td className="text-left">
                              {transaction.status === 1
                                ? "Order Created"
                                : getStatusText(transaction.status)}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center">
                          {isLoading ? (
                            <Spinner animation="border" role="status">
                              <span className="sr-only">Loading...</span>
                            </Spinner>
                          ) : (
                            <CardTitle tag="h4">No Records Found</CardTitle>
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)} size="md">
          <ModalBody className="p-3 position-relative">
            {/* Close Button */}
            <div
              className="position-absolute d-flex align-items-center justify-content-center"
              style={{
                right: "12px",
                top: "8px",
                cursor: "pointer",
                zIndex: 1,
                backgroundColor: "red",
                borderRadius: "50%",
                width: "25px",
                height: "25px",
              }}
              onClick={() => setModalOpen(false)}
            >
              <FontAwesomeIcon icon={faTimes} className="text-white" />
            </div>

            {/* Transaction Details Section */}
            <div className="mb-3">
              <h6 className="mb-2">Transaction Details</h6>
              <div className="border rounded">
                <Row className="g-0">
                  <Col xs="5" className="p-2 bg-light">
                    <div className="fw-semibold text-dark">Transaction ID</div>
                  </Col>
                  <Col className="p-2">
                    <div className="fw-semibold">
                      {selectedTransaction?.id || ""}
                    </div>
                  </Col>
                </Row>
                <Row className="g-0 border-top">
                  <Col xs="5" className="p-2 bg-light">
                    <div className="fw-semibold text-dark">Order ID</div>
                  </Col>
                  <Col className="p-2">
                    <div className="fw-semibold">
                      {selectedTransaction?.order_id || ""}
                    </div>
                  </Col>
                </Row>
                <Row className="g-0 border-top">
                  <Col xs="5" className="p-2 bg-light">
                    <div className="fw-semibold text-dark">
                      PG Transaction ID
                    </div>
                  </Col>
                  <Col className="p-2">
                    <div className="fw-semibold">
                      {selectedTransaction?.pg_txn_id || ""}
                    </div>
                  </Col>
                </Row>
                <Row className="g-0 border-top">
                  <Col xs="5" className="p-2 bg-light">
                    <div className="fw-semibold text-dark">
                      PG Transaction Message
                    </div>
                  </Col>
                  <Col className="p-2">
                    <div className="fw-semibold">
                      {selectedTransaction?.pg_txn_message || ""}
                    </div>
                  </Col>
                </Row>
                <Row className="g-0 border-top">
                  <Col xs="5" className="p-2 bg-light">
                    <div className="fw-semibold text-dark">Amount</div>
                  </Col>
                  <Col className="p-2">
                    <div className="fw-semibold">
                      ₹{selectedTransaction?.amount / 100 || ""}
                    </div>
                  </Col>
                </Row>
                <Row className="g-0 border-top">
                  <Col xs="5" className="p-2 bg-light">
                    <div className="fw-semibold text-dark">Status</div>
                  </Col>
                  <Col className="p-2">
                    <div className="fw-semibold">
                      {getStatusText(selectedTransaction?.status)}
                    </div>
                  </Col>
                </Row>
                <Row className="g-0 border-top">
                  <Col xs="5" className="p-2 bg-light">
                    <div className="fw-semibold text-dark">
                      Transaction Date
                    </div>
                  </Col>
                  <Col className="p-2">
                    <div className="fw-semibold">
                      <Moment format="DD MMM YYYY hh:mm:ss a">
                        {selectedTransaction?.createdat}
                      </Moment>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <div>
              <h6 className="mb-2">Membership Details</h6>
              <div className="border rounded">
                <Row className="g-0">
                  <Col xs="5" className="p-2 bg-light">
                    <div className="fw-semibold text-dark">Membership ID</div>
                  </Col>
                  <Col className="p-2">
                    <div className="fw-semibold">
                      {selectedTransaction?.subscription_id
                        ?.pg_subscription_id || ""}
                    </div>
                  </Col>
                </Row>
                <Row className="g-0 border-top">
                  <Col xs="5" className="p-2 bg-light">
                    <div className="fw-semibold text-dark">Paid Count</div>
                  </Col>
                  <Col className="p-2">
                    <div className="fw-semibold">
                      {selectedTransaction?.subscription_id?.paid_count || ""}
                    </div>
                  </Col>
                </Row>
                <Row className="g-0 border-top">
                  <Col xs="5" className="p-2 bg-light">
                    <div className="fw-semibold text-dark">Status</div>
                  </Col>
                  <Col className="p-2">
                    <div className="fw-semibold">
                      {selectedTransaction?.subscription_id?.status || ""}
                    </div>
                  </Col>
                </Row>
                <Row className="g-0 border-top">
                  <Col xs="5" className="p-2 bg-light">
                    <div className="fw-semibold text-dark">Type</div>
                  </Col>
                  <Col className="p-2">
                    <div className="fw-semibold">
                      {selectedTransaction?.subscription_id?.total_count === 1
                        ? "One Time"
                        : selectedTransaction?.subscription_id?.total_count
                          ? "Recurring"
                          : ""}
                    </div>
                  </Col>
                </Row>
                <Row className="g-0 border-top">
                  <Col xs="5" className="p-2 bg-light">
                    <div className="fw-semibold text-dark">Share Url</div>
                  </Col>
                  <Col className="p-2">
                    <div className="fw-semibold">
                      {selectedTransaction?.subscription_id?.share_url || ""}
                    </div>
                  </Col>
                </Row>
                <Row className="g-0 border-top">
                  <Col xs="5" className="p-2 bg-light">
                    <div className="fw-semibold text-dark">
                      Membership Start Date
                    </div>
                  </Col>
                  <Col className="p-2">
                    <div className="fw-semibold">
                      {selectedTransaction?.subscription_id?.start_at
                        ? moment
                          .unix(
                            selectedTransaction?.subscription_id?.start_at
                          )
                          .format("DD MMM YYYY")
                        : ""}
                    </div>
                  </Col>
                </Row>
                <Row className="g-0 border-top">
                  <Col xs="5" className="p-2 bg-light">
                    <div className="fw-semibold text-dark">
                      Membership End Date
                    </div>
                  </Col>
                  <Col className="p-2">
                    <div className="fw-semibold">
                      {selectedTransaction?.subscription_id?.end_at
                        ? moment
                          .unix(selectedTransaction?.subscription_id?.end_at)
                          .format("DD MMM YYYY")
                        : ""}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Container>
    </div>
  );
};

export default UserProfileDetailedView;
