import axios from "axios";

const getSubscriptions = async (filters , page , records) => {
    let formdata={
        filters,
        page,
        records
    };
    console.log(formdata)
    const response = await axios.post('/api/membership/get-subscriptions',formdata);
    return response.data;
}

const getTransactions = async (filters , page , records) => {
    let formdata={
        filters,
        page,
        records
    };
    console.log(formdata);
    const response = await axios.post('/api/membership/get-transactions',formdata);
    return response.data;
}

const getUserSubscriptions = async (projectId, userId, filters, page, records) => {
    let formdata={
        projectId,
        userId,
        filters,
        page,
        records
    };
    const response = await axios.post('/api/membership/get-user-subscriptions',formdata);
    return response.data;
}

const getUserTransactions = async (projectId, userId, filters, page, records) => {
    let formdata={
        projectId,
        userId,
        filters,
        page,
        records
    };
    console.log("In membership Sevice:- ", formdata);
    const response = await axios.post('/api/membership/get-user-transactions',formdata);
    return response.data;
}
const getTotalTransactions = async(formdata) =>{
    const response = await axios.post('/api/membership/get-total-transactions',formdata);
    return response.data;
}

export  default {
    getSubscriptions,
    getTransactions,
    getUserSubscriptions,
    getUserTransactions,
    getTotalTransactions
}