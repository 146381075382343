import React, { useContext, useEffect, useState } from 'react'
import { Button, Alert, Spinner, Card, CardBody, CardHeader, CardTitle, FormGroup, Input, Label, Col, Row, Table, Form } from 'reactstrap'
import ProjectContext from '../../ProjectContext'
import notificationsService from "../../services/api/notifications-service";
import Select from 'react-select';
import SweetAlert from "react-bootstrap-sweetalert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const IzootoSegment = (props) => {
  const {
    formdetails,
    setFormDetails,
    propsPlatform,
    showSegmentDropDown,
    setShowSegmentDropDown,
    segmentId,
    setSegmentId,
    segmentState,
    setSegmentState,
    segmentError,
    setSegmentError,
  } = props
  const platform = propsPlatform.toUpperCase();

  const { id: projectId } = useContext(ProjectContext);


  const [segmentRadioButton, setSegmentRadioButton] = useState('all')
  const [dbSegmentsLoader, setDbsegmentsLoader] = useState(false)
  const [dbSegments, setDbSegments] = useState([])
  const [selectedSegment, setSelectedSegment] = useState("")
  const [manageSegmentLoader, setManageSegmentLoader] = useState(false)
  const [savedSegments, setsavedSegments] = useState([])
  const [newSegments, setnewSegments] = useState([])
  const [deletedSegments, setDeletedSegments] = useState([])
  const [showSegmentPopup, setShowSegmentPopup] = useState(false);
  const [showPopup, setShowPopup] = useState({ alert: null })
  const [defaultSegmentId, setDefaultSegmentId] = useState(null);


  // console.log("props", props)




  useEffect(() => {
    const defaultSegment = dbSegments.find(segment => segment.set_default);
    if (defaultSegment) {
      setSelectedSegment(defaultSegment);
      setDefaultSegmentId(defaultSegment.id)
      setSegmentId({
        ...segmentId,
        [platform]: defaultSegment._id
      })
      setFormDetails({
        ...formdetails,
        segmentId: {
          ...segmentId,
          [platform]: defaultSegment._id
        }
      })
    }
  }, [dbSegments]);


  const getDbSegments = async () => {
    try {
      setDbsegmentsLoader(true);
      const response = await notificationsService.getDbIzootoSegments(projectId, platform)
      if (response.status && response.data) {
        setDbSegments(response.data)
      }
      setDbsegmentsLoader(false);
      setShowSegmentPopup(false)
    } catch (error) {
      toast.error(error)
      console.log(error);
      setDbsegmentsLoader(false);
    }
  }
  const addDashboardSegment = async () => {
    try {
      setManageSegmentLoader(true);

      const response = await notificationsService.getDashboardIzootoSegments(projectId, platform);
      if (response.status && response.data) {
        let dashboardSegments = response.data
        let dashboardIds = {};
        let dashboardArray = [];
        let dbArray = [];
        let deletedElementArray = []
        dashboardSegments.forEach(segment => {
          dashboardIds[segment.id] = true;
        });
        dbSegments.forEach(segment => {
          if (dashboardIds[segment.id]) {
            dbArray.push(segment);
            delete dashboardIds[segment.id];
          } else {
            segment.status = '-1';
            deletedElementArray.push(segment.id);
          }
        });
        console.log(deletedElementArray);
        if (deletedElementArray.length) {
          const deleteResponse = await notificationsService.removeDbIzootoSegmets({
            project_id: projectId,
            platform: platform,
            segment_ids: deletedElementArray
          })
          setDeletedSegments(deletedElementArray)
        }
        for (const id in dashboardIds) {
          if (dashboardIds.hasOwnProperty(id)) {
            dashboardArray.push(dashboardSegments.find(segment => segment.id === parseInt(id)));
          }
        }
        setsavedSegments(dbArray);
        setnewSegments(dashboardArray);
        setShowSegmentPopup(true);
      }
      else {
        toast.error(response.error.message)
      }
      setSelectedSegment("")
      setManageSegmentLoader(false)
    }
    catch (error) {
      console.log(error);
      toast.error(error)
      setManageSegmentLoader(false)
    }
  }

  const removeSegment = (event, segmentDetails) => {
    try {
      const response = notificationsService.insertDbIzootoSegments({
        segment_id: segmentDetails.id,
        segment_name: segmentDetails.name,
        project_id: projectId,
        status: '-1',
        platform: platform
      })
      if (response) {
        const data = [...newSegments]
        data.push(segmentDetails);
        setnewSegments(data);

        const newData = [...savedSegments]
        const idx = newData.findIndex((obj) => obj.id === segmentDetails.id)
        newData.splice(idx, 1);
        setsavedSegments(newData)
        toast.success("Removed Successfully")
      }
    } catch (error) {
      console.log(error);
    }

  }
  const addSegment = (event, segmentDetails) => {
    console.log("add Segment clicked : ", segmentDetails);
    try {

      const response = notificationsService.insertDbIzootoSegments({
        segment_id: segmentDetails.id,
        segment_name: segmentDetails.name,
        project_id: projectId,
        status: '1',
        platform: platform,
      })
      console.log("response from add segments", response);
      if (response) {

        const data = [...savedSegments]
        console.log("data: ", data);
        data.push(segmentDetails);
        setsavedSegments(data);

        const newData = [...newSegments]
        const idx = newData.findIndex((obj) => obj.id === segmentDetails.id)
        newData.splice(idx, 1);
        setnewSegments(newData)

        toast.success("Added Successfully")
      }


    } catch (error) {
      console.log(error);
    }

  }

  const hideAlert = () => {
    setShowPopup({
      alert: null
    })
  }

  const handledefaultSegmentChange = async (segmentDetails) => {
    setShowSegmentPopup(false);
    const confirmation = await defaultSegmentPopup(segmentDetails);
    if (confirmation) {
      await defaultSegment(segmentDetails)
      if (defaultSegmentId === segmentDetails.id) {
        setDefaultSegmentId(null);
      } else {
        setDefaultSegmentId(segmentDetails.id);
        setSelectedSegment(segmentDetails)
      }
    }
    else {
      hideAlert()
    }
    setShowSegmentPopup(true);
  }
  const defaultSegmentPopup = async (segment) => {
    return new Promise(resolve => {
      setShowPopup({
        alert: (
          <SweetAlert
            title="Are you Sure?"
            onConfirm={() => resolve(true)}
            onCancel={() => resolve(false)}
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes"
            cancelBtnText="Cancel"
            showCancel
            closeOnClickOutside={false}
            style={{ zIndex: '999' }}
          >
            Do you want to set/unset '{segment.name}' as default?
          </SweetAlert>
        )
      });
    });
  }
  const defaultSegment = async (segmentDetails) => {
    try {
      const response = await notificationsService.updateDefaultIzootoSegments({
        segment_id: segmentDetails.id,
        segment_name: segmentDetails.name,
        project_id: projectId,
        status: "1",
        platform: platform.toUpperCase(),
        set_default: segmentDetails.set_default
      })
      if (response.status && response.data) {
        await getDbSegments();
        toast.success("Segment Updated Successfully")
      }
      hideAlert();
    } catch (error) {
      console.log("error in defaultSegment : ", error);
      toast.error(error.message)
      hideAlert();
    }
  }
  let newDashboardElements = [];

  {
    newSegments.forEach(function (segmentDetails) {
      const keyValue = segmentDetails.id
      newDashboardElements.push(<tr>
        <td className="text-left">{segmentDetails.name}</td>
        <td><Button className="btn-smb" type="button" onClick={(event) => addSegment(event, segmentDetails)}>Add</Button>
        </td>

      </tr>)
    })
  }
  let savedDbElements = [];
  {
    savedSegments.forEach(function (segmentDetails) {
      savedDbElements.push(<tr>
        <td className="text-left">{segmentDetails.name}</td>
        <td><Button className="btn-smb" type="button" onClick={(event) => removeSegment(event, segmentDetails)}>Remove</Button></td>
        {(platform === 'ANDROID' || platform === "IOS") && (
          <td>
            <input
              type="checkbox"
              name={`defaultSegment`}
              checked={defaultSegmentId === segmentDetails.id}
              onChange={() => handledefaultSegmentChange(segmentDetails)}
            />
          </td>
        )}
      </tr>)
    })
  }

  const handleSegmentChange = (selectedOption) => {
    setSelectedSegment(selectedOption);
    setSegmentId({
      ...segmentId,
      [platform]: selectedOption._id
    })
    setFormDetails({
      ...formdetails,
      segmentId: {
        ...segmentId,
        [platform]: selectedOption._id
      }
    })
    setSegmentError({
      ...segmentError,
      [platform]: ""
    })
    setSegmentState({
      ...segmentState,
      [platform]: ""
    })
  };
  const cancelPopup = async () => {
    setShowSegmentPopup(false)
  }


  const handleSegmentButtonClick = async (segmentValue) => {
    console.log("segment button ki value : ", segmentValue);
    if (segmentValue === "All") {
      setShowSegmentDropDown({
        ...showSegmentDropDown,
        [platform]: false
      })
      setSegmentRadioButton('all')
      setSegmentId({
        ...segmentId,
        [platform]: ""
      })
      setFormDetails({
        ...formdetails,
        segmentId: {
          ...segmentId,
          [platform]: ""
        }
      })
      setSelectedSegment(null)
    }
    if (segmentValue === "segment") {
      setShowSegmentDropDown({
        ...showSegmentDropDown,
        [platform]: true
      })
      setSegmentRadioButton('')
      getDbSegments();
    }
  }

  return (
    <div>

      {<div className='consFom fulconsFom'>
        <Label className="lblName">Izooto Segments for {platform}</Label>
        <div className='inputFom'>
          <div className='form-check-radio fs custom-radio'>
            <Label check>
              <Input
                checked={segmentRadioButton === "all"}
                value="All"
                name={`segment${platform}`}
                type="radio"
                onClick={(e) => handleSegmentButtonClick("All")}
              />
              Broadcast (ALL Subscribers)
              <span className="form-check-sign" />
            </Label>
          </div>
          <div className='form-check-radio fs custom-radio'>
            <Label check>
              <Input
                value="segment"
                name={`segment${platform}`}
                type="radio"
                onClick={(e) => handleSegmentButtonClick("segment")}
              />
              Send to particular Segment
              <span className="form-check-sign" />
            </Label>
            {showSegmentDropDown[platform] && (dbSegmentsLoader ? <Spinner /> :
              <div className='inputFom'>
                <FormGroup>
                  <Select
                    options={dbSegments}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    onChange={handleSegmentChange}
                    value={selectedSegment}
                  />
                  {segmentState[platform] === "has-danger" ? (
                    <Label className="error" style={{ color: 'red' }}>
                      {segmentError[platform]}
                    </Label>
                  ) : null}
                </FormGroup>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {manageSegmentLoader ? <Spinner /> :
                    <Button className="btnBlue btn-round"
                      type="button"
                      onClick={() => addDashboardSegment()}
                    >Manage Segments
                    </Button>
                  }
                </div>
              </div>
            )}
          </div>

        </div>
      </div>}

      {showPopup.alert}
      {showSegmentPopup && (
        <SweetAlert
          title="Manage Segments"
          onConfirm={() => getDbSegments()}
          onCancel={() => cancelPopup()}
          showCancel={false}
          confirmBtnText="OK"
          btnSize=""
          confirmBtnBsStyle="info"
          style={{ width: '50%', height: '70%', overflowY: "auto", zIndex: "0" }}
          closeOnClickOutside={false}
        >
          <Row>
            <Col className="ml-auto mr-auto">
              <Table responsive>
                <thead>
                  <tr>
                    <td>
                      <h6>Segments From Dashboard</h6>
                    </td>
                    <td>
                      <h6>Segments From Database</h6>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Row>
                        <Col className="ml-auto mr-auto" style={{ maxHeight: "35vh", overflowY: "auto" }}>
                          <Table responsive>
                            <tbody>{newDashboardElements}</tbody>
                          </Table>
                        </Col>
                      </Row>
                    </td>
                    <td>
                      <Row>
                        <Col className="ml-auto mr-auto" style={{ maxHeight: "35vh", overflowY: "auto" }}>
                          <Table responsive>
                            <tbody>{savedDbElements}</tbody>
                          </Table>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </SweetAlert>
      )}
    </div>
  )
}

export default IzootoSegment