import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Alert
} from 'reactstrap';

const GenAIAnalyticsDashboard = ({ data, allApiTypes }) => {
  const chartRefs = {
    apiType: useRef(null),
    distribution: useRef(null),
    timeline: useRef(null)
  };

  const chartInstances = useRef({});

  const isValidData = (data) => {
    return Array.isArray(data) && data.length > 0 && data.every(item => 
      item && 
      typeof item === 'object' &&
      'api_type' in item &&
      'credit_consumed' in item &&
      'createdAt' in item
    );
  };

  useEffect(() => {
    Object.values(chartInstances.current).forEach(chart => {
      if (chart) chart.destroy();
    });

    if (!isValidData(data)) {
      console.log("Invalid or empty data received");
      return;
    }

    createCharts();

    return () => {
      Object.values(chartInstances.current).forEach(chart => {
        if (chart) chart.destroy();
      });
    };
  }, [data]);

  const createCharts = () => {
    const validData = data.filter(item => item.api_type && item.credit_consumed);

    if (validData.length === 0) return;

    createApiTypeChart(validData);
    createDistributionChart(validData);
    createTimelineChart(validData);
  };

  const createApiTypeChart = (validData) => {
    const ctx = chartRefs.apiType.current.getContext("2d");
  
    // Initialize all API types with 0 credits and 0 calls
    const groupedData = allApiTypes.reduce((acc, apiType) => {
      acc[apiType] = { total: 0, count: 0 };
      return acc;
    }, {});
  
    // Populate with actual values from validData
    validData.forEach((item) => {
      if (groupedData[item.api_type]) {
        groupedData[item.api_type].total += item.credit_consumed;
        groupedData[item.api_type].count += 1;
      }
    });
  
    const labels = allApiTypes; // Use provided API types
    const totalCredits = labels.map((apiType) => groupedData[apiType].total);
    const apiCallCounts = labels.map((apiType) => groupedData[apiType].count);
  
    chartInstances.current.apiType = new Chart(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Credits Used",
            data: totalCredits,
            backgroundColor: "rgba(75, 192, 192, 0.6)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const apiType = data.labels[tooltipItem.index];
              const info = groupedData[apiType];
              return [
                `Total Credits: ${info.total}`,
                `Number of Calls: ${info.count}`,
                `Average per Call: ${info.count > 0 ? (info.total / info.count).toFixed(2) : "0"}`,
              ];
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: { beginAtZero: true },
            },
          ],
        },
      },
    });
  };
  

  const createDistributionChart = (validData) => {
    const ctx = chartRefs.distribution.current.getContext('2d');

    const apiCounts = validData.reduce((acc, item) => {
      if (!acc[item.api_type]) acc[item.api_type] = 0;
      acc[item.api_type]++;
      return acc;
    }, {});

    chartInstances.current.distribution = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: Object.keys(apiCounts),
        datasets: [{
          data: Object.values(apiCounts),
          backgroundColor: [
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)'
          ]
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const apiType = data.labels[tooltipItem.index];
              const count = apiCounts[apiType];
              const percentage = ((count / Object.values(apiCounts).reduce((a, b) => a + b, 0)) * 100).toFixed(1);
              return `${apiType}: ${count} calls (${percentage}%)`;
            }
          }
        }
      }
    });
  };

  const createTimelineChart = (validData) => {
    const ctx = chartRefs.timeline.current.getContext("2d");
  
    // Process the data: Group by date and API type
    const timelineData = validData.reduce((acc, item) => {
      const date = new Date(item.createdAt).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = {}; // Store credits/calls for each API type on this date
      }
      if (!acc[date][item.api_type]) {
        acc[date][item.api_type] = { credits: 0, calls: 0 };
      }
      acc[date][item.api_type].credits += item.credit_consumed;
      acc[date][item.api_type].calls += 1;
      return acc;
    }, {});
  
    // Sort dates
    const sortedDates = Object.keys(timelineData).sort((a, b) => new Date(a) - new Date(b));
  
    // Aggregate total credits and calls per day
    const totalCreditsPerDay = sortedDates.map(date =>
      Object.values(timelineData[date]).reduce((sum, api) => sum + api.credits, 0)
    );
  
    const totalCallsPerDay = sortedDates.map(date =>
      Object.values(timelineData[date]).reduce((sum, api) => sum + api.calls, 0)
    );
  
    chartInstances.current.timeline = new Chart(ctx, {
      type: "line",
      data: {
        labels: sortedDates,
        datasets: [
          {
            label: "Total Credits",
            data: totalCreditsPerDay,
            borderColor: "rgba(75, 192, 192, 1)",
            backgroundColor: "rgba(75, 192, 192, 0.1)",
            yAxisID: "credits",
          },
          {
            label: "Total API Calls",
            data: totalCallsPerDay,
            borderColor: "rgba(255, 99, 132, 1)",
            backgroundColor: "rgba(255, 99, 132, 0.1)",
            yAxisID: "calls",
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              const date = data.labels[tooltipItem.index];
              const datasetLabel = data.datasets[tooltipItem.datasetIndex].label;
              const apiBreakdown = timelineData[date];
  
              if (!apiBreakdown) return "No data available";
  
              // If hovering over "Total API Calls", show total calls + API-wise breakdown
              if (datasetLabel === "Total API Calls") {
                const totalCalls = totalCallsPerDay[tooltipItem.index];
                return [
                  `Total API Calls: ${totalCalls}`,
                  ...Object.entries(apiBreakdown).map(([apiType, info]) => `${apiType}: ${info.calls} calls`),
                ];
              }
  
              // If hovering over "Total Credits", show total credits + API-wise breakdown
              if (datasetLabel === "Total Credits") {
                const totalCredits = totalCreditsPerDay[tooltipItem.index];
                return [
                  `Total Credits Used: ${totalCredits}`,
                  ...Object.entries(apiBreakdown).map(([apiType, info]) => `${apiType}: ${info.credits} credits`),
                ];
              }
            },
          },
        },
        scales: {
          yAxes: [
            {
              id: "credits",
              position: "left",
              ticks: { beginAtZero: true },
              scaleLabel: { display: true, labelString: "Credits" },
            },
            {
              id: "calls",
              position: "right",
              ticks: { beginAtZero: true },
              scaleLabel: { display: true, labelString: "Number of Calls" },
            },
          ],
        },
      },
    });
  };  
  

  if (!isValidData(data)) {
    return (
      <Alert color="warning">
        No valid data available for visualization
      </Alert>
    );
  }

  return (
    <div>
      <Row>
      <Col md="8">
          <Card>
            <CardHeader>
              <CardTitle tag="h5">Credits by API Type</CardTitle>
            </CardHeader>
            <CardBody>
              <div style={{ height: '300px' }}>
                <canvas ref={chartRefs.apiType} />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md="4">
          <Card>
            <CardHeader>
              <CardTitle tag="h5">API Usage Distribution</CardTitle>
            </CardHeader>
            <CardBody>
              <div style={{ height: '300px' }}>
                <canvas ref={chartRefs.distribution} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
      <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h5">Usage Timeline</CardTitle>
            </CardHeader>
            <CardBody>
              <div style={{ height: '300px' }}>
                <canvas ref={chartRefs.timeline} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default GenAIAnalyticsDashboard;
