import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router";
import AWS from 'aws-sdk';
import {
    useParams
} from "react-router-dom";

import Embed from './components/Embed';
import AutoAdvance from './components/AutoAdvance';
import PageNotFound from './components/PageNotFound';

import TweetEmbed from 'react-tweet-embed';

import ReactHtmlParser from 'react-html-parser';

import { isEqual } from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faGripHorizontal, faInfoCircle, faImages, faImage, faTrash, faCheckCircle, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import arrowImage from '../../assets/img/webstory/icons/arrow.png';
import backImage from '../../assets/img/webstory/icons/back.png';
import addPage from '../../assets/img/webstory/icons/add.png';
import warningImage from '../../assets/img/webstory/icons/warning.webp';
import settingsIcon from '../../assets/img/webstory/icons/setting.png';
import animationsIcon from '../../assets/img/webstory/icons/animation.png';
import authorIcon from '../../assets/img/webstory/icons/author.png';
import insertBackgroundIcon from '../../assets/img/webstory/icons/insert_background.png';
import backgroundColorIcon from '../../assets/img/webstory/icons/backgroundColor.png';
import imageByUrl from '../../assets/img/webstory/icons/url.png';
import uploadIcon from '../../assets/img/webstory/icons/upload.png';
import cardFramesIcons from '../../assets/img/webstory/icons/photoCard.webp';

import TagsInput from 'react-tagsinput';

import {
    Button,
    Alert,
    Col,
    Spinner,
    Input,
    Label,
    CardTitle,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    FormFeedback,
    FormText,
    Card
} from 'reactstrap';

import {
    templates,
    storypagelimit,
    colorPickerColors,
    descriptionWordlimit,
    paragraphWordlimit,
    headlinesWordlimit,
    supportedFileTypes,
    acceptedPosterImageTypes,
    payloadSizeLimit,
    imageUploadLimit,
    upperUploadLimit,
    allowedFileTypes,
    getAppIcon,
    getMediaIcon,
    templateArr,
    getDefaultFont,
    loadFonts,
    frameStyle,
    imageResolution,
} from "./webstory-config";
import LazyLoadImage from "./components/LazyLoadImage";

import Reorder from './components/Reorder';
import GridView from '../../assets/img/icons/grid_view.png';
import DeleteIcon from '../../assets/img/icons/delete.png';
import upIcon from '../../assets/img/webstory/icons/up.png';
import downIcon from '../../assets/img/webstory/icons/down.png';
import backgroundIcon from '../../assets/img/webstory/icons/background.png';
import embedIcon from '../../assets/img/webstory/icons/embed.png';

import ProjectContext from '../../ProjectContext';


import webstoryService from "../../services/api/web-story-service";
import contentService from "../../services/api/content-service";

import Select from "react-select";

import ReactQuill from 'react-quill';

import { TwitterPicker, SliderPicker } from 'react-color';

import ReactDatetime from 'react-datetime';
import moment from 'moment';

import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import './storyBuilder.css';
import 'react-tagsinput/react-tagsinput.css'
import allListingService from "../../services/api/Jwplayer/allListing-service";
import axios from 'axios';
import { object } from "prop-types";


const {
    v1: uuidV1
} = require('uuid');


const defaultColors = [
    "#fc01fe", "#240022", "#964bbf","#000000", "#ffffff", "#FF0000", "#FF8000", "#FFFF00", "#80FF00", "#00FF00", "#00FF80", "#00FFFF", "#0080FF", "#0000FF", "#8000FF",
    "#800000", "#FF4000", "#FFBF00", "#FFFF80", "#BFFF00", "#00FF40", "#00FFBF", "#00BFFF","#808080", "#FF8080", "#FFC080", "#FFFFC0", "#C0FFC0", "#80FF80", "#802000",
    "#80FFC0", "#80FFFF", "#80C0FF", "#8080FF", "#008040",
  ];

const defaultSideOptions = 'backgroundmediaImage';
const toolbarOptions = {
    toolbar:
    [
      'bold', 'italic', 'underline', 'strike',
      { 'color': [...defaultColors] }, { 'background': [...defaultColors] },
      { 'size': ['small', false, 'large', 'huge'] },
      { 'script': 'sub' }, { 'script': 'super' }, 'link', 'clean'
    ],
    keyboard: {
      bindings: {
        space: {
          key: 32,
          handler: function(range, context) {
            const space = '  ';
            this.quill.insertText(range.index, space);
            this.quill.setSelection(range.index + 1);
            return false;
          }
        }
      }
    }
  };

  const headerToolbarOptions = {
    toolbar: 
    [
      'bold', 'italic', 'underline', 'strike',
      { 'color': [...defaultColors] }, { 'background': [...defaultColors] },
      { 'size': ['small', false, 'large', 'huge'] },
      { 'script': 'sub' }, { 'script': 'super' },
      { 'header': 1 }, 'link', 'clean'
    ],
    keyboard: {
      bindings: {
        space: {
          key: 32,
          handler: function(range, context) {
            const space = '  ';
            this.quill.insertText(range.index, space);
            this.quill.setSelection(range.index + 1);
            return false;
          }
        }
      }
    }
  };

const defaultBackgroundOptions = {
    brightness: 1, //max 2 
    contrast: 1  //max 2 
}
// const headerToolbarOptions ={
//     toolbar: 
//         [
//             'bold', 'italic', 'underline', 'strike',
//             { 'color': [] }, { 'background': [] },{ 'font': [] },
//             { 'size': ['small', false, 'large', 'huge'] },
//             { 'script': 'sub'}, { 'script': 'super' },
//             { 'header': 1} ,'link','clean'
//         ]
// }

const bgAnimations = [ 
    {label:'Zoom In' ,value:'zoom-in' },
    {label:'Zoom Out' ,value:'zoom-out' },
    {label:'Pan Left' ,value:'pan-left' },
    {label:'Pan Right' ,value:'pan-right' },
    {label:'Pan Up' ,value:'pan-up' },
    {label:'Pan Down' ,value:'pan-down' }   
]

const textAnimations = [
    {label:'Fade In' ,value:'fade-in' },
    {label:'Twirl In' ,value:'twirl-in' },
    {label:'Fly In Left',value:'fly-in-left' },
    {label:'Fly In Right' ,value:'fly-in-right' },
    {label:'Fly In Top' ,value:'fly-in-top' },
    {label:'Fly In Bottom' ,value:'fly-in-bottom' },
    {label:'Rotate In Left' ,value:'rotate-in-left' },
    {label:'Rotate In Right' ,value:'rotate-in-right' },
    {label:'Drop In' ,value:'drop' },
    {label:'Whoosh In left' ,value:'whoosh-in-left' },
    {label:'Whoosh In right' ,value:'whoosh-in-right' },
]

const frames = [
    // { label: "Circular Frame", value: "circular_frame" },
    // { label: "Horizontal Rectangle Frame", value: "h_rect_frame" },
    // { label: "Vertical Rectangle Frame", value: "v_rect_frame" },
    // { label: "Top Frame", value: "top_frame" },
    // { label: "Half Frame", value: "half_frame" },
    { label: "Story Box News", value: "storyBoxNews" },
    { label: "Story Up", value: "storyUp" },
    { label: "Stroy Vedia", value: "storyVedia" },
    { label: "Story Wep", value: "storyWep"},
    { label: "Story Wep News", value: "storyWepNews" }
];



const createMetaFor = (label, value, type, status = true) => {
    return {
        "component_name": label,
        "component_value": value,
        "status": status,
        "component_type": type
    }
}
const slugRegex = /[\\!@#,?\'"|’%^=[\]\(\)\+_`~{}&$></\*.:;]/g;
const defaultAlertState = { status: false, msg: null, color: 'success' };

const defaultBgColor = '#00d084';

const keys = {
    ArrowRight: 'ArrowRight',
    ArrowLeft: 'ArrowLeft'
}
  
const StoryBoard = () => {
    const { storyId, page, slug } = useParams();
    const history = useHistory();
    const reactQuillRef = useRef();
    const fileRef = useRef();
    const posterFileRef = useRef();
    const urlImageRef = useRef();
    const titleRef = useRef();
    const slugRef = useRef();
    const mediaContainerRef = useRef(null);
    const initalScheduleStatus = useRef(null);
    const projectDetails = useContext(ProjectContext);
    
    if (projectDetails.id == '') {
        history.push("/project/allprojects");
    }
    /***         PAGE STATES          **/
    const [categories, setCategories] = useState([]);
    const [pageNo, setPageNo] = useState(page - 0);
    const [currentPage, setCurrentPage] = useState({});
    const [allPages, setAllPages] = useState([]);
    const [validationErrors, setValidationErrors] = useState([]);
    const [webstoryArticle, setWebStoryArticle] = useState({});
    const [reorder, setReorder] = useState(false);
    const [appIcon, setAppIcon] = useState('');
    const [previewState, setPreviewState] = useState('');
    const [pageOptions, setPageOptions] = useState(false);
    const [sideOptions, setSideOptions] = useState(defaultSideOptions);//backgroundmediaImage, backgroundcolor, backgroundmediaurl, embed, and changetemplate, settings ,textanimations,bganimations
    const [pageTemplate, setPageTemplate] = useState('template4');
    const [showAuthor, setShowAuthor] = useState(false);
    const [mediaListing, setMediaListing] = useState([]);
    const [backgroundOptions, setBackgroundOptions] = useState(defaultBackgroundOptions);
    const [fonts, setFonts] = useState([]);
    const [defaultFont, setDefaultFont] = useState('');

    /***          ALERT STATES          **/
    const [pendingAction, setPendingAction] = useState();
    const [alertState, setAlertState] = useState(defaultAlertState);
    const [modalState, setModalState] = useState({});
    const [embedhtml, setEmbedhtml] = useState('');
    const [errorhtml, seterrorhtml] = useState('');
    const [translateLoader ,setTranslateLoader] = useState(false);

    /***          LOADING STATE          **/
    const [loading, setLoading] = useState(false);
    const [shake, setShake] = useState(false);

    /***          PUBLISHING STATE          **/
    const [publishOption, setPublishOption] = useState('immediate');
    const [scheduleDateTime, setScheduleDateTime] = useState(null);
    const [isInvalidTime, setIsInvalidTime] = useState({"status": true, "message":""});
    const offset = useRef({ x: 0, y: 0 });
    const [isDragging ,setIsDragging] = useState(false);

    const handleMouseDown = (e) => {
        e.preventDefault()
        const currentImageResolution = currentPage?.imageResolution ? JSON.parse(currentPage.imageResolution) : {};
    
        offset.current = {
            x: e.clientX - (currentImageResolution.left ?? 100),
            y: e.clientY - (currentImageResolution.top ?? 100),
        };
        setIsDragging(true);
    };  
    
    const handleMouseMove = (e) => {
        if(!isDragging) return;
        e.preventDefault()
        setCurrentPage((prev) => ({
            ...prev,
            imageResolution: JSON.stringify({
                ...(typeof prev.imageResolution === "string" ? JSON.parse(prev.imageResolution) : prev.imageResolution),
                left: e.clientX - offset.current.x,
                top: e.clientY - offset.current.y,
            }),
        }));
    };
    
    const handleMouseUp = () => {
        setIsDragging(false)
    };

    

    const validDate = (current) => {
        const now = moment();
      
        if (current.isBefore(now, 'day')) {
          return false;
        }
        return true;
    };

    const handleDateTimeChange = (date) => {
        let isValidObject = isScheduleDateTimeValid(date);

        if(isValidObject.status){
            setIsInvalidTime({status: false, message: ""});
            setScheduleDateTime(date);
        }else{
            setIsInvalidTime({status: true, message: isValidObject.message});
            setScheduleDateTime(null);
        }
        return;
    };

    const isScheduleDateTimeValid = (date) => {

        const returnObject = {
            "status": false,
            "message": ""
        }

        if(!date){
            returnObject.message = "Date is Null";
            return returnObject
        }
        const now = moment();
        const selected = moment(date);
        if (selected.isBefore(now, 'day')) {
            returnObject.message = "Date is Invalid as it is in Past"; 
            return returnObject;
        } else if (selected.isSame(now, 'day')) {
            const isValidInterval = selected.minute() % 15 === 0;
            const isFutureTime = selected.isAfter(now);
    
            if (!isValidInterval || !isFutureTime) {
                returnObject.message = "Time is Invalid";
                return returnObject;
            }
        }
        returnObject.status = true;
        return returnObject;
    }

    const handleDragOver = (event) => {
        event.preventDefault();
    }

    const handleDrop = (event) => {
        event.preventDefault();
        console.log(event)
        let media = event.dataTransfer.getData('text');
        if(!media?.url_original){
            return
        }
        media = JSON.parse(media);
        if (media.url_original.includes('img.cdn.sortd') || media.url_original.includes('img.gcdn.sortd')) {
            updateBackground2({ background_image: media.url_original, imageId: media._id }, 'image');
        } else {
            updateBackground2({ background_image_by_url: media.url_original, imageId: media._id }, 'image_by_url');
        }
    }

    const handleDragStart = (event, data) => {
        event.dataTransfer.setData("text/plain", JSON.stringify(data));
        event.dataTransfer.setDragImage(event.target, 0, 0);
    }


    const handleZoom = (factor) => {
        setCurrentPage((prev) => {
            const parsedImageResolution = prev.imageResolution ? JSON.parse(prev.imageResolution) : {};
            return {
                ...prev,
                imageResolution: JSON.stringify({
                    ...parsedImageResolution,
                    transform: (parsedImageResolution.transform ?? 10) + factor
                })
            };
        });
    };

    /***          HOOKS          **/
    useEffect(() => {
        (async () => {
            console.log('\n \n -------------- Webstory Article --------------')
            await getWebstoryById();
            console.log('\n \n --------------  Webstory Pages  --------------')
            await getWebstoryPages();
            await getMediaLibrary(0);
            const response = await getDefaultFont(projectDetails.id);
            if(response && !response.err) {
                setDefaultFont(response);
            } else {
                setDefaultFont('sans-serif');
            } 
           

            const res = await getAppIcon(projectDetails.id);
            if (res && !res.err) {
                setAppIcon(res);
            }
        })();


        // if (!document.body.classList.contains("sidebar-mini")) {
        //     document.body.classList.toggle("sidebar-mini");
        // }
        
        document.querySelector('.navbar-absolute.fixed-top.navbar-transparent.navbar.navbar-expand-lg').style.display = 'none';
        document.querySelector('.navbar-absolute.fixed-top.navbar-transparent.navbar.navbar-expand-lg').style.display = 'none';
        document.querySelector('.sidebar').style.display = 'none';
        document.querySelector('.main-panel').classList.add('w-100');
        document.querySelector('footer').style.display = 'none';
   
        return () => {
           document.querySelector('.navbar-absolute.fixed-top.navbar-transparent.navbar.navbar-expand-lg') && (document.querySelector('.navbar-absolute.fixed-top.navbar-transparent.navbar.navbar-expand-lg').style.display = 'block');
            document.querySelector('.sidebar') && (document.querySelector('.sidebar').style.display = 'block');
            document.querySelector('.main-panel') && (document.querySelector('.main-panel').classList.remove('w-100'));

            // document.body.classList.toggle("sidebar-mini");

        }
    }, []);

    useEffect(() => {
        console.log('useeffect currentpage ', currentPage);
        if(currentPage.background_options) {
            console.log('-------------- Background Options found  --------------')
            console.log('currentPage.background_options',currentPage.background_options);
            setBackgroundOptions(currentPage.background_options);
        } else {
            console.log('-------------- Reset Background Options as No Option found  --------------')
            setBackgroundOptions(defaultBackgroundOptions);
        }
        if(currentPage.font) {
            loadFonts(currentPage.font);
        }
        validateCurrentPage();

    }, [currentPage]);
    useEffect(() => {
        console.log(!(pageNo == page) && allPages[pageNo - 1])
        console.log(!(pageNo == page))
        console.log(pageNo == page)
        console.log(pageNo)
        console.log(page)
        console.log(allPages[pageNo - 1])

        if (allPages[pageNo - 1]) {
            // if(allPages[pageNo-1].auto_advance_page === undefined) {
            //     allPages[pageNo-1].auto_advance_page = false;
            // } 
            setCurrentPage(allPages[pageNo - 1]);
            navigate(`/dashboard/storyboard/${slug}/${pageNo}/${storyId}`, {});
        }
        console.log(`-------------- PAGE ${pageNo} --------------`);
    }, [pageNo]);
    useEffect(() => {
        console.log(`-------------- page was set --------------`);

        setPageNo(page - 0);
    }, [page]);
    useEffect(() => {
        console.log('use effect all pages', allPages)
    }, [allPages]);
    useEffect(() => {
        const isValidObject = isScheduleDateTimeValid(webstoryArticle.pdt);
        if(isValidObject.status){
            setScheduleDateTime(moment(webstoryArticle.pdt));
            setPublishOption("scheduled");
            setIsInvalidTime({status: false, message: ""});
            initalScheduleStatus.current = "scheduled";
        }else{
            setScheduleDateTime(null);
            setPublishOption("immediate");
            setIsInvalidTime({status: true, message: ""});
            initalScheduleStatus.current = "immediate";
        }
        console.log('use effect webstory article', webstoryArticle)
    }, [webstoryArticle, modalState]);
    useEffect(() => {
        console.log('use effect validations', validationErrors)
    }, [validationErrors]);
    useEffect(() => {
        console.log('useeffect embed html text', embedhtml);
    }, [embedhtml]);
    useEffect(() => {
        console.log('useeffect pagetempalte', pageTemplate);
    }, [pageTemplate]);
    useEffect(() => {
        if (modalState.publish) {
            console.log(titleRef)
            if (webstoryArticle.title && webstoryArticle.title === 'Untitled' && titleRef.current) {
                titleRef.current.value = reactQuillRef.current.getEditor().getText().trim();
                slugRef.current.value = slugFunction(titleRef.current.value);
            }
        }
    }, [modalState]);
    useEffect(() => {
        console.log('useeffect backgroundOptions', backgroundOptions);
    }, [backgroundOptions]);

    useEffect(() => {
        (async () => {
            if (!fonts.length && sideOptions === 'settings') {
                await getGoogleFonts();
            } else {
                console.log("don't load fonts")
            }
            console.log('useeffect sideOptions', sideOptions);
        })();

    }, [sideOptions]);

    useEffect(() => {
        (async () => {
           await loadFonts(defaultFont);
        })();

    }, [defaultFont]);

    useEffect(() => {
        setCurrentPage((prevValue)=> ({...prevValue, show_author: showAuthor}))
    }, [showAuthor]);


    /***          UTIL FUNCTIONS           **/
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const navigate = (url, response = {}) => {
        history.push({
            pathname: url,
            response: response
        });
    }
    const getTemplateText = (name) => {
        return templates[pageTemplate] ? templates[pageTemplate][name] : '';
    }
    const setStoryTitle = (value) => {
        console.log('setStoryTitle', value)
        console.log('title ref', titleRef)
        setWebStoryArticle(prevValue => ({ ...prevValue, title: value }));
    }
    const setStorySlug = (value) => {
        console.log('slug ref', slugRef)

        console.log('slug ref', slugFunction(value))

        slugRef.current.value = slugFunction(value);

        setWebStoryArticle(prevValue => ({ ...prevValue, slug: slugFunction(value) }));

    }
    
    const generateAiSlug = async ()=>{
        setTranslateLoader(true)
        const englishSlug = await  webstoryService.getEnglishSlug(slugRef.current.value);
        if(englishSlug){
            slugRef.current.value = slugFunction(englishSlug);
        }
        setTranslateLoader(false)

    }
    const setStoryDescription = (value) => {
        setWebStoryArticle(prevValue => ({ ...prevValue, excerpt: value }));
    }
    const setStoryTags = (value) => {
        setWebStoryArticle(prevValue => ({ ...prevValue, tags: value }));
    }
    const closeReorder = () => {
        setPageOptions(false);
    }
    const navigateWithCheck = async (url) => {
        const hasChanged = await contentHasChanged();
        if (hasChanged) {
            setPendingAction(url);
            setModalState({ unsaved: true, index: pageNo });
        } else {
            navigate(url);
        }
    }
    const slugFunction = (value) => {
        const newValue = value.toLowerCase().replace(slugRegex, '').replace(/\s+/g, ' ').trim().replaceAll(' ', '-');
        console.log(newValue);
        return newValue;
    }
    const getInitialBackground = (storyPage) => {
        if (storyPage.background_image) {
            return { type: 'image', value: { imageId: storyPage.imageId, background_image: storyPage.background_image } };
        }

        if (storyPage.background_image_by_url) {
            return { type: 'image_by_url', value: { imageId: storyPage.imageId, background_image_by_url: storyPage.background_image_by_url } };
        }

        if (storyPage.background_video) {
            return { type: 'video', value: { videoId: storyPage.videoId, background_video: storyPage.background_video } };
        }

        if (storyPage.background_color) {
            return { type: 'color', value: storyPage.background_color };
        }
        return { type: '', value: {} };

    }
    const setUrlInput = ({ reset = false, valid }) => {

        if (reset) {
            urlImageRef.current.classList.remove('is-valid');
            urlImageRef.current.classList.remove('is-invalid');
            urlImageRef.current.value = '';
            setPreviewState('');

            return;
        }

        if (valid) {
            urlImageRef.current.classList.remove('is-invalid');
            urlImageRef.current.classList.add('is-valid');
        } else {
            urlImageRef.current.classList.add('is-invalid');
            urlImageRef.current.classList.remove('is-valid');
        }
    }
    const navigateToPage = (direction) => {
        console.log('as')
        console.log(pageNo - 1)
        switch (direction) {
            case 'previous':
                console.log('previous')
                if (pageNo !== 1) {
                    console.log('previous')
                    changePage(pageNo - 1);
                }
                break;
            case 'next':
                console.log('next')
                if (pageNo !== allPages.length) {
                    console.log('next')
                    changePage(pageNo + 1)
                }
                break;
            default:
                break;
        }
    }

    /***          ALERT RELATED FUNCTIONS           **/

    const openAlert = (obj, autoClose = true, time=1500) => {
        setAlertState(obj);
        if (autoClose) {
            setTimeout(() => {
                closeAlert();
            }, time);
        }
    }
    const closeAlert = () => {
        setAlertState(defaultAlertState);
    }

    /***           UPDATIONS RELATED FUNCTIONS           **/
    const updateText = (newValue, delta, source, editor) => {
        console.log('\n \n -------------- Updating Text -------------- ', reactQuillRef.current);
    
        const innerContent = editor.getText();
        console.log(innerContent);
        
        if (newValue) {
          // Preserve multiple spaces by replacing them with HTML entities
          newValue = newValue.replace(/  +/g, (match) => {
            return ' ' + '\u00A0'.repeat(match.length - 1);
          });
    
          if (pageNo > 1 ? innerContent.length > descriptionWordlimit : innerContent.length > headlinesWordlimit) {
            openAlert({ status: true, msg: 'Word Limit Reached', color: 'info' });
          } else if (newValue.match(/<p>/g) && (newValue.match(/<p>/g).length > paragraphWordlimit)) {
            openAlert({ status: true, msg: 'Paragraph Limit Reached', color: 'info' });
          } else {
            if (currentPage && Object.keys(currentPage).length) {
              if (pageNo > 1) {
                console.log('------------- DESCRIPTION UPDATE-------------');
                let content = reactQuillRef.current.getEditor().getContents();
    
                console.log("JSON.stringify(content)", (content.ops[content.ops.length-1]['insert']).replaceAll("\n",' '));
                currentPage.description !== newValue ? setCurrentPage(prevState => ({ ...prevState, 'description': newValue })) : console.log('Description not updated');
              } else {
                console.log('------------- HEADLINE UPDATE-------------');
                currentPage.headlines !== newValue ? setCurrentPage(prevState => ({ ...prevState, 'headlines': newValue })) : console.log('Headlines not updated');
              }
            } else {
              console.log('do not update text');
            }
          }
        }
        console.log('\n \n -------------- Finished Updating Text --------------');
    };

    const updateBackground = (value, isImage) => {
        if (isImage) {
            setCurrentPage(prevState => ({ ...prevState, ...value }));
        } else {
            if (currentPage.imageId || currentPage.videoId) {
                setCurrentPage(prevState => ({ ...prevState, 'background_color': value, imageId: false, videoId: false, background_image: false, background_video: false }));
                fileRef.current.value = null;
            } else {
                setCurrentPage(prevState => ({ ...prevState, 'background_color': value }));
            }
        }
    }
    const updateBackground2 = (value, type) => {
        switch (type) {
            case 'image':
                setCurrentPage(prevState => ({ ...prevState, ...value, background_image_by_url: false, background_color: false, videoId: false, background_video: false }));
                break;
            case 'color':
                setCurrentPage(prevState => ({ ...prevState, imageId: false, background_image: false, background_image_by_url: false, 'background_color': value, videoId: false, background_video: false }));
                fileRef.current.value = null;
                break;
            case 'video':
                setCurrentPage(prevState => ({ ...prevState, imageId: false, frames: null, frameStyle: null, background_image: false, background_image_by_url: false, background_color: false, ...value }));
                break;
            case 'image_by_url':
                setCurrentPage(prevState => ({ ...prevState, ...value, background_image: false, background_color: false, videoId: false, background_video: false }));
                break;
            default:
                setCurrentPage(prevState => ({ ...prevState, ...value }));
                break;
        }
    }
    const updateEmbedHtml = (value) => {
        console.log('updateEmbedHtml', value)
        let html = '';
        if (value.iframe) {
            console.log('iframe it is');
            html = getIframeHtml(value.iframe);
            if (currentPage.iframe !== value.iframe) {
                console.log('updating iframe it is');

                setCurrentPage(prevValue => ({ ...prevValue, iframe: value.iframe }));
            }
        }
        if (value.blockquote) {
            console.log('updating blockquote it is');
            setCurrentPage(prevValue => ({ ...prevValue, blockquote: value.blockquote }));

            if (/class="twitter-tweet"/.test(value.blockquote)) {
                console.log('twitter it is');
                console.log(value.blockquote);
                // let val = value.blockquote.replace(/<blockquote\b[^>]+\bclass\s?=\s?[\'"](twitter-tweet)[\'"]+(.*?)>(.*?)<a href="(.*?)status\/(.*?)"(.*?)<\/blockquote>/gis,'$5')
                // console.log(val);
                // html = getTwitterWidget(val.split('?')[0]);
                html = getDefaultWidget('Twitter');

            }
            if (/class="instagram-media"/.test(value.blockquote)) {
                console.log('instagram it is');

                // }
                // if(/class="instagram-media"/.test(value.blockquote)) {
                //     console.log('instagram it is');
                //     // html = getInstagramWidget(value.instagramURL);

                // }
                html = getDefaultWidget('Instagram');
                // html = getInstagramWidget(vak);

            }
        }

        /**
         * Object keys to not set these values on page load 
         * Embed html helps in removing iframe/instaur/ tweeturl from currentpage
         * html covers the case when both the pages contain embed, then just embed html needs to be updated  
         */
        if (html === '' && Object.keys(currentPage).length && (embedhtml)) {

            if (currentPage.iframe) {
                setCurrentPage(prevValue => ({ ...prevValue, iframe: '' }));
            }
            if (currentPage.blockquote) {
                setCurrentPage(prevValue => ({ ...prevValue, blockquote: '' }));
            }
        }
        setEmbedhtml(html);
    }
    /** */
    const updateSortedList = async (reorderedArr, reorderPages) => {
        try {
            await reorderedArr.forEach(async (element, index) => {
                if (element != index + 1) {
                    let payload = {
                        "storyPageData": {
                            "id": reorderPages[index]._id,
                            "order": index + 1
                        }
                    };
                    setLoading(true);
                    const response = await webstoryService.saveWebstoryPage(payload);
                    if(projectDetails.isContractExpired){
                        setLoading(false);
                        projectDetails.setContractExpiredAlert(projectDetails);
                    }
                    else if(!response.status || !response.data) {
                        openAlert({status:true,msg:'Error Reordering Pages', color:'danger'});
                    } 
                }
            });
            setLoading(false);
            setPageOptions(false);
            await getWebstoryPages();
        } catch (err) {
            console.log('WEBSTORY ERROR :: Reorder Page ::', err);
            openAlert({ ...alertState, status: true, msg: 'Failed to Reorder Pages', color: 'danger' });
        }


    }
    const updateAutoAdvance = (value) => {
        setCurrentPage(prevState => ({ ...prevState, 'auto_advance_page': value }))
    }
    const updateBackgroundOptions = (name, value) => {
        // setBackgroundOptions(prevValue => ({ ...prevValue, [name]: value }));
        setCurrentPage(prevValue => ({ ...prevValue, background_options: {
            ...backgroundOptions, [name]: value 
        } }));
    }

    const updateFont = async (value) => {
        setCurrentPage(prevState => ({ ...prevState, 'font': value }));
        await loadFonts(value);
    }

    const updateBgAnimations = async (value) => {
        setCurrentPage(prevState => ({ ...prevState, 'bg_animations': value }));
    }

    const updateTextAnimations = async (value) => {
        setCurrentPage(prevState => ({ ...prevState, 'text_animations': value }));
    }

    const updatePhotoFrame = async (value) => {
        const style = frameStyle[value];
        setCurrentPage(prevState => ({ ...prevState, 'frames': value,'frameStyle' : style?.turbo, imageResolution : JSON.stringify(imageResolution[value]) }));
    }
    
    const updateSinglePageTextAnimation = async() => {
        try {
            setLoading(true);
            saveWebstoryPage(true);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log('WEBSTORY ERROR :: Error Updating Page ::', err);
            openAlert({ ...alertState, status: true, msg: 'Failed to Update Pages', color: 'danger' });
        }
    }

    const updateAllPagesForMeta = async ( property, value , ) => {
        try {
            setLoading(true);
            await saveWebstoryPage(true);
            const response = await webstoryService.updateAllPagesForMeta({property, value, projectId: projectDetails.id, articleId: storyId} );

            if(projectDetails.isContractExpired){
                openAlert({status:false,msg:'', color:''},false);

                projectDetails.setContractExpiredAlert(projectDetails);
            }
            else if(!response.status) {
                openAlert({...alertState, status:true,msg:'Error Saving Page', color:'danger'});
            } else {
                setLoading(false);
                openAlert({ ...alertState, status: true, msg: 'Saved to All Pages' });
            }

            await getWebstoryPages();
        } catch (err) {
            setLoading(false);

            console.log('WEBSTORY ERROR :: Error Updating Page ::', err);
            openAlert({ ...alertState, status: true, msg: 'Failed to Update All Pages', color: 'danger' });
        }
    }

    /***           VALIDATIONS RELATED FUNCTIONS           **/
    const contentHasChanged = async () => {


        const oldPage = allPages[pageNo - 1];
        console.log(currentPage)
        console.log(oldPage)
        const hasChanged = !(isEqual(oldPage, currentPage));

        if (pageNo > 1) {
            if (currentPage.description === templates[pageTemplate].description) {
                return false;
            }
        } else {
            if (currentPage.headlines === templates[pageTemplate].title) {
                return false;
            }
        }

        return hasChanged;
    }
    const validateCurrentPage = (page = currentPage, index = pageNo, updateErrors = true) => {
        // console.log(currentPage)
        console.log('\n \n -------------- Enter Page Validation  --------------')

        if (page.template_name) {
            let errors = [];

            const text = index > 1 ? page.description : page.headlines;
            const templateText = templates[page.template_name][index > 1 ? 'description' : 'title']
            console.log(text)
            console.log(templateText)
            if (text === undefined || text === templateText || reactQuillRef.current.getEditor().getText().trim() === '') {
                errors.push(
                    {
                        msg: `Page ${index}: ${index > 1 ? 'Description' : 'Headlines'} is not set`
                    });
            }

            if (!(page.background_color || page.imageId || page.background_video)) {
                errors.push(
                    {
                        msg: `Page ${index}: Background is not set`
                    });
            }
            if (updateErrors) {
                setValidationErrors(errors);
            } else {
                console.log('\n \n -------------- Exit Page Validation 1 --------------')
                return errors;
            }
        }
        console.log('\n \n -------------- Exit Page Validation 2     --------------')

    }

    const allPagesValid = () => {
        setValidationErrors([])
        let errors = [];
        allPages.forEach((page, index) => {
            const err = validateCurrentPage(page, index + 1, false);
            console.log(err);
            if (err.length) {
                errors.push(...err);
            }
        });
        setValidationErrors(prevState => (errors));
        return errors.length === 0 ? true : false;
    }

    /***           MODAL RELATED FUNCTIONS           **/
    const publishAlert = async () => {
        const hasChanged = await contentHasChanged();
        if (hasChanged) {
            setModalState({ unsaved: true, index: pageNo });
        } else {
            if (allPagesValid()) {
                setPageNo(1);
                await getCategories();
                setModalState({ publish: true })
            } else {
                openAlert({ ...alertState, status: true, msg: 'You have Incomplete Changes', color: 'danger' });
            }
        }
    }
    const cancelPublishAlert = () => {
        setModalState({});
        setValidationErrors([]);
    }
    const cancelSaveAlert = () => {
        if (pendingAction) {
            navigate(pendingAction);
            return;
        }
        setPageNo(prevNo => (modalState.index));
        fileRef.current.value = null;
        setModalState({});
    }
    const saveChanges = async () => {
        await saveWebstoryPage();
        if (pendingAction) {
            navigate(pendingAction);
            return;
        }
        setPageNo(prevNo => (modalState.index));
        setUrlInput({ reset: true, valid: true });
        cancelSaveAlert();
        fileRef.current.value = null;

    }

    /***            API RELATED FUNCTIONS           **/
    const getWebstoryPages = async () => {
        try {
            setLoading(true);
            const response = await webstoryService.getWebstoryPages({ articleId: storyId, projectId: projectDetails.id });
            setLoading(false);
            console.log(response)
            if (!response.status) {
                openAlert({ status: true, msg: 'Error Loading Webstory Pages', color: 'danger' }, false);
            }
            else {
                let currentPageData = {};
                let firstPage = response.data[0];
                setShowAuthor((firstPage.show_author ? firstPage.show_author : false));

                setAllPages([...response.data]);
                if (response.data[pageNo - 1]) {
                    currentPageData = response.data[pageNo - 1];
                    console.log(currentPageData)
                    if (currentPageData.auto_advance_page === undefined) {
                        currentPageData.auto_advance_page = "";
                    }
                    setPageTemplate(currentPageData.template_name);
                    setCurrentPage(prevState  => ({...currentPageData }));

                } else {
                    setPageNo(1);
                }
            }
        } catch (err) {
            setLoading(false);
            console.log("WEBSTORY ERROR :: Get Webstory Pages ::", err);
            openAlert({ status: true, msg: 'Failed to Get Webstory Pages', color: 'danger' }, false);
        }
    }
    const getWebstoryById = async () => {
        try {
            const response = await webstoryService.getWebstoryById({ storyId });
            setLoading(false);
            console.log(response)
            if (!response.status) {
                openAlert({ status: true, msg: 'Error Loading Webstory Data', color: 'danger' }, false);
            }
            else {
                setWebStoryArticle(response.data);
            }
        } catch (err) {
            // setLoading(false);
            console.log("WEBSTORY ERROR :: Get Webstory Data ::", err);
            openAlert({ status: true, msg: 'Failed to Get Webstory Data', color: 'danger' }, false);
            seterrorhtml(PageNotFound);
        }
    }
    // WIP:: add div should be into scrolled into view
    const addNewWebstoryPage = async () => {
        try {
            if (allPages.length >= storypagelimit) {
                openAlert({ status: true, msg: 'Max Limit Reached', color: 'warning' });
            } else {
                let payload = {
                    article_id: storyId,
                    order: allPages.length + 1,
                    template_name: pageTemplate
                };
                console.log('payload', payload);

                const response = await webstoryService.createWebstoryPage(payload);
                if(projectDetails.isContractExpired){
                    projectDetails.setContractExpiredAlert(projectDetails);
                }
                else if(!response.data) {
                    openAlert({status:true, msg:'Error Adding New Page',color:'danger'});
                } else {
                    setAllPages(pages => ([...pages, { ...payload, _id: response.data }]));
                    changePage(allPages.length + 1);
                }
            }
        } catch (err) {
            console.log("WEBSTORY ERROR :: Add New Story Page ::", err);
            openAlert({ status: true, msg: 'Failed to Add Story Page', color: 'danger' }, false);
        }
    }

    const saveWebstoryPage = async (byPassValidation=false) => {
        try {
            if ( byPassValidation || !validationErrors.length) {
                openAlert({ status: true, msg: 'Saving...', color: 'info' }, false);
                let payload = {
                    "storyPageData": {
                        "id": currentPage._id,
                        "page_meta": [
                            createMetaFor('headlines', currentPage.headlines, 'HTML'),
                            createMetaFor('description', currentPage.description, 'HTML'),
                            templates[pageTemplate].direction? createMetaFor("direction", templates[pageTemplate].direction, 'direction') : null,
                            templates[pageTemplate].textAlign ? createMetaFor("textAlign",templates[pageTemplate].textAlign, 'textAlign') : null,
                            (currentPage.background_color && !currentPage.imageId && !currentPage.videoId) ? createMetaFor('background_color', currentPage.background_color, 'color') : null,

                            (currentPage.imageId && currentPage.background_image) ? createMetaFor('background_image', currentPage.imageId, 'media') : null,
                            (currentPage.imageId && currentPage.background_image_by_url) ? createMetaFor('background_image_by_url', currentPage.imageId, 'media') : null,
                            currentPage.videoId ? createMetaFor('background_video', currentPage.videoId, 'media') : null,

                            currentPage.show_author ?  createMetaFor('show_author', currentPage.show_author ,'boolean'):null,

                            createMetaFor('css', JSON.stringify(templates[pageTemplate].cssObj), 'CSS'),
                            currentPage.iframe ? createMetaFor('iframe', currentPage.iframe, 'HTML') : null,
                            currentPage.blockquote ? createMetaFor('blockquote', currentPage.blockquote, 'URL') : null,
                            currentPage.background_options ? createMetaFor('background_options', currentPage.background_options, 'options') : null,
                            currentPage.font ? createMetaFor('font', currentPage.font, 'font') : null,

                            currentPage.bg_animations ? createMetaFor('bg_animations', currentPage.bg_animations, 'bg_animations') : null,
                            currentPage.text_animations ? createMetaFor('text_animations', currentPage.text_animations, 'text_animations') : null,
                            currentPage.frames && !currentPage.videoId ? createMetaFor('frames', currentPage.frames, 'frames') : null,
                            currentPage.frameStyle && !currentPage.videoId ? createMetaFor("frameStyle",currentPage.frameStyle,"frameStyle") :null,
                            currentPage.imageResolution ? createMetaFor('imageResolution', typeof(currentPage.imageResolution) == 'string'? currentPage.imageResolution : JSON.stringify(currentPage.imageResolution), 'imageResolution' ) : null 
                            
                        ],
                        template_name: pageTemplate,
                        auto_advance_page: currentPage.auto_advance_page
                    }
                };
                console.log("payload.storyPageData.page_meta===>",payload.storyPageData.page_meta)
                payload.storyPageData.page_meta = payload.storyPageData.page_meta.filter(e => e != null);
                console.log(payload);
                payload.projectId = projectDetails.id;
                const response = await webstoryService.saveWebstoryPage(payload);
                if(projectDetails.isContractExpired){
                    openAlert({status:false,msg:'', color:''},false);
                    projectDetails.setContractExpiredAlert(projectDetails);
                }
                else if(!response.status) {
                    openAlert({...alertState, status:true,msg:'Error Saving Page', color:'danger'});
                } else {
                    let pages = allPages;
                    pages[pageNo - 1] = currentPage;
                    console.log(pages);
                    setAllPages(pages);
                    openAlert({ ...alertState, status: true, msg: 'Saved Page Successfully' });
                }
            } else {
                setShake(true);
                openAlert({ ...alertState, status: true, msg: 'Fill Out Required Fields', color: 'warning' });

                setTimeout(() => {
                    setShake(false);
                }, 1000);
            }
        } catch (err) {
            console.log("WEBSTORY ERROR :: Save Webstory Page ::", err);
            openAlert({ status: true, msg: 'Failed to Save Page', color: 'danger' });
        }
    }

    const deleteWebstoryPage = async (storyPageId) => {
        try {
            if (pageNo === 1) {
                openAlert({ status: true, msg: 'You cannot delete first page !', color: 'warning' }, true);
            } else {
                let payload = {
                    storyPageId: storyPageId,
                };
                console.log(currentPage)
                // const response = {status:true};
                const response = await webstoryService.deleteWebstoryPage(payload);
                if(projectDetails.isContractExpired){
                    projectDetails.setContractExpiredAlert(projectDetails);
                }
                else if(!response.status) {
                    openAlert({status:true, msg:'Error Deleting Page',color:'danger'});
                } else {
                    openAlert({ status: true, msg: 'Page Deleted Successfully', color: 'success' });

                    await setPageNo(prevPageNo => (prevPageNo - 1));
                    await setAllPages(prevState => (prevState.filter(e => e._id !== storyPageId)));

                }
            }
        } catch (err) {
            console.log("WEBSTORY ERROR :: Delete Webstory Page ::", err);
            openAlert({ status: true, msg: 'Failed to Delete Story Page', color: 'danger' }, false);
        }
    }
    /**
     * Creates uploading file name. 
     * Uploads to S3 when exceeds payload limit.
     */
    const handleFileUpload = async (uploadedFiles) => {
        try {
            if (uploadedFiles && uploadedFiles.length > 0) {
                console.log(uploadedFiles);

                setLoading(true);
                const mediaFile = uploadedFiles[0];
                console.log(mediaFile.type)
                console.log(mediaFile.size)
                /*************************  check if file format is support *******************/
                if (supportedFileTypes.indexOf(mediaFile.type) != -1) {

                    const objectKey = "webstory-" + uuidV1();
                    let mediaData = {};

                    /*************************  UPPER LIMIT REACHED *******************/

                    if (mediaFile.size > upperUploadLimit) {
                        throw (`Video Size Should Be Less than ${upperUploadLimit / 1000000}MB`);
                    }

                    /**  If Image type is found and file size is greater than image upload limit, then do not upload, throw error */
                    if ((allowedFileTypes.image.indexOf(mediaFile.type) != -1) && mediaFile.size > imageUploadLimit) {
                        throw (`Image Should Be Less than ${imageUploadLimit / 1000000}MB`);
                    }

                    /*************************  upload via sending image in payload as bufferstring  base64 *******************/
                    if (mediaFile.size < payloadSizeLimit) {
                        mediaData.base64 = await toBase64(mediaFile);
                        mediaData.base64 = mediaData.base64.split(',')[1];
                    }

                    /*************************  image upload via common s3 , above 4MB moves here  *******************/
                    else {

                        const res = await webstoryService.uploadDetails({ projectId: projectDetails.id });

                        if (!res.status || !res.data) {
                            throw ("Error Occured While Uploading..");
                        } else {

                            const { commonBucket, bucket_region, identityPoolId, parallelSyncingAllowed, gcp_bucket_region,gcpCommonBucket } = res.data;
                            let bucket_name = '';
                            if(projectDetails.cloudProvider === "aws" || parallelSyncingAllowed){
                                await uploadtoCommonS3(mediaFile, commonBucket, bucket_region, identityPoolId, objectKey);
                            }

                            if(projectDetails.cloudProvider === "gcp" || parallelSyncingAllowed){
                                await uploadToCommonGCS(mediaFile, gcpCommonBucket, objectKey);
                            }
                            // switch(projectDetails.cloudProvider){
                            //     case "gcp":
                            //         await uploadToCommonGCS(mediaFile, commonBucket, objectKey);
                            //         break;

                            //     default: 
                            //         await uploadtoCommonS3(mediaFile, commonBucket, bucket_region, identityPoolId, objectKey);
                            //         break;
                            // }

                            const res1 = await webstoryService.copyToProjectS3({
                                projectId: projectDetails.id,
                                objectKey,
                                isVideo: (mediaFile.type === 'video/mp4') ? true : false
                            });

                            if (!res1.status || !res1.data) {
                                throw ("Error Occured While Copying to S3..");
                            } else {
                                console.log(res1)
                                bucket_name = res1.data.bucket_name;
                            }
                            mediaData.fullBucketName = bucket_name;
                            mediaData.filesize = mediaFile.size;
                        }
                    }


                    const payload = {
                        projectId: projectDetails.id,
                        articleId: storyId,
                        contentType: mediaFile.type,
                        mediaData,
                        objectKey
                    };

                    console.log(payload);

                    const response = await webstoryService.webstoryMediaUpload(payload);

                    console.log(response);
                    if (!response.status || !response.data) {
                        throw ("Error Occured While Uploading..");
                    } else {
                        setLoading(false);
                        if (mediaFile.type === 'video/mp4') {
                            updateBackground2({ background_video: response.data.url, videoId: response.data.mediaId }, 'video');
                            openAlert({ ...alertState, status: true, msg: 'Video Saved Successfully' });
                        } else {
                            openAlert({ ...alertState, status: true, msg: 'Image Saved Successfully' });
                            setMediaListing(([{ _id: response.data.mediaId, url_original: response.data.url }, ...mediaListing]));
                            updateBackground2({ background_image: response.data.url, imageId: response.data.mediaId }, 'image');
                        }
                    }
                } else {
                    throw ("Invalid File Format");
                }
            }
            // setStoryBackground({color:{...storyBackground.color,status:false},image:{value:response.data.url,status:true,id:response.data.mediaId}});
        }
        catch (err) {
            console.log(':: Failed to Upload Media :: ', err)
            setLoading(false);
            openAlert({ ...alertState, status: true, msg: err ? err : "Failed to Upload Media", color: 'danger' });
            fileRef.current.value = null;
        }
    }
    const handleUrlUpload = async (event) => {
        console.log(event.target.value)
        console.log(urlImageRef.current)
        console.log(allPages[pageNo - 1])
        const urlRegex = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

        if (event.target.value.trim() === '') {// when field is empty reset the image to last saved image

            setUrlInput({ reset: true, valid: true });

            // const initialBackground = getInitialBackground(allPages[pageNo-1]);
            // updateBackground2(initialBackground.value, initialBackground.type);
            // openAlert({status:true ,msg:"Default Image Set"});
            return;
        }
        if (!urlRegex.test(event.target.value)) {

            setUrlInput({ valid: false });
            setPreviewState('');
            return;
        } else {
            setUrlInput({ valid: true });
            setPreviewState(event.target.value);
            return;
        }
    }
    const registerUrlMedia = async () => {
        try {
            setPreviewState('');
            console.log(urlImageRef.current.value)
            if (urlImageRef.current.value) {
                setLoading(true);
                const payload = {
                    url: urlImageRef.current.value,
                    projectId: projectDetails.id,
                    articleId: storyId,
                }
                const response = await webstoryService.imageUrlUpload(payload);

                console.log(response);
                if (!response.status || !response.data) {
                    setUrlInput({ reset: true, valid: false });
                    throw ("Error Occured While Uploading..");

                } else {
                    setLoading(false);
                    setUrlInput({ reset: true, valid: false });
                    openAlert({ ...alertState, status: true, msg: 'Image Saved Successfully' });
                    setMediaListing(([{ _id: response.data.mediaId, url_original: response.data.url }, ...mediaListing]));
                    updateBackground2({ imageId: response.data.mediaId, background_image_by_url: response.data.url }, 'image_by_url');
                }
            } else {
                throw ("No Value Found ");
            }

        } catch (err) {
            console.log(err);
            setLoading(false);
            openAlert({ status: true, msg: "Error Updating Media" });
        }
    }
    const updatePosterImage = async (acceptedFiles) => {
        try {
            console.log(acceptedFiles)
            let mediaData = {};
            let blobUrl = Array.from(acceptedFiles).map(file => URL.createObjectURL(file))[0];

            console.log(blobUrl);
            if (acceptedPosterImageTypes.indexOf(acceptedFiles[0].type) != -1 && acceptedFiles[0].size < imageUploadLimit) {
                const objectKey = "webstory-" + uuidV1();

                mediaData.base64 = await toBase64(acceptedFiles[0]);
                mediaData.base64 = mediaData.base64.split(',')[1];

                const payload = {
                    contentType: acceptedFiles[0].type,
                    mediaData: mediaData,
                    url_original: blobUrl,
                    objectKey: objectKey
                };
                setWebStoryArticle(prevValue => ({ ...prevValue, posterImage: payload }));
            } else {
                if (!(acceptedPosterImageTypes.indexOf(acceptedFiles[0].type) != -1)) { // check if file format was not found
                    openAlert({ ...alertState, status: true, msg: 'Invalid File Format', color: 'danger' });
                } else {
                    openAlert({ ...alertState, status: true, msg: `File Size Should Be Less than ${imageUploadLimit / 1000000}MB`, color: 'danger' });
                }
                posterFileRef.current.value = '';
            }
        }
        catch (err) {
            posterFileRef.current.value = '';
            console.log(err)
            openAlert({ ...alertState, status: true, msg: 'Failed to Save Poster Image', color: 'danger' });
        }
    }
    const changePage = async (index) => {
        try {
            console.log('\n \n -------------- CHANGE PAGE INITIATED --------------');


            const hasChanged = await contentHasChanged();
            console.log(hasChanged)
            if (hasChanged) {
                setModalState({ unsaved: true, index: index })
            } else {
                fileRef.current.value = null;

                setUrlInput({ reset: true, valid: false });

                setPageNo(prevNo => (index));
            }
        } catch (err) {
            console.log(err);
            openAlert({ status: true, msg: 'Failed to Save Page', color: 'danger' }, false);
        }
    }
    const publishWebstory = async (storyId) => {
        console.log(storyId)
        console.log(projectDetails.id)
        const response = await  webstoryService.publishWebstory({storyId, projectId:projectDetails.id});
        if(projectDetails.isContractExpired){
            projectDetails.setContractExpiredAlert(projectDetails);
        }
        else if(response.status){
            setAlertState({status:true, msg: 'Story Published Successfully'})
        } else {
            setAlertState({ status: true, msg: 'Error Publishing Story', color: 'danger' })
        }
    }
    const publish = async () => {
        try {
            if (!validationErrors.length) {
                const payload = {
                    webstoryArticleData: {
                        categories: webstoryArticle.categories,
                        title: titleRef.current.value,
                        slug: slugRef.current.value,
                        excerpt: webstoryArticle.excerpt,
                        tags: webstoryArticle.tags,
                        published: true
                    },
                    articleId: storyId,
                    projectId: projectDetails.id,
                    posterImage: webstoryArticle.posterImage
                };
                if(publishOption === "scheduled" && !isInvalidTime.status){
                    payload.webstoryArticleData.scheduled = true;
                    payload.webstoryArticleData.pdt = scheduleDateTime.toISOString();
                }
                if(initalScheduleStatus.current === "scheduled" && publishOption === "immediate"){
                    payload.webstoryArticleData.scheduled = false;
                    payload.webstoryArticleData.updateScheduleAndPdt = true;
                }
                console.log(payload);
                openAlert({ ...alertState, status: true, msg: 'Publishing...', color: 'info' }, false);
                cancelPublishAlert();
                const response = await webstoryService.updateWebstory(payload);
                if(projectDetails.isContractExpired){
                    openAlert({...alertState, status:false,msg:'', color:''},false);
                    projectDetails.setContractExpiredAlert(projectDetails);
                }
                else if(!response.status) {
                    openAlert({...alertState, status:true,msg:'Failed to Publish Web Story', color:'danger'});
                } else {
                    // openAlert({...alertState, status:true,msg:'Story Published Successfully'});
                    console.log(response);
                    navigate(`/contentsettings/webstory/list/published`);
                }
            } else {
                openAlert({ ...alertState, status: true, msg: 'You have Incomplete Changes', color: 'danger' });
            }
        }
        catch (err) {
            console.log("WEBSTORY ERROR :: Publish Story ::", err);
            openAlert({ ...alertState, status: true, msg: 'Failed to Publish Story', color: 'danger' });
        }
    }
    const getCategories = async () => {
        try {
            setLoading(true);
            const response = await contentService.getCategories(projectDetails.id);
            if (!response.status) {
                setLoading(false);
                openAlert({ ...alertState, status: true, msg: 'Failed to get Categories', color: 'danger' });
            } else {
                setLoading(false);
                let categ = [];
                response.data.forEach(e => {
                    categ.push({ value: e._id, label: e.name })
                    if(e.sub_categories && e.sub_categories.length) {
                        e.sub_categories.forEach((subCat)=>{
                            categ.push({ value: subCat._id, label: `-${subCat.name}` })
                        })
                    }
                    
                });
                console.log(categ)
                setCategories(categ);
            }
        } catch (err) {
            setLoading(false);
            openAlert({ status: true, msg: 'Failed to get Categories Data', color: 'danger' });
        }
    }
    const handleCategoryChange = (categoryArr) => {
        console.log(categoryArr);
        if (categoryArr !== null) {
            setWebStoryArticle(prevValue => ({ ...prevValue, categories: categoryArr.map(e => e.value) }));
        } else {
            setWebStoryArticle(prevValue => ({ ...prevValue, categories: [] }));
        }
    }
    const uploadtoCommonS3 = async (file, bucket_name, bucket_region, identityPoolId, objectKey) => {
        try {
            console.log('s3 bucket file', file);


            AWS.config.update({
                region: bucket_region,
                credentials: new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: identityPoolId
                })
            });



            let upload = new AWS.S3.ManagedUpload({
                params: {
                    Bucket: bucket_name,
                    Key: objectKey,
                    Body: file,
                    ContentType: file.type
                }
            });

            let promise = upload.promise();

            await promise.then(
                function (res) {
                    console.log('Media Uploaded into Common Bucket', res);

                },
                function (err) {
                    console.log(err)
                    throw ("Error Uploading to Common ")
                }
            );
        } catch (err) {
            console.log(err)
            throw (err);
        }
    }

    const uploadToCommonGCS = async (file, bucketName, objectKey) => {
        try {
            // const chunkSize = 256 * 1024 * 1024;
            const chunkSize = 256 * 1024; // 256 KB
            const totalChunks = Math.ceil(file.size / chunkSize);
            
            const res = await webstoryService.genResumableUrl({
                bucketName: bucketName,
                objectKey: objectKey,
            });

            const signedUrl = res.data;

            console.log("chunkSize: ",chunkSize);
            console.log("totalChunks: ",totalChunks);
            console.log("signedUrl: ",signedUrl);
            console.log("file: ",file);
            
            const response = await fetch(signedUrl, {
                method: 'PUT',
                headers: {
                //   'Content-Range': contentRange,
                  'Content-Type': file.type,
                  'x-goog-resumable' : "start"
                },
                body: file,
            });

            // const fileUploaded = await uploadFileInChunks(file,offset,chunkSize,signedUrl)
        } catch (err) {
            console.error('Error uploading file:', err);
            throw err;
        }
    };
    
    
    const changeTempalte = async()=>{
        try{
            setLoading(true); 
            const response = await webstoryService.changeTemplate({articleId:currentPage.article_id, templateName:pageTemplate, projectId:projectDetails.id})
            if(projectDetails.isContractExpired){
                projectDetails.setContractExpiredAlert(projectDetails);
            }
            else if(!response.status || !response.data) {
                setLoading(false);
                openAlert({ ...alertState, status: true, msg: 'Error Updating Template', color: 'danger' });
            } else {
                setLoading(false);
                console.log(response)
                let pages = response.data.webstoryPages;
                setAllPages(pages);
                setCurrentPage(pages[pageNo - 1]);
                openAlert({ ...alertState, status: true, msg: 'Template Changed Successfully' });
            }
        } catch (err) {
            setLoading(false);
            console.log("WEBSTORY ERROR :: Template Webstory Page ::", err);
            openAlert({ status: true, msg: 'Failed to Change Template', color: 'danger' });
        }
    }

    const updateShowAuthor = async () => {
        try {
            setLoading(true);
            setShowAuthor(prevValue => !prevValue);

            const response = await webstoryService.updateShowAuthor({ articleId: currentPage.article_id });

            if (!response.status || !response.data) {
                setLoading(false);
                openAlert({ ...alertState, status: true, msg: 'Error Updating Author', color: 'danger' });
            } else {
                setLoading(false);
                console.log(response)
                let pages = response.data.webstoryPages;
                if (!showAuthor) {
                    openAlert({ ...alertState, status: true, msg: `${webstoryArticle.author}, your name will be displayed on First Page` });
                } else {
                    openAlert({ ...alertState, status: true, msg: 'Done' });
                }
            }
        } catch (err) {
            console.log("WEBSTORY ERROR :: Failed to Update Author ::", err);
            openAlert({ status: true, msg: 'Failed to Update Author', color: 'danger' });
        }
    }
    const getMediaLibrary = async (skip) => {
        try {
            setLoading(true);
            console.log('mediaListing.length', mediaListing.length)
            const response = await webstoryService.getMediaLibrary({ projectId: projectDetails.id, skip: mediaListing.length })
            if (!response.status || !response.data) {
                setLoading(false);
                openAlert({ ...alertState, status: true, msg: 'Error Fetching Media Library', color: 'danger' });
            } else {
                setLoading(false);
                console.log(response);
                setMediaListing((prevState) => ([...prevState, ...response.data]));
            }
        } catch (err) {
            setLoading(false);
            console.log("WEBSTORY ERROR :: Get Media Library ::", err);
            openAlert({ status: true, msg: 'Failed to Get Media Library', color: 'danger' });
        }
    }
    const getGoogleFonts = async () => {
        try {
            setLoading(true);
            const response = await webstoryService.getGoogleFonts();
            console.log(response, 'fonts')
            if (!response.items) {
                setLoading(false);
                openAlert({ ...alertState, status: true, msg: 'Error Fetching Fonts', color: 'danger' });
            } else {
                setLoading(false);
                let fontFamilyArr = response.items.map(e=>{
                    return {value:e.family, label:e.family} ;
                });

                console.log(fontFamilyArr);
                setFonts(fontFamilyArr);
                return fontFamilyArr;
            }
        } catch (err) {
            setLoading(false);
            console.log("WEBSTORY ERROR :: Error Fetching Fonts  ::", err);
            openAlert({ status: true, msg: 'Failed to Get Google Fonts', color: 'danger' });
        }
    }

    const getImageResoultion =(imageResolution) => {
        return  typeof(imageResolution) =="string"? JSON.parse(imageResolution): imageResolution;
    }
   
    const getFrames = (story) => {
        const image = story.background_image || story.background_image_by_url;
        const imageResolution = getImageResoultion(story.imageResolution);
        return (
            <>
                <style>
                    {(frameStyle[story.frames])?.sortd}
                </style>
                <div className="NWSStory-scene-content">    
                    <div className="NWSStory-layer--img is-cover upper_layer" draggable = "false"
                        style={{pointerEvents :'none' ,position:'absolute'}}>
                        <img src={`/frames/${story.frames}.webp`}  alt="Frame" style={{ width: "100%", height: "100%", objectFit: 'cover'}}/>
                    </div>
                    {image && (
                    <div className={`NWSStory-layer-content ${story.frames}`}
                        style={{ 
                            filter: `brightness(${backgroundOptions.brightness}) 
                            contrast(${backgroundOptions.contrast})`,
                            backgroundImage: `url('${image}')`,
                            backgroundPosition: 'center', 
                            backgroundSize: 'cover',
                            position: "absolute",
                            transform: `scale(${imageResolution?.transform}%)`,
                            cursor: isDragging ? "grabbing" : "grab",  
                            left: `${imageResolution?.left}%`,
                            top: `${imageResolution?.top}%`
                            }}
                        draggable = "false"
                        onMouseMove = {handleMouseMove}
                        onMouseUp = {handleMouseUp}
                        onMouseLeave = {handleMouseUp}
                        onMouseDown = {handleMouseDown}
                        > 
                        {/* <img 
                            className="frame_image"
                            src={image}
                            
                        /> */}
                    </div>
                    )}
                </div>
            </>
        );
    };
    
    

    /***            HTML RETURNS FUNCTIONS           **/

    const getPageBackGround = (story, autoplay = false) => {
        if(!story.videoId && story.frames && !story.background_color){
            return getFrames(story);
        }
        return story.videoId ?
            <video key={story.background_video} style={{ objectFit: 'cover', height: '100%', width: '100%', borderRadius: 2, filter: `brightness(${backgroundOptions.brightness}) contrast(${backgroundOptions.contrast})` }} className='videoTag' autoPlay={autoplay} loop muted>
                <source src={story.background_video} type='video/mp4' />
            </video> :
            (<div className="h-100 w-100 preview-cover" onClick={e => { console.log('hllo') }} style={getBackgroundStyle(story)}></div>);
    }

    const getBackgroundStyle = (story) => {
        if (story.imageId) {
            if (story.background_image) {
                return { backgroundImage: `url('${story.background_image}')`, backgroundPosition: 'center', backgroundSize: 'cover', filter: `brightness(${backgroundOptions.brightness}) contrast(${backgroundOptions.contrast})` };
            }
            if (story.background_image_by_url) {
                return { backgroundImage: `url('${story.background_image_by_url}')`, backgroundPosition: 'center', backgroundSize: 'cover', filter: `brightness(${backgroundOptions.brightness}) contrast(${backgroundOptions.contrast})` };
            }
        } else {
            return { backgroundColor: story.background_color, filter: `brightness(${backgroundOptions.brightness}) contrast(${backgroundOptions.contrast}) ` };
        }

    }
    const noBackground = (story, autoplay = false) => {
        return templates[story.template_name] ? defaultTemplateBg(story, autoplay) : <div className="no_image_available">
            <FontAwesomeIcon className="m-1" style={{ cursor: 'pointer', fontSize: 43 }} icon={faImage} />
            <CardTitle tag='h1' style={{ marginTop: 9 }}>No Image Available</CardTitle>
        </div>;
    }
    const defaultTemplateBg = (story, autoplay) => {
        return templates[story.template_name].media_type ? <video style={{ objectFit: 'cover', height: '100%', width: '100%', borderRadius: 2 }} className='videoTag' autoPlay={autoplay} loop muted>
            <source src={templates[story.template_name].image} type='video/mp4' />
        </video> : <div className="h-100 w-100 preview-cover" onClick={e => { console.log('hllo') }} style={{ backgroundImage: `url('${templates[story.template_name].image}')`, backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
    }
    const liveDetailBox = () => {
        return (<div
            style={currentPage.order > 1 ? templates[pageTemplate]?.css.descriptionCss : templates[pageTemplate]?.css.headlinesCss}
            dangerouslySetInnerHTML={{ __html: currentPage.order > 1 ? (currentPage.description ? currentPage.description : '') : (currentPage.headlines ? currentPage.headlines : '') }}
        >
        </div>)
    }
    const detailBox = (page) => {
        return <div
            style={page.order > 1 ? templates[page.template_name]?.css.descriptionCss : templates[page.template_name]?.css.headlinesCss}
            dangerouslySetInnerHTML={{ __html: (page.description || page.headlines) ? (page.order > 1 ? page.description : page.headlines) : '' }}>
        </div>
    }
    const getIframeHtml = (iframeString) => {
        return ReactHtmlParser(iframeString);
    }
    const getTwitterWidget = (id) => {
        return <TweetEmbed tweetId={id} />
    }
    // const getInstagramWidget = (instagramUrl) => {
    //     console.log(instagramUrl)
    //     return <InstagramEmbed url={instagramUrl} width="80%" />
    // }
    const getDefaultWidget = (val) => {
        return <div >
            Your {val} Embed will be here
        </div>
    }
    const getIconImage = () => {
        if (pageOptions) {
            return <img src={downIcon} style={{ width: '1.4vw' }} />;
        } else {
            return <img src={upIcon} style={{ width: '1.4vw' }} />;
        }
    }
    /***           KEYBOARD SHORTCUT RELATED FUNCTIONS           **/

    const keyboardInteraction = (event) => {
        console.log(event)
        console.log(event.key)
        console.log(event.ctrlKey)

        const key = event.key;
        const ctrlKey = event.ctrlKey;

        if (keys[key]) {
            console.log('here')
            switch (true) {
                case keys[key] === keys.ArrowRight: //ArrowRight
                    console.log('here right')
                    if (ctrlKey) {
                        if (pageNo != allPages.length)
                            changePage(pageNo + 1);
                    }
                    break;
                case keys[key] === keys.ArrowLeft: //ArrowLeft
                    console.log('here left')
                    if (ctrlKey) {
                        if (pageNo !== 1)
                            changePage(pageNo - 1);
                    }
                    break;
                default:
                    break;
            }
        }
    }
    const getBackgroundProperty = () => {
        
        if (currentPage.background_image) {
            return { src: currentPage.background_image };
        }
        if (currentPage.background_image_by_url) {
            return { src: currentPage.background_image_by_url };
        }
        if (currentPage.background_color) {
            return { color: currentPage.background_color };
        }
        if (currentPage.background_video) {
            return { video: currentPage.background_video };
        }

        return '';
    }

    return (
        <div className="storyboard-container" style={{ height: '100vh' }} >
            <style>
                {`\
                    .ql-editor{\
                        font-family: '${currentPage.font ? currentPage.font : defaultFont}';\
                        ${ (currentPage.direction || getTemplateText("direction")) ? `direction: ${currentPage.direction || getTemplateText("direction")};` : "" }
                        ${ (currentPage.textAlign || getTemplateText("textAlign")) ? `text-align: ${currentPage.textAlign || getTemplateText("textAlign")};` : "" }
                    }\
                `}
            </style>
            {getBackgroundProperty().src ? <img src={getBackgroundProperty().src} style={{ height: '100vh', position: 'absolute', width: '100vw', zIndex: 0, filter: 'blur(20px) brightness(0.5)' }} /> : null}

            {getBackgroundProperty().color ? <div style={{ height: '100vh', width: '100vw', position: 'absolute', zIndex: 0, filter: 'blur(20px) brightness(0.5)', backgroundColor: getBackgroundProperty().color }} > </div>
                : null
            }
            {getBackgroundProperty().video ? <video style={{ position: 'absolute', width: '100vw', zIndex: 0, objectFit: 'cover', height: '100vh', filter: 'blur(20px) brightness(0.5)' }} autoPlay={false} muted>
                <source src={getBackgroundProperty().video} type='video/mp4' />
            </video>
                : null
            }
            <Alert id="absolute-top-alert" isOpen={alertState.status} color={alertState.color} >
                {alertState.msg}
            </Alert>
            <Modal size='xl' isOpen={modalState.publish}>
                <div className="d-flex p-0 justify-content-between align-items-center" style={{ padding: 0, background: '#313131cc', color: 'white' }}>
                    <Button style={{ margin: 0, padding: 0, position: 'relative' }} className="publish-close" onClick={cancelPublishAlert}></Button>
                    <span style={{fontSize:"18px" ,fontWeight:700}}> Story Details</span>
                    {/* <a href="#" className="close"></a> */}
                    <Button title="Complete Required Fields "
                        style={{ margin: 0, padding: '9px', width: 'fit-content', right: 0,fontSize:"15px" }}
                        color='success'
                        disabled={(webstoryArticle.categories?.length === 0 || categories.length === 0 || webstoryArticle.title?.trim() === '' || webstoryArticle.slug?.trim() === '' || !webstoryArticle.posterImage || (publishOption === "scheduled" && isInvalidTime.status)) ? true : false}
                        onClick={publish}>
                        {publishOption === "immediate" ?
                            "Publish Story" 
                        :
                            "Schedule Story"}  {(webstoryArticle.categories?.length === 0 || categories.length === 0 || webstoryArticle.title?.trim() === '') ? <FontAwesomeIcon icon={faInfoCircle} /> : <FontAwesomeIcon icon={faCheckCircle} />}
                    </Button>
                </div>
                <ModalBody>
                    <div className="d-flex justify-content-between ">
                        <div style={{ padding: '10px' }}>
                            <FormGroup className="w-100">
                                <h6 style={{ textAlign: "left" }}> Poster Image <span style={{ color: 'red' }}>*</span> </h6>

                                {webstoryArticle?.posterImage?.url_original ?
                                    <img style={{ width: '300px', aspectRatio: '3/4' }} src={webstoryArticle.posterImage.url_original} />
                                    : <FontAwesomeIcon className="m-1" style={{ cursor: 'pointer', height: 'auto', width: '80%' }} icon={faImage} />}

                                <input className="form-control " type="file" ref={posterFileRef} onChange={(e) => { updatePosterImage(e.target.files) }} />
                                <FormText >
                                    Supported formats are png, jpeg, jpg
                                </FormText>
                            </FormGroup>
                        </div>
                        <div style={{ padding: '10px' }}>
                            {/* STORY TITLE */}

                            <FormGroup className="w-100">
                                <h6 style={{ textAlign: "left" }}> Story Title <span style={{ color: 'red' }}>*</span> </h6>
                                <Input
                                    className="form-control mb-2"
                                    name=""
                                    type="text"
                                    invalid={(webstoryArticle.title?.trim() == '')}
                                    onChange={e => { setStoryTitle(e.target.value) }}
                                    defaultValue={webstoryArticle.title}
                                    innerRef={titleRef}
                                />

                                <FormFeedback >
                                    Please Set a Title to Publish Story
                                </FormFeedback>
                            </FormGroup>

                            <FormGroup className="w-100 position-relative">
                                <h6 style={{ textAlign: "left" }}> Story Slug <span style={{ color: 'red' }}>*</span> </h6>
                                <div className="position-relative">
                                    <Input
                                        className="form-control mb-2"
                                        name=""
                                        type="text"
                                        invalid={(webstoryArticle.slug?.trim() == '' || slugRegex.test(webstoryArticle.slug))}
                                        onChange={e => { console.log(e.target.value); setStorySlug(e.target.value.replaceAll(' ', '-')) }}
                                        defaultValue={webstoryArticle.slug}
                                        innerRef={slugRef}
                                    />
                                    {projectDetails.slugAiTranslateStatus && (
                                        <Button 
                                            type="button" 
                                            className="btn btn-success"
                                            style={{ border: '1px solid #ced4da', borderLeft: 'none',backgroundColor: "#6bd098" }}
                                            onClick={() => generateAiSlug()}
                                            disabled={translateLoader}
                                            >
                                           {translateLoader ? (
                                                <>
                                                    <Spinner as="span" animation="border" size="sm" role="status" />
                                                    &nbsp; Generating...
                                                </>
                                            ) : (
                                                " Generate English Slug"
                                            )}
                                        </Button>
                                    )}
                                </div>
                                <FormText >
                                    <i class='fa fa-info-circle pe-1'></i>This box automatically slugifies text.
                                </FormText>
                                <FormFeedback >
                                    Please Set a Slug to Publish Story
                                </FormFeedback>
                            </FormGroup>

                            {/* STORY CATEGORIES */}
                            <FormGroup className="w-100">

                                <h6 style={{ textAlign: "left" }}> Story Categories <span style={{ color: 'red' }}>*</span>  </h6>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    placeholder="Select Categories"
                                    isMulti="true"
                                    isSearchable="true"
                                    value={categories.filter(e => webstoryArticle.categories.indexOf(e.value) != -1)}
                                    options={categories}
                                    onChange={(categoryArr) => handleCategoryChange(categoryArr)}
                                />
                                {categories.length === 0 ? <p style={{ color: 'red' }}>Please <a style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }} onClick={() => { navigate(`/contentsettings/categories`) }}>Create a Category</a> in Order to Publish Web Story </p> : null}
                            </FormGroup>

                            {/* STORY PUBLISHING */}
                            <FormGroup className="w-100">

                                <h6 style={{ textAlign: "left" }}> Publishing <span style={{ color: 'red' }}>*</span>  </h6>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="publishOption" value="immediate"
                                        checked={publishOption === 'immediate'}
                                        onChange={() => setPublishOption('immediate')}
                                        />{' '}
                                        Publish Now
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="publishOption" value="scheduled"
                                        checked={publishOption === 'scheduled'}
                                        onChange={() => {
                                            setPublishOption('scheduled');
                                        }}
                                        />{' '}
                                        Schedule for Later
                                    </Label>
                                    {isInvalidTime.status && (
                                        <span style={{ color: 'red' }}>  {isInvalidTime.message} </span>
                                    )}
                                    {publishOption === "scheduled" && (
                                        <ReactDatetime
                                            className = "form-check-label"
                                            value={scheduleDateTime}
                                            onChange={handleDateTimeChange}
                                            inputProps={{
                                                className: `form-control`,
                                                placeholder: "Schedule Date & Time",
                                                id:"scheduleDateTime",
                                            }}
                                            timeConstraints={{ minutes: { step: 15 } }}
                                            isValidDate={validDate}
                                        />
                                    )}
                                </FormGroup>

                            </FormGroup>

                            {/* STORY DESCRIPTION */}
                            <FormGroup className="w-100">
                                <h6 style={{ textAlign: "left" }}> Story Description </h6>
                                <Input
                                    className="form-control mb-2"
                                    name=""
                                    type="text"
                                    onChange={e => { console.log(e.target.value); setStoryDescription(e.target.value) }}
                                    value={webstoryArticle.excerpt} />

                                <FormText >
                                    Stories with description have search advantages and have better reach.
                                </FormText>
                            </FormGroup>
                            {/* TAGS */}
                            <FormGroup className="w-100">
                                <h6 style={{ textAlign: "left" }}> Add tags </h6>
                                <TagsInput style={{ width: '100%' }} value={webstoryArticle.tags} onChange={e => { setStoryTags(e) }} />
                                <p className="text-muted form-text">
                                    Press <i>Enter or Tab</i> after entering your tag.
                                </p>
                            </FormGroup>
                        </div>
                        <div className="m-2">
                            {validationErrors.map((error, index) => (
                                <div key={index} className={shake ? 'mb-2 p-2 shake' : 'mb-2 p-2'} style={{ backgroundColor: '#28282B', color: 'white', fontSize: '9px' }}>
                                    {error.msg}
                                </div>
                            ))}
                        </div>

                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {/* <Button color='danger' onClick={cancelPublishAlert}>Close</Button> */}
                        {/* <Button color='success'  disabled={(selectedCategory.length===0 || categories.length===0 || webstoryArticle.title?.trim()==='')? true:false} onClick={publish}>Publish Story</Button> */}
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={modalState.unsaved}>
                <ModalBody style={{ padding: '20px' }}>
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="w-25">
                            <img src={warningImage} />
                        </div>
                        <div className="d-flex flex-column align-items-end" style={{ width: '60%' }}>
                            <div style={{ width: '80%' }}>
                                <p style={{ fontSize: '16px' }}>You have unsaved changes. do you want to Save Changes or Discard? </p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button color='danger' onClick={cancelSaveAlert}>Discard</Button>
                                <Button color='success' onClick={saveChanges}>Save Page</Button>
                            </div>

                        </div>


                    </div>
                </ModalBody>
            </Modal>
            {/* { reorder?
                <div style={{position:'absolute',width:'100%', height:'100%',zIndex:100, background:'#000000e6', paddingTop:'5%'}}>
                    
                    <Reorder pagesArr={allPages} close={closeReorder} finish={updateSortedList} getDefaultWidget={getDefaultWidget} key={allPages}/>
                </div>
                :
                null
            } */}
            {errorhtml ? errorhtml :
                <div className="d-flex " style={{ padding: 20, paddingRight: 0, paddingTop: 5 }} onKeyUp={(event) => { keyboardInteraction(event) }} tabIndex="0">
                    <div className='d-flex justify-content-center preview-card-container'  >
                        {/* <div style={{marginRight:'1em'}}>
                        <h3>Page {pageNo} / {allPages.length}</h3>
                    </div> */}
                        <div className="d-flex align-items-center" style={{ height: currentPage.frame ? "650px": '80vh' }}>
                            <div className="h-100 position-relative d-flex " style={{ flexDirection: 'column', marginRight: '10px', alignItems: 'end' }}>
                                <div style={{ height: '30vh' }}>
                                    <div className="action-box mb-2" onClick={() => { webstoryArticle.published ? navigateWithCheck(`/contentsettings/webstory/list/published`) : navigateWithCheck(`/contentsettings/webstory/list/drafts`) }} >
                                        <img className="m-1" style={{ cursor: 'pointer', width: '1.6vw' }} src={backImage} />
                                        
                                        <span className="tooltiptext right">Go Back to All Stories</span>

                                    </div>

                                    <div className={sideOptions === 'settings' ? "action-box open mb-2" : "action-box mb-2"} onClick={e => { setSideOptions('settings') }} >
                                        <img className="m-1" style={{ cursor: 'pointer', width: '1.6vw' }} src={settingsIcon} />

                                        <span className="tooltiptext right"> Webstory Options </span>

                                    </div>
                                    { !currentPage.background_video && (
                                        <div className={sideOptions === 'photoFrames' ? "action-box open mb-2" : "action-box mb-2"} onClick={e => { setSideOptions('photoFrames') }} >
                                            <img className="m-1" style={{ cursor: 'pointer', width: '1.6vw' }} src={cardFramesIcons} />
                                            <span className="tooltiptext right"> photo Frame </span>
                                        </div> 
                                    )}

                                  {currentPage.order>1 ?  <div className={sideOptions.includes('animations')  ? "action-box open mb-2" : "action-box mb-2"} onClick={e => { setSideOptions('bganimations') }} >
                                        <img className="m-1" style={{ cursor: 'pointer', width: '1.6vw' }} src={animationsIcon} />

                                        <span className="tooltiptext right"> Animations </span>

                                    </div>:null}
 
                                    {/* <div className="sliding-box ">
                                        <img className="m-1" style={{ cursor: 'pointer', width: '1.6vw' }} src={authorIcon} />

                                      

                                        <div className="tooltiptext right show-author-webstory"  >
                                            <h6 style={{ fontSize: '1vw', textAlign: 'center' }}>Show Author</h6>
                                            <label class="switch">
                                                <input type="checkbox" checked={showAuthor} onChange={updateShowAuthor} />
                                                <span class="slider"></span>
                                            </label>
                                            <div style={{ fontSize: '0.8vw' }}>Author name is shown on first page</div>
                                        </div>
                                    </div> */}
                                </div>
                                <div className={pageNo !== 1 ? 'arrow-container' : 'arrow-container-disabled'}
                                    style={{ "transform": "scale(-1, 1)" }} title="Previous Page"
                                    onClick={(e) => {
                                        setSideOptions(defaultSideOptions);
                                        navigateToPage('previous');
                                    }} >
                                    <img src={arrowImage} />
                                </div>
                                <div style={{ "justifyContent": "flex-end", "display": "flex", "flexDirection": "column", "height": '32vh' }}>
                                    {/* <div style={{ background: 'white', height: '100%', padding: '0.1vw 0.5vw 0.5vw 0.5vw ', borderRadius: '0.4vw' }} >
                                        <h6 style={{ fontSize: '1vw', textAlign: 'center', marginBottom: '0.1vw' }}> Background Options </h6>
                                        <div className="background-options" style={{ fontSize: '1vw' }}>
                                            <div>
                                                Brightness
                                                <Input
                                                    name="range"
                                                    type="range"
                                                    step="0.1"
                                                    min="0"
                                                    max="2"
                                                    value={backgroundOptions.brightness}
                                                    onChange={(event) => {
                                                        updateBackgroundOptions('brightness', event.target.value)

                                                    }}
                                                />
                                            </div>
                                            <div>
                                                Contrast
                                                <Input
                                                    name="range"
                                                    type="range"
                                                    step="0.1"
                                                    min="0"
                                                    max="2"
                                                    value={backgroundOptions.contrast}
                                                    onChange={(event) => {
                                                        updateBackgroundOptions('contrast', event.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className={sideOptions==='changetemplate'?"action-box open mb-2":"action-box mb-2"}  
                                     onClick={()=>setSideOptions('changetemplate')}
                                     style={{"width":"123px","textAlign":"center","fontWeight":"bolder"}}>
                                        Change Template
                                </div> */}
                                </div>
                            </div>
                            <div className="preview-card " 
                            // onDragOver={handleDragOver}
                            // onDrop={handleDrop}
                            >
                                {getMediaIcon(currentPage)}
                                <div className="m-2 position-absolute" style={{ zIndex: 10 }}>
                                    {validationErrors.map((error, index) => (
                                        <div key={index} className={shake ? 'shake mb-2 p-2 ' : 'mb-2 p-2'} style={{ backgroundColor: '#28282B', color: 'white', fontSize: '9px' }}>
                                            {error.msg}
                                        </div>
                                    ))}
                                </div>

                                {(currentPage.background_color || currentPage.imageId || currentPage.background_video || currentPage.frames) ?
                                    getPageBackGround(currentPage, true) : noBackground(currentPage, true)}

                                {embedhtml ? <div className="embed-container">
                                    {embedhtml}
                                </div> : null}
                                <div className="story-content-container" style={templates[pageTemplate]?.css.containerCss} >
                                    {templates[pageTemplate]?.css.logoCss && pageNo == 1 ?
                                        <img style={templates[pageTemplate]?.css.logoCss} src={appIcon ? appIcon : templates[pageTemplate]?.logo} />
                                        : null}
                                    <ReactQuill
                                      ref={reactQuillRef}
                                      placeholder='Text...'
                                      onChange={updateText}
                                      modules={pageNo > 1 ? toolbarOptions : headerToolbarOptions}
                                      value={pageNo > 1 ? (currentPage.description ? currentPage.description : getTemplateText('description')) : (currentPage.headlines ? currentPage.headlines : getTemplateText('title'))}
                                      style={pageNo > 1 ? {...templates[pageTemplate]?.css.descriptionCss} : {...templates[pageTemplate]?.css.headlinesCss}}
                                      theme='bubble' />
                                </div>
                                {(pageNo === 1 && showAuthor) ? <div className="webstory-author-name">
                                    by {webstoryArticle?.author}
                                </div> : null}
                            </div>
                            <div className="h-100 position-relative">
                                <div style={{ height: '30vh' }}>
                                    <div className="action-box mb-2" style={(allPages.length >= storypagelimit) ? { opacity: 0.5 } : null} onClick={addNewWebstoryPage}>
                                        <img className="m-1" style={{ cursor: 'pointer', width: '1.3vw' }} src={addPage} />

                                        {/* <img  className="m-1" style={{cursor:'pointer', width:'18px'}} src={AddStoryPageIcon} /> */}

                                        {!(allPages.length >= storypagelimit) ? <span className="tooltiptext">Add New Page</span> : null}
                                    </div>
                                    <div className={sideOptions.includes('background') ? "action-box open mb-2" : "action-box mb-2"} onClick={(event) => { setSideOptions('backgroundmediaImage') }} >
                                        {/* <FontAwesomeIcon  className="m-1" style={{cursor:'pointer'}} icon={faPlus} /> */}
                                        <img className="m-1" style={{ cursor: 'pointer', width: '2vw' }} src={backgroundIcon} />
                                        <span className="tooltiptext">Set Background</span>

                                    </div>
                                    <div className={sideOptions === 'embed' ? "action-box open mb-2" : "action-box mb-2"} onClick={(event) => { setSideOptions('embed') }} >
                                        <img className="m-1" style={{ cursor: 'pointer', width: '1.6vw' }} src={embedIcon} />
                                        <span className="tooltiptext">Embed</span>

                                    </div>


                                </div>
                                <div className={pageNo !== allPages.length ? 'arrow-container' : 'arrow-container-disabled'} title="Next Page"
                                    onClick={(e) => {
                                        setSideOptions(defaultSideOptions);
                                        navigateToPage('next');
                                    }} >
                                    <img src={arrowImage} />
                                </div>
                                <div style={{ "justifyContent": "flex-end", "display": "flex", "flexDirection": "column", "height": '32vh' }}>
                                    <div className="action-box " style={pageNo > 1 ? null : { opacity: 0.5 }} onClick={() => { deleteWebstoryPage(currentPage._id) }}>
                                        {/* <FontAwesomeIcon  className="m-1" style={{cursor:'pointer'}} icon={faTrash} /> */}
                                        <img className="m-1" style={{ cursor: 'pointer', width: '1.3vw' }} src={DeleteIcon} />

                                        {pageNo > 1 ? <span className="tooltiptext">Delete Page</span> : <span className="tooltiptext">Cannot Delete First Page</span>}
                                    </div>
                                </div>

                            </div>

                            {/* <ScrollContainer className="scroll-container d-flex small-preview-card-container" horizontal>
                            {allPages.map((page, index)=>(
                                    <div key={index} className="small-preview-card " style={(index==pageNo-1)?{opacity:1, border:'2px solid '}:null}
                                        onClick={()=>{changePage(index+1)}}> 

                                            {(page.background_color || page.imageId || page.background_video)?
                                                getPageBackGround(page):noBackground(page)}
                                            {page.blockquote? <div className="embed-container">
                                                {getDefaultWidget('Twitter')}
                                            </div>:null}
                                            <div className="story-content-container" style={templates[page.template_name]?.css.containerCss}>
                                            {templates[pageTemplate]?.css.logoCss && index==0? 
                                                <img style={templates[pageTemplate]?.css.logoCss} src={appIcon? appIcon: templates[pageTemplate]?.logo}/>
                                            :null}
                                                <div className='ql-snow' style={templates[page.template_name]?.css.qlSnowCorrectionCss? templates[page.template_name]?.css.qlSnowCorrectionCss:{}}>
                                                    {index==pageNo-1?liveDetailBox():detailBox(page)}
                                                </div>
                                            </div>
                                    </div>
                                )
                            )}
                        </ScrollContainer>  */}


                        </div>

                        <div>

                            {/* <div className="action-box mb-2" onClick={()=>{navigateWithCheck(`/dashboard/webstory/${slug}/${storyId}`)}}>
                            <img  className="m-1" style={{cursor:'pointer', width:'22px'}} src={GridView} />

                            <span className="tooltiptext">Grid View</span>
                        </div> */}
                            {/* <div className="action-box mb-2">
                            <FontAwesomeIcon  className="m-1" style={{cursor:'pointer'}} icon={faCircleNotch} />
                            <span className="tooltiptext">Reset</span>
                        </div> */}

                            {/* <div className="action-box mb-2"  onClick={()=>{webstoryArticle.published?navigateWithCheck(`/contentsettings/webstory/list/published`):navigateWithCheck(`/contentsettings/webstory/list/drafts`)}}>
                            <img  className="m-1" style={{cursor:'pointer', width:'18px'}} src={ViewAllStoriesIcon} />

                            <span className="tooltiptext">View All Stories</span>
                        </div> */}
                            {/* <div className="action-box"  onClick={()=>{allPages.length>=3?setReorder(true):console.log('\n ** Cannot reorder ** \n')}}>
                            <img  className="m-1" style={allPages.length>=3?{cursor:'pointer', width:'22px'}:{cursor:'pointer', width:'22px', opacity:0.5}} src={ReorderImage} />
                            {allPages.length>=3? <span className="tooltiptext">Reorder Pages</span>:<span className="tooltiptext">Need to have atleast 3 pages to reorder. </span>}
                        </div> */}
                        </div>
                    </div>
                    <div style={{ width: '30%', position: 'relative', zIndex: '2' }}>
                        <Col style={{ background: '#313131', color: 'white', padding: '0 1vw', textAlign: 'center', fontWeight: 'bolder' }}>
                            <span style={{ fontSize: '1.4vw' }}>STORYBOARD</span>
                        </Col>
                        <div className="webstory-edit-options">

                            <div className="col-sm-12" style={sideOptions.includes('background') ? {  } : { display: 'none'}} >
                                <div>
                                    <div className="w-100 d-flex set-background" style={{ marginBottom: '1vw' }} >

                                        <div className={sideOptions.includes('backgroundmedia') ? "action-box open mb-2" : "action-box mb-2"} title='Insert Background Media' onClick={e => { setSideOptions('backgroundmediaImage') }} >
                                            <img className="m-1" style={{ cursor: 'pointer', width: '2.6vw', paddingTop: '0.5vw' }} src={insertBackgroundIcon} />
                                        </div>

                                        <div className={sideOptions === 'backgroundcolor' ? "action-box open mb-2" : "action-box mb-2"} title='Insert Background Color' onClick={e => { setSideOptions('backgroundcolor') }} >
                                            <img className="m-1" style={{ cursor: 'pointer', width: '4vw' }} src={backgroundColorIcon} />
                                        </div>

                                    </div>
                                    <div className="mb-2" style={sideOptions.includes('backgroundmedia') ? {} : { display: 'none' }} >
                                        <div style={sideOptions === 'backgroundmediaImage' ? {} : { display: 'none' }}>
                                            <h6> UPLOAD BACKGROUND </h6>
                                            {/* <div 
                                            className="p-2 m-0" 
                                            title="Upload Image" 
                                            style={{background: '#0085e9', borderRadius: 3, cursor: 'pointer', borderWidth: 2, fontWeight: 600, fontSize: '0.8571em', lineHeight: '1.35em', textTransform: 'uppercase', textAlign:'center',color:'white'}}
                                            onClick={(e)=>{fileRef.current?.click()}}
                                            > 
                                            Upload Image or Video
                                        </div> */}
                                            <div className="d-flex" style={{ alignItems: 'center', justifyContent: 'end' }}>
                                                <div className={sideOptions === 'backgroundmediaurl' ? "action-box open m-0" : "action-box m-0"} onClick={e => { setSideOptions('backgroundmediaurl') }} >
                                                    <img className="m-0" style={{ cursor: 'pointer', width: '1.6vw' }} src={imageByUrl} title='Enter Image URL' />
                                                </div>
                                                <div
                                                    className="p-2 m-0"
                                                    style={{ "background": "white", "borderRadius": "0.3vw", "cursor": "pointer", "borderWidth": "0.2vw", "fontWeight": "600", "fontSize": "1vw", "lineHeight": "1vw", "textTransform": "uppercase", "textAlign": "center", "color": "rgb(0, 133, 233)", boxShadow: '3px 3px 5px 0px #8080805e' }}
                                                    title='Upload Image'
                                                    onClick={e => { fileRef.current?.click() }} >
                                                    Upload Image or Video
                                                    <img className="m-1" style={{ cursor: 'pointer', width: '1.6vw' }} src={uploadIcon} />
                                                </div>

                                            </div>
                                            <div className="form-text text-muted" style={{ textAlign: 'right' }} >
                                                Supported formats are png, jpeg, jpg, and mp4
                                            </div>
                                            <input
                                                className="form-control "
                                                type="file"
                                                ref={fileRef}
                                                style={{ display: 'none' }}
                                                onChange={(e) => { handleFileUpload(e.target.files) }} />
                                            <div className="media-library-container" ref={mediaContainerRef} onScroll={async (event) => {
                                                if (mediaContainerRef.current.scrollTop / (mediaContainerRef.current.scrollHeight - mediaContainerRef.current.clientHeight) >= 0.95) {
                                                    // call API here
                                                    if (!loading) {
                                                        console.log('hererererererer')
                                                        console.log(mediaListing.length)
                                                        await getMediaLibrary();
                                                    }
                                                }
                                            }}>
                                                {mediaListing.map(media => {
                                                    return <div className="media-library-img-container" key={`${media.url_original}${media._id}`}
                                                            draggable="true"
                                                            onDragStart={(event)=> handleDragStart(event, media)}
                                                            onClick={() => {
                                                                if (media.url_original.includes('img.cdn.sortd') || media.url_original.includes('img.gcdn.sortd')) {
                                                                    updateBackground2({ background_image: media.url_original, imageId: media._id }, 'image');
                                                                } else {
                                                                    updateBackground2({ background_image_by_url: media.url_original, imageId: media._id }, 'image_by_url');
                                                                }
                                                        }}  >
                                                        <LazyLoadImage
                                                            key={`${media.url_original}`}
                                                            url={media.url_original}
                                                            failCallback={(event) => {
                                                                event.target.parentNode.parentNode.style.display = 'none';
                                                            }}
                                                        />
                                                    </div>
                                                })}
                                            </div>

                                        </div>


                                        {/* <div class="separator m-2">OR</div> */}
                                        {/* <div className="p-2 m-0 mb-2" title="Upload Image" style={{background: 'rgb(221, 225, 228)', borderRadius: 3, cursor: 'pointer', borderWidth: 2, fontWeight: 600, fontSize: '0.8571em', lineHeight: '1.35em', textTransform: 'uppercase', textAlign:'center'}}> 
                                            URL
                                        </div> */}
                                        <div style={sideOptions === 'backgroundmediaurl' ? {} : { display: 'none' }}>

                                            <FormGroup>
                                                <h6> Upload Image By URL</h6>

                                                <Input type="text"
                                                    onChange={(e) => handleUrlUpload(e)}
                                                    placeholder="https://img.org/demo-image-url"
                                                    innerRef={urlImageRef}
                                                />
                                                {previewState ?
                                                    <div style={{ "textAlign": "center", "position": "absolute", "zIndex": "109", "padding": "0 10px 10px", "border": "2px solid gray", "background": "white", "top": "0px", "borderRadius": "5px", "left": "-210px", "width": "200px" }}>
                                                        <h6>PREVIEW</h6>
                                                        <div>
                                                            <img
                                                                style={{ height: '100%' }}
                                                                src={previewState}
                                                                alt="There is no image on this URL"
                                                                onError={e => { setUrlInput({ valid: false }); }}
                                                            />
                                                        </div>
                                                    </div> : null}
                                                <FormText >
                                                    <i class='fa fa-info-circle'></i> This URL will be used to display the Image <br />
                                                </FormText>
                                                <FormFeedback>
                                                    Uh oh! Looks like there is an issue with your URL. Please input a correct URL.
                                                </FormFeedback>
                                                <FormFeedback
                                                    tooltip valid
                                                    style={{ top: '29px', opacity: 1, cursor: 'pointer', boxShadow: '2px 1px 8px 0px grey', right: '4px' }}
                                                    onClick={registerUrlMedia}>
                                                    APPLY
                                                </FormFeedback>
                                            </FormGroup>

                                            <Button color='primary' onClick={() => setSideOptions('backgroundmediaImage')}>
                                                Go Back
                                            </Button>

                                        </div>
                                    </div>
                                    <div style={sideOptions === 'backgroundcolor' ? {} : { display: 'none' }} >
                                        <h6> SET BACKGROUND COLOR</h6>

                                        <TwitterPicker
                                            triangle="hide" colors={colorPickerColors} color={(currentPage.background_color ? currentPage.background_color : defaultBgColor)}
                                            onChange={(color) => { updateBackground2(color.hex, 'color') }}
                                        />
                                        <SliderPicker
                                            color={(currentPage.background_color ? currentPage.background_color : defaultBgColor)}
                                            onChangeComplete={(color) => { updateBackground2(color.hex, 'color') }}
                                        />

                                    </div>

                                </div>
                                {/* <hr className="mb-0"/> */}
                            </div>
                            {/* <div className="col-sm-12 d-flex" >
                            <FormGroup style={{width:'100%',marginBottom:0}} >
                                    <h6>
                                            AUTO ADVANCE PAGE  
                                            <i class='auto_advance fa fa-info-circle p-1'> 
                                                <span className="tooltiptext right">
                                                    <b>Auto Advance to the  next page of the story after a specified amount of time.</b> <br/>
                                                  
                                                </span>
                                            </i> 
                                    </h6> 
                                    <AutoAdvance currentAutoAdvance={currentPage.auto_advance_page} isBackgroundVideo={currentPage.background_video?true:false} updateAutoAdvance={updateAutoAdvance} openAlert={openAlert} />
                            </FormGroup>
                        </div> */}

                            <div className="col-sm-12" style={sideOptions === 'embed' ? { paddingTop: 0 } : { display: 'none', paddingTop: 0 }}    >
                                <h6> EMBEDS </h6>
                                <Embed currentPage={currentPage} changeEmbed={(value) => updateEmbedHtml(value)} />

                            </div>

                            <div className="col-sm-12" style={sideOptions === 'settings' ? { paddingTop: 0 } : { display: 'none', paddingTop: 0 }}    >
                                <h6 style={{"fontSize":"1.2vw","textAlign":"center","background":"#9d9d9d","margin":"0","color":"white"}}> WEBSTORY OPTIONS </h6>
                                <div>
                                    <h6 style={{ fontSize: '1vw', textAlign: 'center', marginBottom: '0.1vw' }}> Background Options </h6>
                                    <div className="background-options" style={{ fontSize: '1vw' }}>
                                        <div>
                                            Brightness
                                            <Input
                                                name="range"
                                                type="range"
                                                step="0.1"
                                                min="0"
                                                max="2"
                                                value={backgroundOptions.brightness}
                                                onChange={(event) => {
                                                    updateBackgroundOptions('brightness', event.target.value)

                                                }}
                                            />
                                        </div>
                                        <div>
                                            Contrast
                                            <Input
                                                name="range"
                                                type="range"
                                                step="0.1"
                                                min="0"
                                                max="2"
                                                value={backgroundOptions.contrast}
                                                onChange={(event) => {
                                                    updateBackgroundOptions('contrast', event.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="show-author-webstory" >
                                    <h6 style={{ fontSize: '1vw', textAlign: 'center' }}>Show Author</h6>
                                    <label class="switch">
                                        <input type="checkbox" checked={showAuthor} onChange={updateShowAuthor} />
                                        <span class="slider"></span>
                                    </label>
                                    <div style={{ fontSize: '0.8vw' }}>Author name is shown on first page</div>
                                </div>
                                <div style={{textAlign: 'center',marginTop: '1vw'}}>
                                    <h6 style={{ fontSize: '1vw' }}>Fonts</h6>
                                   <div className="text-end">
                                        <Select
                                            className="react-select primary text-start "
                                            classNamePrefix="react-select"
                                            placeholder="Select Fonts"
                                            name="fontsId"
                                            id="fontsId"
                                            value={{label: currentPage.font?currentPage.font:defaultFont, value: currentPage.font?currentPage.font:defaultFont} }
                                            options={fonts}
                                            onChange={(selection) => { updateFont(selection.value);console.log(selection.value)}}
                                        />
                                        <Button style={{background:'#439ddd'}} onClick={()=>updateAllPagesForMeta('font', currentPage.font)} >Apply to all pages</Button>
                                   </div>
                                </div>
                            </div>

                            <div className="col-sm-12" style={sideOptions.includes('animations') ? { paddingTop: 0 } : { display: 'none', paddingTop: 0 }}    >
                                
                                <h6 style={{"fontSize":"1.2vw","textAlign":"center","background":"#9d9d9d","margin":"0","color":"white"}}> Animations </h6>
                                
                                <div style={currentPage.order>1? {display:'block'}:{display:'none'}}>
                                    <div className="w-100 d-flex set-background" >
                                        <h6 
                                            style={{ fontSize: '1vw' }} 
                                            onClick={e => { setSideOptions('bganimations') }}
                                            className={sideOptions === 'bganimations' ? "action-box open mb-2" : "action-box mb-2"}
                                            > Background Animation </h6>
                                        <h6 
                                            style={{ fontSize: '1vw' }} 
                                            onClick={e => { setSideOptions('textanimations') }}
                                            className={sideOptions === 'textanimations' ? "action-box open mb-2" : "action-box mb-2"}
                                            > Text Animation </h6>
                                    </div>

                                    <div style={ {textAlign: 'center', display: sideOptions === 'bganimations' ?'block':'none'}}>
                                    <div className="text-end">
                                            <Select
                                                className="react-select primary text-start "
                                                classNamePrefix="react-select"
                                                name="bg_animations"
                                                id="bg_animations"
                                                value={currentPage.bg_animations?bgAnimations.find((e)=>e.value === currentPage.bg_animations ) :{label: 'Select Background Animations',value: ''} }

                                                options={bgAnimations}
                                                onChange={(selection) => { updateBgAnimations(selection.value);console.log(selection.value)}}
                                            />
                                            <Button style={{background:'#ff6c6c'}} onClick={()=>updateBgAnimations(null)} disabled={currentPage.bg_animations?false:true} >Remove</Button>
                                            <Button style={{background:'#439ddd'}} onClick={()=>updateAllPagesForMeta('bg_animations', currentPage.bg_animations)} disabled={currentPage.bg_animations?false:true} >Apply to all pages</Button>
                                    </div>
                                    </div>
                                    <div style={ sideOptions === 'textanimations' ?{textAlign: 'center'} :{textAlign: 'center', display:'none'}}>
                                    <div className="text-end">
                                            <Select
                                                className="react-select primary text-start "
                                                classNamePrefix="react-select"
                                                name="text_animations"
                                                id="text_animations"
                                                value={currentPage.text_animations?textAnimations.find((e)=>e.value === currentPage.text_animations ) :{label: 'Select Text Animations',value: ''} }
                                                
                                                options={textAnimations}
                                                onChange={(selection) => { updateTextAnimations(selection.value);console.log(selection.value)}}
                                            />
                                            <Button style={{background:'#ff6c6c'}} onClick={()=>updateTextAnimations(null)} disabled={currentPage.text_animations?false:true} >Remove</Button>
                                            <Button style={{background:'#439ddd'}} onClick={()=>updateSinglePageTextAnimation()} disabled={currentPage.text_animations?false:true} >Apply to this page </Button>
                                            <Button style={{background:'#439ddd'}} onClick={()=>updateAllPagesForMeta('text_animations', currentPage.text_animations)} disabled={currentPage.text_animations?false:true} >Apply to all pages</Button>

                                    </div>
                                    </div>

                                </div>
                                {currentPage.order==1?<>

                                    <div className="d-flex align-items-center justify-content-between">
                                        <div style={{width:'15%'}}>
                                            <img src={warningImage} />
                                        </div>
                                        <div className="d-flex flex-column align-items-end " style={{ width: '80%' }}>
                                            Animations on the first page of a story are not supported for user experience and performance reasons.
                                        </div>


                                    </div>
                                   
                                </> :null}
                                
                            </div>

                            {/* new photo frame */}
                            
                            <div className="col-sm-12" style={sideOptions.includes('photoFrames') ? { paddingTop: 0 } : { display: 'none', paddingTop: 0 }}    >
                                <h6 style={{"fontSize":"1.2vw","textAlign":"center","background":"#9d9d9d","margin":"0","color":"white"}}> PHOTO FRAMES </h6>
                                <div style={{display:'block'}}>
                                    <div style={{textAlign: 'center', display: 'block'}}>
                                        <div className="text-end">
                                            <Select
                                                className="react-select primary text-start "
                                                classNamePrefix="react-select"
                                                name="photoFrames"
                                                id="photoFrames"
                                                value={currentPage.frames?frames.find((e)=>e.value === currentPage.frames ) :{label: 'Select Photo Frames',value: ''} }
                                                options={frames}
                                                onChange={(selection) => { updatePhotoFrame(selection.value);console.log("photoFrames",selection.value)}}
                                            />
                                            <Button style={{background:'#ff6c6c'}} onClick={()=>updatePhotoFrame(null)} disabled={currentPage.frames?false:true} >Remove</Button>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="drag-drop" >
                                    <h6 style={{ fontSize: '1vw', textAlign: 'center' }}>ViewAdjust</h6>
                                    <label class="switch">
                                    <div>
                                        <Button className="btn btn-primary"  onClick={()=>handleZoom(10)} disabled={!(currentPage.frames && currentPage.imageId)}
                                            style={!(currentPage.frames && currentPage.imageId)? { opacity: 0.2 } : {}}>
                                            Zoom In
                                        </Button>
                                        <Button className="btn btn-primary"  disabled={!(currentPage.frames && currentPage.imageId)}  onClick={()=>handleZoom(-10)}
                                            style={!(currentPage.frames && currentPage.imageId)? { opacity: 0.2 } : {}}>
                                            Zoom Out
                                        </Button>
                                    </div>
                                    </label>
                                    <div style={{ fontSize: '0.8vw' }}>Set Image in  current Frame</div>
                                </div> */}
                            </div>
                        

                            <div className="col-sm-12 select-template" style={sideOptions === 'changetemplate' ? { paddingTop: 0, height: '400px', overflow: 'hidden' } : { display: 'none', paddingTop: 0, height: '400px', overflow: 'hidden' }}    >
                                <div className="d-flex align-items-baseline justify-content-between">
                                    <h6> Select Template </h6>
                                    <button className="btn btn-primary"
                                        disabled={currentPage.template_name === pageTemplate}
                                        style={currentPage.template_name === pageTemplate ? { opacity: 0.2 } : {}}
                                        onClick={() => { setPageTemplate(currentPage.template_name) }}
                                    >
                                        Reset
                                    </button>
                                    <button
                                        className="btn btn-success"
                                        disabled={currentPage.template_name === pageTemplate}
                                        style={currentPage.template_name === pageTemplate ? { opacity: 0.2 } : {}}
                                        onClick={() => { changeTempalte() }}
                                    >
                                        Apply
                                    </button>
                                </div>
                                <div className="justify-content-around w-100 card-group small-template-card-container" style={{ height: '374px', overflowY: 'scroll' }} >

                                    {templateArr.map((template, index) => (
                                        <div key={index} className=" col-3 m-1 small-template-card" >
                                            <Card
                                                style={pageTemplate === template.name ? { boxShadow: '0 6px 10px -4px rgb(0 0 0)', opacity: 1, border: '1px solid blue' } : { boxShadow: '0 6px 10px -4px rgb(0 0 0)', opacity: 0.5 }}
                                                className="story-page-container"
                                                onClick={() => { setPageTemplate(template.name) }}
                                            >
                                                {(currentPage.template_name === template.name) ? <div className="position-absolute h-100 w-100 d-flex justify-content-center align-items-center" style={{ background: '#5a545463', zIndex: '100' }}>
                                                    <span style={{ "padding": "5px", "background": "green", "color": "white", "borderRadius": "7px", "transform": "rotate(45deg)" }}> Applied  </span>
                                                </div>
                                                    : null}

                                                {(pageTemplate === template.name && currentPage.template_name !== template.name) ? <div className="position-absolute h-100 w-100 d-flex justify-content-center align-items-center" style={{ background: '#5a545463', zIndex: '100' }}>
                                                    <span style={{ "padding": "5px", "background": "#1676ac", "color": "white", "borderRadius": "7px", "transform": "rotate(45deg)" }}> Selected  </span>
                                                </div> : null}


                                                {template.media_type ?
                                                    <video style={{ objectFit: 'cover', height: '100%' }} className='videoTag' autoPlay loop muted>
                                                        <source src={template.image} type='video/mp4' />
                                                    </video> :
                                                    // <CardImg
                                                    //     alt="Card image cap"
                                                    //     src={template.image}
                                                    //     top
                                                    //     height="100%"
                                                    //     width="100%"
                                                    //     style={{objectFit:'cover'}}
                                                    //     loading="lazy"
                                                    // />
                                                    <LazyLoadImage
                                                        key={`${template}${index}`}
                                                        url={template.image}
                                                    />
                                                }

                                                <div style={template.css.containerCss} className='story-content-container'>
                                                    {template.css.logoCss ?
                                                        <img style={template.css.logoCss} src={appIcon ? appIcon : template.logo} />
                                                        : null}
                                                    <div className="headline" style={template.css.headlinesCss} dangerouslySetInnerHTML={{ __html: template.title }}></div>
                                                </div>
                                            </Card>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                        <div >
                            <div style={{ textAlign: 'center' }}>
                                {/* <Button className="final-actions save"  onClick={saveWebstoryPage}>Save</Button>  */}
                                {/* <Button className="final-actions publish" color="success" onClick={publishAlert} >{webstoryArticle.published?'Update':'Publish'}</Button>  */}
                                {/* <Button color="warning" onClick={saveWebstoryCategory}>SAVE Category</Button>  */}
                                {/* <Button color="primary" onClick={()=>{navigate(`/dashboard/webstory/${slug}/${storyId}`)}}>View All Pages</Button>  */}
                                {/* <Button color="info" onClick={()=>{navigate(`/contentsettings/webstory/list`)}}>View All Stories</Button>  */}
                                {/* <Button color="grey" onClick={deleteWebstoryPage()}>Reset</Button>  */}
                                {/* {storyBackground.image.status? <Button color="danger" onClick={resetBackground}>Remove Image</Button>:null } */}
                            </div>
                        </div>

                        {/* <Alert id="absolute-top-alert" isOpen={alertState.status} color={alertState.color} toggle={closeAlert}>
                        {alertState.msg}
                    </Alert> */}


                    </div>

                </div>
            }
            {pageOptions? 
            <div className="position-absolute w-100 " style={{background:'#0000003d', zIndex:100, height:'98vh', top:0}} onClick={() => { setPageOptions((prevValue) => !prevValue) }}>

            </div>:null}
            <div className="story-fixed-bottom-bar">
                <div className="bottom-main-bar d-flex w-100 align-items-center" style={{ height: '16vh' }} >
                    <div style={{ width: '40%', justifyContent: 'unset' }} >
                        <AutoAdvance currentAutoAdvance={currentPage.auto_advance_page} isBackgroundVideo={currentPage.background_video ? true : false} updateAutoAdvance={updateAutoAdvance} openAlert={openAlert} />

                    </div>
                    <div style={{ width: '20%'}}>
                        <Button style={{ fontSize: '1vw', padding: '1vw 1.2vw', borderRadius: '0.3vw' }} onClick={() => { setPageOptions((prevValue) => !prevValue) }}>{pageNo} / {allPages.length} Pages {getIconImage()}</Button>
                    </div>
                    <div style={{ width: '40%' }}>
                        <div style={{ width: '20%' }}>
                            <Button className='mb-0 p-1' onClick={() => { navigateWithCheck(`/dashboard/webstory/${slug}/${storyId}`) }}>
                                <img className="m-1" style={{ cursor: 'pointer', width: '1.4vw' }} src={GridView} />
                            </Button>
                            <p className="m-0 fs-1vw ">Grid View</p>
                        </div>
                        <div className="d-flex justify-content-evenly bottom-action-buttons" style={{ width: '65%' }}>
                            <Button className="final-actions save" onClick={(event) => {saveWebstoryPage()}}>Save</Button>

                            <Button className="final-actions publish" color="success" onClick={publishAlert} >{webstoryArticle.published ? 'Update' : 'Publish'}</Button>
                        </div>
                    </div>
                </div>
                <div className={pageOptions ? "bottom-reorder-listing open  " : "bottom-reorder-listing"}>
                    {pageOptions ? <Reorder pagesArr={allPages} close={closeReorder} finish={updateSortedList} getDefaultWidget={getDefaultWidget} key={allPages} changePage={changePage} pageTemplate={pageTemplate} pageNo={pageNo} showAuthor={showAuthor} webstoryArticle={webstoryArticle} defaultFont={defaultFont}/> : null}
                </div>
            </div>
            {loading ?
                <div className="spinner-container">
                    <Spinner className='center-spinner' />
                </div>
                : null}
        </div>

    )
}

export default StoryBoard;
