import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import notificationsService from "../../services/api/notifications-service";
import projectsService from "../../services/api/projects-service";
import ProjectContext from '../../ProjectContext'
import List from "./List"
import Stats from "./Stats"
import './notificaton.css';
// import IzootoCard from "./IzootoCard";
// import MoengageCard from "./MoengageCard";
// import FirebaseCard from "./FirebaseCard";
import NotificationCard from "./NotificationCard";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// reactstrap components
import {
  Spinner,
  Container,
  Row,
  Col,
} from "reactstrap";
const Dashboard = (props) => {
  const projectDetails = useContext(ProjectContext);
  const history = new useHistory();
  if (projectDetails.id == '') {
    history.push("/project/allprojects");
  }
  const projectId = projectDetails.id;
  const [renderComponent, setRenderComponent] = useState(false);
  // const [messageService, setMessageService] = useState({});
  const [propsState, setPropsState] = useState({ ...props });
  // const [izootoPlatform, setIzootoPlatform] = useState([]);
  // const [firebasePlatform, setFirebasePlatform] = useState([]);
  // const [moengagePlatform, setMoengagePlatform] = useState([]);
  const [categories, setCategories] = useState([])
  const [platformArray, setPlatformArray] = useState([])
  const [platformObject, setPlatformObject] = useState({})
  let getProjectSlug = new URLSearchParams(props.location.search).get('project');
  if (!getProjectSlug) {
    history.push({
      search: '?project=' + projectDetails.slug
    })
  }
  useEffect(() => {
    (async () => {
      let domainData = await projectsService.verifyPublicHostSetup(projectDetails.id);
      if (!domainData.status && !domainData.data) {
        toast.info("Public host not  setup for Web Notifications.",{ autoClose: false , theme:"colored" })
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const getProvider = await notificationsService.getProvider(projectId);
        if (!getProvider?.status || !getProvider?.data) {
          console.error("Error: Failed to fetch provider data.");
          history.push("/notifications/provider");
          return;
        }
        const getMessageService = await notificationsService.getNotificationMessageService(projectId);
        if (!getMessageService?.status || !getMessageService?.data) {
          console.error("Error: Failed to fetch notification message service data.");
          return;
        }

        const platformData = getMessageService.data.data ?? {};
        // setMessageService(getMessageService.data.data);
        setPlatformObject(platformData);

        try {
          const platforms = Object.entries(platformData).map(([key, value]) => `${key} (${value})`);
          setPlatformArray(platforms);

          // const platformArrays = {};
          // for (const [key, value] of Object.entries(platformData)) {
          //   if (platformArrays[value]) {
          //     platformArrays[value].push(key);
          //   } else {
          //     platformArrays[value] = [key];
          //   }
          // }
          // console.log("platformArrays : ", platformArrays);
          // setIzootoPlatform(platformArrays['izooto']);
          // setMoengagePlatform(platformArrays['moengage']);
          // setFirebasePlatform(platformArrays['firebase']);
        } catch (platformError) {
          console.error("Error processing platform data:", platformError);
        }

        // Render the component only after all data is set
        setRenderComponent(true);
      } catch (error) {
        // Log general errors
        console.error("Error in useEffect:", error);
      }
    })();
  }, [projectId, history]);

  useEffect(() => {
    (async () => {
      let getCategories = await notificationsService.getCategories(projectId)
      if (getCategories && getCategories.data) {
        setCategories(getCategories.data)
      }
    })()
  }, [])

  return (
    <div className="content mt-30">
      <Container>
        {renderComponent ?
          <Row>
            <Stats />

            <Col className="ml-auto mr-auto" md="5">
              {/* {(firebasePlatform && firebasePlatform.length > 0) ?
                <FirebaseCard {...propsState} platform={firebasePlatform} categories={categories} />
                : ""}
              {(izootoPlatform && izootoPlatform.length > 0) ?
                <IzootoCard {...propsState} platform={izootoPlatform} categories={categories} />
                : ""}
              {(moengagePlatform && moengagePlatform.length > 0) ?
                <MoengageCard {...propsState} platform={moengagePlatform} categories={categories} />
                : ""} */}
              {(platformArray && platformArray.length > 0) ?
                <NotificationCard {...propsState} platformObject={platformObject} categories={categories} />
                : ""}
            </Col>
            <Col className="ml-auto mr-auto" md="7">
              <List />
            </Col>
          </Row>
          :
          <Row>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </Row>

        }
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
        }}
      />
      <ToastContainer position='bottom-right' />


    </div>
  );

}

export default Dashboard;
