import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ProjectContext from '../../ProjectContext';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from 'reactstrap';
import Pagination from 'react-js-pagination';
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faSearch, faSync, faPlusCircle, faTimes, faGamepad } from '@fortawesome/free-solid-svg-icons';
import './games.css'
import gameServices from "../../services/api/games-service"

const GamesListing = (props) => {
  const urlArticleId = new URLSearchParams(props.location.search).get("articleid") || "";
  const history = useHistory();
  const { id: projectId } = useContext(ProjectContext);

  const gameTypeOptions = [
    { value: 'headline_scramble', label: 'Headline Scramble' },
    { value: 'quiz', label: 'Quiz' },
    { value: 'poll', label: 'Poll' },
    { value: 'crossword', label: 'Crossword' },
    { value: 'hangman', label: 'Hangman' },
  ];
  const gameTypeLookup = {
    "headline_scramble": "Headline Scramble",
    "quiz": "Quiz",
    "poll": "Poll",
    "crossword": "Crossword",
    "hangman":"Hangman",
  }
  const [games, setGames] = useState([]);
  const [filters, setFilters] = useState({
    article_id: urlArticleId,
    game_id: '',
    game_type: null,
    start_date: null,
    end_date: null,
  });
  const [formdata, setFormdata] = useState({
    article_id: '',
    game_id: '',
    game_type: null,
    start_date: null,
    end_date: null,
  })
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalItems: 0
  });
  const [selectedGame, setSelectedGame] = useState(null);

  const [gameDetails, setGameDetails] = useState(null);
  const [isGameDetailsLoading, setIsGameDetailsLoading] = useState(false);
  const [gameDetailsError, setGameDetailsError] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const firstRecord = (pagination.currentPage - 1) * pagination.pageSize + 1;
  const lastRecord = Math.min(pagination.currentPage * pagination.pageSize, pagination.totalItems);

  const fetchGames = async () => {

    if (!validateFilters()) return;

    setIsLoading(true);
    setError(null);
    try {
      const result = await gameServices.fetchGames({
        project_id: projectId,
        article_id: filters.article_id || undefined,
        game_id: filters.game_id || undefined,
        game_type: filters.game_type?.value || undefined,
        created_at: filters.created_at || undefined,
        start_date: filters.start_date || undefined,
        end_date: filters.end_date || undefined,
        page: pagination.currentPage,
        page_size: pagination.pageSize
      })
      if (result && result.status === false) {
        setGames([]);
        setError('No games found');
      } else {
        setGames(result.data.games || []);
        setPagination(prev => ({
          ...prev,
          totalItems: result.data.count || 0
        }));
      }
    } catch (err) {
      setError('Error fetching games');
      setGames([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGameDetails = async (gameId) => {
    setIsGameDetailsLoading(true);
    setGameDetailsError(null);
    setGameDetails(null);

    try {
      const response = await gameServices.fetchGameDetails({
        game_id: gameId,
        project_id: projectId
      });

      if (response.status === false) {
        setGameDetailsError('Unable to fetch game details');
      } else {
        setGameDetails(response.data.games[0]);
      }
    } catch (error) {
      console.error('Error fetching game details:', error);
      setGameDetailsError('An error occurred while fetching game details');
    } finally {
      setIsGameDetailsLoading(false);
    }
  };

  useEffect(() => {
    fetchGames();
  }, [pagination.currentPage, formdata]);


  const openGameDetails = (game) => {
    setSelectedGame(game);
    setIsModalOpen(true);
    fetchGameDetails(game.game_id);
  };

  const handleSearch = () => {
    setFormdata({ ...formdata, filters })
    setPagination(prev => ({ ...prev, currentPage: 1 }));
  };

  const handleReset = () => {
    setFormdata({
      article_id: '',
      game_id: '',
      game_type: null,
      start_date: null,
      end_date: null,
    });
    setFilters({
      article_id: '',
      game_id: '',
      game_type: null,
      start_date: null,
      end_date: null,
    })
    setPagination(prev => ({ ...prev, currentPage: 1 }));
  };

  const validateFilters = () => {
    const errors = {};

    if (filters.start_date && filters.end_date) {
      const startDate = new Date(filters.start_date);
      const endDate = new Date(filters.end_date);

      if (startDate > endDate) {
        errors.end_date = 'End date must be after start date';
      }
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '0.75rem', // More rounded
      boxShadow: state.isFocused
        ? '0 0 0 3px rgba(52, 152, 219, 0.2)'
        : 'none',
      borderColor: state.isFocused
        ? '#3498db' // Vibrant blue
        : provided.borderColor,
      transition: 'all 0.3s ease',
      '&:hover': {
        borderColor: state.isFocused
          ? '#3498db'
          : '#95a5a6'
      }
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? '#3498db'
        : state.isFocused
          ? 'rgba(52, 152, 219, 0.1)'
          : 'white',
      color: state.isSelected ? 'white' : 'black',
      transition: 'all 0.3s ease',
      '&:hover': {
        backgroundColor: 'rgba(52, 152, 219, 0.1)',
      }
    })
  };

  return (
    <div className="container-fluid p-4 bg-light" style={{ backgroundColor: '#f4f6f9' }}>
      <Card className="shadow-lg mb-4" style={{
        borderRadius: '1rem',
        border: 'none',
        background: 'linear-gradient(135deg, #f6f8f9 0%, #e5ebee 100%)'
      }}>
        <CardHeader className="bg-white border-bottom-0 p-4" style={{ borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem' }}>
          <div className="d-flex align-items-center justify-content-between">
            <CardTitle
              tag="h2"
              className="mb-0 d-flex align-items-center text-dark"
              style={{ fontWeight: 600 }}
            >
              <FontAwesomeIcon
                icon={faGamepad}
                className="me-3 "
                style={{ fontSize: '1.95rem', color: '#473FF2' }}
              />
              Games Management
            </CardTitle>
            {/* <Button
              className="button d-flex align-items-center"
              onClick={() => (history.push(`/dashboard/create-games?projectid=${projectId}`))}
            >
              <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
              Create Game
            </Button> */}
            <Button
              className="button d-flex align-items-center"
              onClick={() => (history.push('/games/settings'))}
            >
              <FontAwesomeIcon className="me-2" />
              Game Settings 
            </Button>
          </div>
        </CardHeader>
        <CardBody className="pt-0">
          <Form>
            <div className="row mb-3">
              <div className="col-md-3">
                <FormGroup>
                  <Label className="font-weight-bold text-muted mb-2">Article ID</Label>
                  <Input
                    type="text"
                    placeholder="Enter Article ID"
                    value={filters.article_id}
                    onChange={(e) => setFilters(prev => ({
                      ...prev,
                      article_id: e.target.value
                    }))}
                    className="form-control-lg"
                    style={{ borderRadius: '0.75rem' }}
                  />
                </FormGroup>
              </div>
              {/* <div className="col-md-4">
                <FormGroup>
                  <Label className="font-weight-bold text-muted mb-2">Game ID</Label>
                  <Input
                    type="text"
                    placeholder="Enter Game ID"
                    value={filters.game_id}
                    onChange={(e) => setFilters(prev => ({
                      ...prev,
                      game_id: e.target.value
                    }))}
                    className="form-control-lg"
                    style={{ borderRadius: '0.75rem' }}
                  />
                </FormGroup>
              </div> */}
              <div className="col-md-3">
                <FormGroup>
                  <Label className="font-weight-bold text-muted mb-2">Game Type</Label>
                  <Select
                    options={gameTypeOptions}
                    placeholder="Select Game Type"
                    value={filters.game_type}
                    onChange={(selected) => setFilters(prev => ({
                      ...prev,
                      game_type: selected
                    }))}
                    styles={customSelectStyles}
                  />
                </FormGroup>
              </div>
              <div className="col-md-3">
                <FormGroup>
                  <Label className="font-weight-bold text-muted mb-2">Start Date</Label>
                  <Input
                    type="date"
                    value={filters.start_date || ''}
                    onChange={(e) => setFilters(prev => ({
                      ...prev,
                      start_date: e.target.value
                    }))}
                    invalid={!!validationErrors.end_date}

                    style={{
                      borderRadius: '0.75rem',
                      borderColor: validationErrors.end_date ? '#dc3545' : '',
                      paddingRight: validationErrors.end_date ? 'calc(1.5em + .75rem)' : '',
                      backgroundPosition: validationErrors.end_date ? 'right calc(.375em + .1875rem) center' : '',
                      backgroundPositionX: validationErrors.end_date ? 'right calc(0.375em + 0.1875rem)' : '',
                      backgroundPositionY: validationErrors.end_date ? 'center' : '',
                      backgroundSize: validationErrors.end_date ? 'calc(.75em + .375rem) calc(.75em + .375rem)' : ''
                    }}
                  />
                </FormGroup>
              </div>
              <div className="col-md-3">
                <FormGroup>
                  <Label className="font-weight-bold text-muted mb-2">End Date</Label>
                  <Input
                    type="date"
                    value={filters.end_date || ''}
                    onChange={(e) => setFilters(prev => ({
                      ...prev,
                      end_date: e.target.value
                    }))}
                    invalid={!!validationErrors.end_date}
                    style={{
                      borderRadius: '0.75rem',
                      borderColor: validationErrors.end_date ? '#dc3545' : '',
                      paddingRight: validationErrors.end_date ? 'calc(1.5em + .75rem)' : '',
                      backgroundPosition: validationErrors.end_date ? 'right calc(.375em + .1875rem) center' : '',
                      backgroundPositionX: validationErrors.end_date ? 'right calc(0.375em + 0.1875rem)' : '',
                      backgroundPositionY: validationErrors.end_date ? 'center' : '',
                      backgroundSize: validationErrors.end_date ? 'calc(.75em + .375rem) calc(.75em + .375rem)' : ''
                    }}
                  />
                  {validationErrors.end_date && (
                    <FormFeedback className="position-absolute" style={{ top: '100%', left: 0 }}>{validationErrors.end_date}</FormFeedback>
                  )}
                </FormGroup>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12 d-flex justify-content-end gap-3">
                <Button
                  className="button d-flex align-items-center"
                  onClick={handleSearch}
                  disabled={isLoading}
                >
                  <FontAwesomeIcon icon={faSearch} className="me-2" />
                  Search
                </Button>
                <Button
                  className="button d-flex align-items-center"
                  onClick={handleReset}
                  disabled={isLoading}
                >
                  <FontAwesomeIcon icon={faSync} className="me-2" />
                  Reset
                </Button>
              </div>
            </div>
          </Form>
        </CardBody>
      </Card>
      <Card
        className="shadow-lg"
        style={{
          borderRadius: '1rem',
          border: 'none',
          background: 'linear-gradient(135deg, #ffffff 0%, #f0f2f5 100%)'
        }}
      >
        <CardBody>
          {isLoading ? (
            <div className="text-center py-5">
              <Spinner color="primary" />
              <p className="mt-3">Loading games...</p>
            </div>
          ) : error ? (
            <div className="alert alert-danger text-center">{error}</div>
          ) : games.length === 0 ? (
            <div className="alert alert-info text-center">No games found</div>
          ) : (
            <>
              <div className="d-flex justify-content-center align-items-center mb-4">
                <div style={{ marginRight: '15px' }}>
                  <b className="text-muted">{firstRecord} - {lastRecord} of {pagination.totalItems}</b>
                </div>
                <Pagination
                  activePage={pagination.currentPage}
                  itemsCountPerPage={pagination.pageSize}
                  totalItemsCount={pagination.totalItems}
                  pageRangeDisplayed={3}
                  onChange={(pageNumber) => setPagination(prev => ({ ...prev, currentPage: pageNumber }))}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>

              <Table responsive hover striped className="bg-white" style={{ borderRadius: '1rem', overflow: 'hidden' }}>
                <thead className="thead-light text-center">
                  <tr>
                    <th>S.No</th>
                    <th>Article</th>
                    <th>Game Type</th>
                    <th>Created On</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {games.map((game, index) => {
                    return (
                      <tr key={game._id} className="text-center transition-all duration-300 hover:bg-gray-100">
                        <td>{(pagination.currentPage - 1) * pagination.pageSize + index + 1}</td>
                        <td>{game.article_id.title}</td>
                        <td>
                          {gameTypeLookup[game.game_type] || ''}
                        </td>
                        <td>
                          {new Date(game.createdAt).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric'
                          })}<br />
                          {new Date(game.createdAt).toLocaleString('en-US', {
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                            hour12: true,
                          })}
                        </td>
                        <td>
                          <Button
                            className="button d-flex align-items-center mx-auto"
                            size="sm"
                            onClick={() => openGameDetails(game)}

                          >
                            <FontAwesomeIcon icon={faInfo} />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="d-flex justify-content-between align-items-center mt-3 px-3">
                <small className="text-muted">
                  Showing {games.length} of {pagination.totalItems} games
                </small>
              </div>
            </>
          )}
        </CardBody>
      </Card>

      <Modal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(!isModalOpen)}
        size="lg"
        style={{
          borderRadius: '1rem',
          overflow: 'hidden'
        }}
      >
        <ModalHeader
          className="text-white"
          style={{
            backgroundColor: '#473FF2',
            borderBottom: 'none',
            padding: '1.5rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <h5 className="m-0 text-white">Game Details</h5>
        </ModalHeader>
        <ModalBody style={{ backgroundColor: '#f4f6f9' }}>
          {isGameDetailsLoading ? (
            <div className="text-center py-5">
              <Spinner color="primary" />
              <p className="mt-3 text-muted">Loading game details...</p>
            </div>
          ) : gameDetailsError ? (
            <div className="alert alert-danger">{gameDetailsError}</div>
          ) : gameDetails ? (
            <div className="container">
              <div className="row mb-4">
                <div className="col-12 text-center">
                  <h3 className="text-dark mb-3">
                    {gameTypeLookup[gameDetails.game_type] || ''}
                  </h3>
                  <hr className="my-2" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3 p-3 bg-white rounded shadow-sm">
                    <strong className="text-muted">Game ID:</strong>
                    <span className="ml-2 text-dark">{gameDetails.id}</span>
                  </div>
                  <div className="mb-3 p-3 bg-white rounded shadow-sm">
                    <strong className="text-muted">Article ID:</strong>
                    <span className="ml-2 text-dark">{gameDetails.article_id}</span>
                  </div>
                  <div className="mb-4">
                    <h6 className="text-muted mb-3">Game Data:</h6>
                    <pre
                      className="p-3 bg-light rounded border"
                      style={{
                        maxHeight: '300px',
                        overflowY: 'auto',
                        backgroundColor: '#f8f9fa'
                      }}
                    >
                      {JSON.stringify(gameDetails.data, null, 2)}
                    </pre>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </ModalBody>
        <ModalFooter
          className="justify-content-center"
          style={{
            backgroundColor: '#f4f6f9',
            borderTop: 'none',
            padding: '1.5rem'
          }}
        >
          <Button
            className="button d-flex align-items-center"
            onClick={() => setIsModalOpen(false)}

          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default GamesListing;