import React, { useEffect, useState, useContext, useCallback } from "react";
import ProjectContext from "../../ProjectContext.js";

import { useHistory } from "react-router-dom";
import paidArticlesService from "../../services/api/paid-articles-service.js";
import projectUsersService from "../../services/api/project-users-service";
import Moment from "react-moment";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Spinner,
  Form,
  Table,
  Container,
  Row,
  Col,
  Input,
} from "reactstrap";
import Select from "react-select";
import Pagination from "react-js-pagination";
import moment from "moment";
import ReactDatetime from "react-datetime";
import "./Transaction.css";
import ReactBSAlert from "react-bootstrap-sweetalert";
import planService from "../../services/api/plan-service.js";
import FilterComponent from "./FilterComponent.js";
import MembershipService from "../../services/api/membership-service.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faTimes } from "@fortawesome/free-solid-svg-icons";

const MembershipTransactions = (props) => {
  const TRANSACTION_STATUS_OPTIONS = [
    { value: 0, label: "Initiated" },
    { value: 1, label: "Order Created" },
    { value: -1, label: "Order Creation Failed" },
    { value: 2, label: "Completed" },
    { value: -2, label: "Failed" },
  ];

  const TYPE_OPTIONS = [
    { value: "1", label: "One time" },
    { value: "2", label: "Recurring" },
  ];

  const getStatusText = (status) => {
    switch (status) {
      case 0:
        return "Initiated";
      case -1:
        return "Order could not created";
      case 2:
        return "Success";
      case -2:
        return "Failed";
      default:
        return "";
    }
  };

  const projectDetails = useContext(ProjectContext);
  const history = useHistory();
  if (projectDetails.id == "") {
    history.push("/project/allprojects");
  }
  const project_id = projectDetails.id;

  const [formState, setFormState] = useState({
    user_id: "",
    transaction_id: "",
    from_date: "",
    to_date: "",
    project_id: project_id,
    status: [2],
    type: "",
  });

  const [transactions, setTransactions] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [renderComponent, setRenderComponent] = useState(false);
  const [reloadComponent, setReloadComponent] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [firstRecord, setFirstRecord] = useState(0);
  const [lastRecord, setLastRecord] = useState(0);
  const [showPopup, setShowPopup] = useState({ alert: null });
  const [paidArticleEnabled, setPaidArticleEnabled] = useState(false);
  const records = 10;
  const handleReset = useCallback(() => {
    setFormState({
      user_id: "",
      transaction_id: "",
      from_date: "",
      to_date: "",
      project_id: project_id,
      status: [2],
      type: "",
    });

    searchTransaction();
  }, [project_id]);

  const [users, setUsers] = useState([]); // Store users from API

  useEffect(() => {
    // Fetch users from API
    const fetchUsers = async () => {
      try {
        const result = await projectUsersService.fetchUsers({
          project_id: project_id,
        });

        if (result && result.status === false) {
          setUsers([]);
        } else {
          const data = result.data.users;

          const userOptions = data.map((user) => ({
            value: user.user_id, // userId
            label: `${user.name} (${user.email || user.phone_number})`, // User's name
          }));
          setUsers(userOptions);
        }
      } catch (err) {
        console.error("Error fetching users:", err);
        setUsers([]);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await MembershipService.getTransactions(
        formState,
        activePage,
        records
      );
      if (response.status && response.data) {
        let transactions = response.data;
        setTransactions(response.data);
        const totalCount = await MembershipService.getTotalTransactions(
            formState
        );
        if (totalCount.status) {
            setTotalTransactions(totalCount.data.count);
        }
        if (transactions && transactions.length) {
          setFirstRecord((activePage - 1) * records + 1);
          setLastRecord((activePage - 1) * records + transactions.length);
        } else {
          setFirstRecord((activePage - 1) * records);
          setLastRecord((activePage - 1) * records + transactions.length);
        }
      }
      setRenderComponent(true);
    })();
  }, [reloadComponent, activePage]);
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setReloadComponent(Math.random());
  };
  const handleSearch = (e) => {
    e?.preventDefault();
    searchTransaction();
  };

  const searchTransaction = () => {
    setActivePage(1);
    setReloadComponent(Math.random());
  };

  const handleFormChange = useCallback((field, value) => {
    setFormState((prev) => ({ ...prev, [field]: value }));
  }, []);

  const hideAlert = () => {
    setShowPopup({ alert: null });
  };
  const reviewTransactionPopup = (selectedTransaction) => {
    setShowPopup({
      alert: (
        <ReactBSAlert
          Success
          onCancel={() => hideAlert()}
          style={{
            display: "block",
            position: "absolute",
            height: "auto",
            width: "500px",
            maxWidth: "90%",
          }}
          closeOnClickOutside={true}
          showCancel={false}
          showConfirm={false}
          btnSize=""
        >
          {/* Close Button */}
          <div
            className="position-absolute d-flex align-items-center justify-content-center"
            style={{
              right: "12px",
              top: "8px",
              cursor: "pointer",
              zIndex: 1,
              backgroundColor: "red",
              borderRadius: "50%",
              width: "25px",
              height: "25px",
            }}
            onClick={() => hideAlert()}
          >
            <FontAwesomeIcon icon={faTimes} className="text-white" />
          </div>

          {/* Transaction Details Section */}
          <div className="mb-3">
            <h6 className="mb-2">Transaction Details</h6>
            <div className="border rounded">
              <Row className="g-0">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">Transaction ID</div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.pg_txn_id || ""}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">Order ID</div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.order_id || ""}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">PG Transaction ID</div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.pg_txn_id || ""}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">
                    PG Transaction Message
                  </div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.pg_txn_message || ""}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">Amount</div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    ₹{selectedTransaction?.amount / 100 || ""}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">Status</div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {getStatusText(selectedTransaction?.status)}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">Transaction Date</div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    <Moment format="DD MMM YYYY hh:mm:ss a">
                      {selectedTransaction?.createdat}
                    </Moment>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div>
            <h6 className="mb-2">Membership Details</h6>
            <div className="border rounded">
              <Row className="g-0">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">Membership ID</div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.subscription_id?.pg_subscription_id ||
                      ""}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">Paid Count</div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.subscription_id?.paid_count || ""}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">Status</div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.subscription_id?.status || ""}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">Type</div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.subscription_id?.total_count === 1
                      ? "One Time"
                      : selectedTransaction?.subscription_id?.total_count
                      ? "Recurring"
                      : ""}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">Share Url</div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.subscription_id?.share_url || ""}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">
                    Membership Start Date
                  </div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.subscription_id?.start_at
                      ? moment
                          .unix(selectedTransaction?.subscription_id?.start_at)
                          .format("DD MMM YYYY")
                      : ""}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">
                    Membership End Date
                  </div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.subscription_id?.end_at
                      ? moment
                          .unix(selectedTransaction?.subscription_id?.end_at)
                          .format("DD MMM YYYY")
                      : ""}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </ReactBSAlert>
      ),
    });
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 0:
        return "Initiated";
      case 1:
        return "Order Created";
      case -1:
        return "Order Could Not Create";
      case 2:
        return "Completed";
      case -2:
        return "Failed";
      default:
        return "Unknown";
    }
  };

  return (
    <div className="content mt-30">
      {showPopup.alert}
      <Container>
        <Card className="dp-box">
          <CardHeader>
            {/* <CardTitle tag="h4" className="text-center">
                            Plan Purchase History 
                        </CardTitle> */}

            <CardTitle tag="h4">Transaction</CardTitle>
            <Form id="transactionfilters" onSubmit={handleSearch}>
              <Row>
                <Col md="3">
                  <Select
                    isClearable
                    placeholder="Select User"
                    options={users}
                    value={
                      users.find(
                        (option) => option.value === formState.user_id
                      ) || null
                    }
                    onChange={(option) =>
                      handleFormChange("user_id", option?.value || "")
                    }
                  />
                </Col>

                <Col md="3">
                  <ReactDatetime
                    value={formState.from_date}
                    onChange={(date) => handleFormChange("from_date", date._d)}
                    inputProps={{
                      className: "form-control",
                      placeholder: "From Date",
                    }}
                  />
                </Col>

                <Col md="3">
                  <ReactDatetime
                    value={formState.to_date}
                    onChange={(date) => handleFormChange("to_date", date._d)}
                    inputProps={{
                      className: "form-control",
                      placeholder: "To Date",
                    }}
                  />
                </Col>

                <Col md="3">
                  <Input
                    type="text"
                    placeholder="Transaction Id"
                    value={formState.transaction_id}
                    onChange={(e) =>
                      handleFormChange("transaction_id", e.target.value)
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-3">
              <Col md="3">
                      <Select
                        isMulti
                        isClearable
                        placeholder="Select Status"
                        options={TRANSACTION_STATUS_OPTIONS}
                        value={TRANSACTION_STATUS_OPTIONS.filter((option) =>
                          formState.status.includes(option.value)
                        )}
                        onChange={(selectedOptions) =>
                          handleFormChange(
                            "status",
                            selectedOptions
                              ? selectedOptions.map((opt) => opt.value)
                              : []
                          )
                        }
                       
                      />
                </Col>

                <Col md="3">
                  <Select
                    isClearable
                    placeholder="Transaction Type"
                    options={TYPE_OPTIONS}
                    value={
                      TYPE_OPTIONS.find(
                        (option) => option.value === formState.type
                      ) || null
                    }
                    onChange={(option) =>
                      handleFormChange("type", option?.value)
                    }
                  />
                </Col>
                <Col md="6">
                  <Button className="btn-round mr-2" color="info" type="submit">
                    Search
                  </Button>
                  <Button
                    className="btn-round"
                    color="warning"
                    type="button"
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </Form>
            {totalTransactions > 0 ? (
              <div className="pagBox">
                <div className="pagNo">
                  {firstRecord} - {lastRecord} of {totalTransactions}
                </div>
                <div className="pagDgt">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={records}
                    totalItemsCount={totalTransactions}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange.bind(this)}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              </div>
            ) : null}
          </CardHeader>

          {!renderComponent ? (
            <div className="text-center">
              <Spinner className="text-center" animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : null}
          <CardBody>
            <Table responsive>
              <thead className="text-primary">
                {renderComponent ? (
                  <>
                    <th className="text-center">#</th>
                    <th className="text-center">User Name</th>
                    <th className="text-center">Email/Phone No.</th>
                    <th className="text-center">Transaction Id</th>

                    <th className="text-center">Amount(INR)</th>
                    {/* <th className="text-center" >Coupon Discount(INR)</th> */}
                    <th className="text-center">Membership ID</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Transaction Date</th>
                    <th className="text-center">Type</th>
                    <th className="text-center">Action</th>
                  </>
                ) : (
                  ""
                )}
              </thead>
              <tbody>
                {transactions && transactions.length
                  ? transactions.map((details, index) => (
                      <tr
                        className={
                          details.status == 2
                            ? ""
                            : details.status == 1
                            ? ""
                            : details.status == -2
                            ? "table-danger"
                            : ""
                        }
                      >
                        <td className="text-center">{firstRecord + index}</td>
                        <td className="text-center">
                          {details?.user_id?.name ? details?.user_id?.name : ""}
                        </td>
                        <td className="text-center">
                          {details?.user_id?.email || details.user_id?.phone_number || ""}
                        </td>
                        <td className="text-left">{details.pg_txn_id}</td>

                        <td className="text-center">{details.amount / 100}</td>
                        {/* <td className="text-center">
                                            {details.coupon_id ? details.coupon_discount / 100 : "-"}
                                        </td> */}
                        <td className="text-center">
                          {details.subscription_id
                            ? details.subscription_id.pg_subscription_id
                            : ""}
                        </td>
                        <td className="text-center">
                          {getStatusLabel(details.status)}
                        </td>

                        <td className="text-center">
                          {moment(details.createdat).format(
                            "dddd DD MMM YYYY hh:mm:ss a"
                          )}
                        </td>
                        <td className="text-center">
                          {details.type == 1
                            ? "One time"
                            : details.type == 2
                            ? "Recurring"
                            : "One time"}
                        </td>

                        <td className="text-center">
                          <Button
                            style={{ cursor: "pointer" }}
                            className="btn-smb"
                            onClick={() => reviewTransactionPopup(details)}
                          >
                            {" "}
                            <FontAwesomeIcon icon={faInfo} />
                          </Button>
                        </td>
                      </tr>
                    ))
                  : null}
                {renderComponent && (!transactions || !transactions.length) ? (
                  <td colspan="12" className="text-center">
                    <CardTitle tag="h4">No Records Found</CardTitle>
                  </td>
                ) : null}
              </tbody>
            </Table>
          </CardBody>
        </Card>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </Container>
    </div>
  );
};

export default MembershipTransactions;
