
import axios from "axios";

const getGenAIFeatureUsage = async(formdetails,page,records) =>{
   
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    console.log("In service ogf genai usage:- ", formdata);
    const response = await axios.post('/api/gen-ai-feature-usage/get-gen-ai-feature-usage',formdata);
    console.log(response.data)
    return response.data;
}




const getGenAIApiType = async(formdetails,page,records) =>{
 
    const formdata = {
        filters : formdetails,
        page : page,
        records : records
    }
    const response = await axios.get('/api/gen-ai-feature-usage/get-gen-ai-api-type',formdata);
    console.log(response.data)
    return response.data;
}
export  default {
    getGenAIFeatureUsage,
    getGenAIApiType

}
