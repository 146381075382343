/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "./layouts/Auth.js";
import AdminLayout from "./layouts/Admin.js";
import InitialLayout from "./layouts/Intial.js";

import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/paper-dashboard.scss?v=1.2.0";
import "./assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { CookiesProvider } from "react-cookie";
//import configData from "./config/config.json";

// import ReactGA from "react-ga4";

// if(window.location.hostname==='console.sortd.mobi'){
//   ReactGA.initialize("G-N7860XY35S");
// }else{
//   ReactGA.initialize("G-4KKR34B2P6");
// }

//ReactGA.send("pageview");

const root = ReactDOM.createRoot(document.getElementById("root"));

const hist = createBrowserHistory();

root.render(
    <CookiesProvider>
        <Router history={hist}>
            <Switch>
                <Route
                    path="/auth"
                    render={(props) => <AuthLayout {...props} />}
                />
                <Route
                    path="/project"
                    render={(props) => <AdminLayout {...props} />}
                />
                <Route
                    path="/dashboard"
                    render={(props) => <AdminLayout {...props} />}
                />
                <Route
                    path="/user"
                    render={(props) => <AdminLayout {...props} />}
                />
                <Route
                    path="/contentsettings"
                    render={(props) => <AdminLayout {...props} />}
                />
                <Route
                    path="/config"
                    render={(props) => <AdminLayout {...props} />}
                />
                <Route
                    path="/notifications"
                    render={(props) => <AdminLayout {...props} />}
                />
                <Route
                    path="/plan"
                    render={(props) => <AdminLayout {...props} />}
                />
                <Route
                    path="/onboard"
                    render={(props) => <InitialLayout {...props} />}
                />
                <Route
                    path="/plans"
                    render={(props) => <InitialLayout {...props} />}
                />
                <Route
                    path="/apps"
                    render={(props) => <AdminLayout {...props} />}
                />
                <Route
                    path="/paidarticles"
                    render={(props) => <AdminLayout {...props} />}
                />
                <Route
                    path="/features"
                    render={(props) => <AdminLayout {...props} />}
                />
                <Route
                    path="/subscriptions"
                    render={(props) => <AdminLayout {...props} />}
                />
                <Route
                    path="/video"
                    render={(props) => <AdminLayout {...props} />}
                />
                <Route
                    path="/analytics"
                    render={(props) => <AdminLayout {...props} />}
                />
                <Route
                    path="/ctv"
                    render={(props) => <AdminLayout {...props}/>}
                />
                <Route
                    path="/games"
                    render={(props) => <AdminLayout {...props}/>}
                />
                <Route
                    path="/membership"
                    render={(props) => <AdminLayout {...props} />}
                />            
                <Redirect push to="/auth/login" />
            </Switch>
        </Router>
    </CookiesProvider>
);
