import React, { useEffect, useState,useCallback, useContext } from "react";
import ProjectContext from "../../ProjectContext.js";

import { useHistory } from "react-router-dom";
import planService from "../../services/api/plan-service";
import projectService from "../../services/api/projects-service.js";
import Moment from "react-moment";
import {
  Button,
  Card,
  Form , 
  Input,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  Spinner,
  Table,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  Col,
} from "reactstrap";

import Select from "react-select";
import Pagination from "react-js-pagination";
import moment from "moment";
import ReactDatetime from "react-datetime";
import ReactBSAlert from "react-bootstrap-sweetalert";
import FilterComponent from "./FilterComponent.js";
import MembershipService from "../../services/api/membership-service.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faTimes } from "@fortawesome/free-solid-svg-icons";
import projectUsersService from "../../services/api/project-users-service";
const closeBtnStyle = {
  "border-radius": "50%",
  padding: "0.5em",
  width: "30px",
  height: "30px",
  border: "1px solid black",
  color: "black",
  position: "absolute",
  "background-color": "#ffffff",
  "pointer-events": "none",
};
const MembershipSubscriptions = (props) => {
  const projectDetails = useContext(ProjectContext);
  const history = useHistory();
  if (projectDetails.id == "") {
    history.push("/project/allprojects");
  }


  const STATUS_OPTIONS = [
    { value: "created", label: "Created" },
    { value: "authenticated", label: "Authenticated" },
    { value: "activated", label: "Activated" },
    { value: "charged", label: "Charged" },
    { value: "completed", label: "Completed" },
    { value: "pending", label: "Pending" },
    { value: "halted", label: "Halted" },
    { value: "cancelled", label: "Cancelled" },
    { value: "paused", label: "Paused" },
    { value: "resumed", label: "Resumed" },
  ];
  const project_id = projectDetails.id;
  const initialFormState = {
    user_id: "",
    subscription_id: "",
    from_date: "",
    to_date: "",
    status: ["activated" , "charged"],
  };

    const [formState, setFormState] = useState({
      ...initialFormState,
      project_id: project_id,
    });
   
  const [subscriptions, setSubscriptions] = useState([]);
  const [totalSubscriptions, setTotalSubscriptions] = useState(0);
  const [renderComponent, setRenderComponent] = useState(false);
  const [reloadComponent, setReloadComponent] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [firstRecord, setFirstRecord] = useState(0);
  const [lastRecord, setLastRecord] = useState(0);
  const [showPopup, setShowPopup] = useState({ alert: null });
  const [paidArticleEnabled, setPaidArticleEnabled] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [transactionFilter, setTransactionFilter] = useState({
    project_id: project_id,
    subscription_id: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
 
    const [users, setUsers] = useState([]); // Store users from API
  
    useEffect(() => {
      // Fetch users from API
      const fetchUsers = async () => {
        try {
          const result = await projectUsersService.fetchUsers({
            project_id: project_id,
          });
  
          if (result && result.status === false) {
            setUsers([]);
          } else {
            const data = result.data.users;
  
            const userOptions = data.map((user) => ({
              value: user.user_id, // userId
              label: `${user.name} (${user.email || user.phone_number})`, // User's name
            }));
            setUsers(userOptions);
          }
        } catch (err) {
          console.error("Error fetching users:", err);
          setUsers([]);
        }
      };
  
      fetchUsers();
    }, []);
  const records = 10;
  useEffect(() => {
    (async () => {
     
      const response = await MembershipService.getSubscriptions(
       formState,
        activePage,
        records
      );
      if (response.status && response.data) {
        let subscriptions = response.data.subscriptions;
        setSubscriptions(response.data.subscriptions);
        setTotalSubscriptions(response.data.count);
        if (subscriptions && subscriptions.length) {
          setFirstRecord((activePage - 1) * records + 1);
          setLastRecord((activePage - 1) * records + subscriptions.length);
        } else {
          setFirstRecord((activePage - 1) * records);
          setLastRecord((activePage - 1) * records + subscriptions.length);
        }
      }
      setRenderComponent(true);
    })();
  }, [reloadComponent]);

  const hidePaidArticlesNotEnabledPopup = () => {
    hideAlert();
    history.push("/dashboard/home");
  };
  const paidArticlesNotEnabledPopup = () => {
    setShowPopup({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Paid Articles"
          onConfirm={hidePaidArticlesNotEnabledPopup}
          showCancel={false}
          btnSize=""
        >
          <Label>
            This feature is not enabled for your project.If you want to enable
            please contact to Sortd team.
          </Label>
        </ReactBSAlert>
      ),
    });
  };
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setReloadComponent(Math.random());
  };
  const handleFilterSubmit = (
    (e) => {
      e.preventDefault();
      searchSubscription();
    }
  )

  const handleFormChange = useCallback((field, value) => {
    setFormState((prev) => ({ ...prev, [field]: value }));
  }, []);
  const searchSubscription = () => {
    setActivePage(1);
    setReloadComponent(Math.random());
  };

    const handleFilterReset = useCallback(() => {
      setFormState({ ...initialFormState, project_id: project_id });
      setActivePage(1);
      setReloadComponent(Math.random());
    }, [project_id]);
 

  const getStatusText = (status) => {
    switch (status) {
      case 0:
        return "Initiated";
      case -1:
        return "Order could not created";
      case 2:
        return "Success";
      case -2:
        return "Failed";
      default:
        return "";
    }
  };


  const hideAlert = () => {
    setShowPopup({ alert: null });
  };


    const fetchTransactions = useCallback(async () => {
      try {
        const response  = await MembershipService.getTransactions(
          transactionFilter,
          activePage,
        records
        );
        if (response.status && response.data) {
          setTransactions(response.data);
         
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } 
    }, [transactionFilter, activePage]); // Depend on transactionFilter & activePage
  
    const handleSubscriptionClick = useCallback((SubscriptionId) => {
      setTransactionFilter((prev) => ({
        ...prev,
        subscription_id: SubscriptionId,
      }));
      setIsModalOpen(true);
     
    }, []);
  
    useEffect(() => {
      fetchTransactions();
    }, [transactionFilter.subscription_id, fetchTransactions]);
 

  return (
    <div className="content mt-30">
      {showPopup.alert}
      <Container>
    
       
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <CardTitle tag="h4">Membership</CardTitle>
                </div>

                <Form onSubmit={handleFilterSubmit}>
                  <Row>
                    <Col md="3">
                      <Select
                        isClearable
                        placeholder="Select User"
                        options={users}
                        value={
                          users.find(
                            (option) => option.value === formState.user_id
                          ) || null
                        }
                        onChange={(option) =>
                          handleFormChange("user_id", option?.value || "")
                        }
                       
                      />
                    </Col>
                    <Col md="3">
                      <ReactDatetime
                        value={formState.from_date}
                        onChange={(date) =>
                          handleFormChange("from_date", date._d)
                        }
                        inputProps={{
                          className: "form-control",
                          placeholder: "From Date",
                         
                        }}
                      />
                    </Col>
                    <Col md="3">
                      <ReactDatetime
                        value={formState.to_date}
                        onChange={(date) =>
                          handleFormChange("to_date", date._d)
                        }
                        inputProps={{
                          className: "form-control",
                          placeholder: "To Date",
                        
                        }}
                      />
                    </Col>
                    <Col md="3">
                      <Input
                        type="text"
                        placeholder="Membership ID"
                        value={formState.subscription_id}
                        onChange={(e) =>
                          handleFormChange("subscription_id", e.target.value)
                        }
                        
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md="3">
                      <Select
                        isMulti
                        isClearable
                        placeholder="Select Status"
                        options={STATUS_OPTIONS}
                        value={STATUS_OPTIONS.filter((option) =>
                          formState.status.includes(option.value)
                        )}
                        onChange={(selectedOptions) =>
                          handleFormChange(
                            "status",
                            selectedOptions
                              ? selectedOptions.map((opt) => opt.value)
                              : []
                          )
                        }
                       
                      />
                    </Col>
                    <Col md="6">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        type="submit"
                       
                      >
                        Search
                      </Button>
                      <Button
                        className="btn-round"
                        color="warning"
                        type="button"
                        onClick={handleFilterReset}
                       
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </Form>
          

            {totalSubscriptions > 0 ? (
              <div className="pagBox">
                <div className="pagNo">
                  {firstRecord} - {lastRecord} of {totalSubscriptions}
                </div>
                <div className="pagDgt">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={records}
                    totalItemsCount={totalSubscriptions}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange.bind(this)}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              </div>
            ) : null}
          </CardHeader>

          {!renderComponent ? (
            <div className="text-center">
              <Spinner className="text-center" animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : null}
          <CardBody>
            <Table responsive>
              <thead className="text-primary">
                {renderComponent ? (
                  <>
                    <tr>
                      <th >#</th>
                      <th>User Email / Phone No.</th>
                      <th>Membership ID</th>
                      <th>Paid Count</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Created At</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th >Action</th>
                    </tr>
                  </>
                ) : (
                  ""
                )}
              </thead>
              <tbody>
                {subscriptions && subscriptions.length
                  ? subscriptions.map((details, index) => (
                      <tr
                        className={
                          details.status === "cancelled" ? "table-danger" : ""
                        }
                      >
                        <td className="text-left">{firstRecord + index}</td>
                        <td>{details.user_id?.email || details.user_id?.phone_number || ""}</td>
                        <td>{details.pg_subscription_id || ""}</td>

                        <td>{details.paid_count}</td>
                        <td>
                          {details?.total_count === 1
                            ? "One Time"
                            : details?.total_count
                            ? "Recurring"
                            : ""}
                        </td>
                        <td>{details.status}</td>
                        <td>
                          {details?.createdAt
                            ? moment(details?.createdAt)
                                .format("DD MMM YYYY hh:mm:ss a")
                            : ""}
                        </td>
                        <td>
                          {details?.start_at
                            ? moment
                                .unix(details?.start_at)
                                .format("DD MMM YYYY")
                            : ""}
                        </td>
                        <td>
                          {details?.end_at
                            ? moment
                                .unix(details?.end_at)
                                .format("DD MMM YYYY")
                            : ""}
                        </td>
                        <td className="text-center">
                          <Button
                            style={{ cursor: "pointer" }}
                            className="btn-smb"
                            onClick={() =>
                                handleSubscriptionClick(details._id)
                              }
                          >
                            {" "}
                            <FontAwesomeIcon icon={faInfo} />
                          </Button>
                        </td>
                      </tr>
                    ))
                  : null}
                {renderComponent &&
                (!subscriptions || !subscriptions.length) ? (
                  <tr>
                    <td colSpan="12" className="text-center">
                      <CardTitle tag="h4">No Membership Found</CardTitle>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </Table>
          </CardBody>

          <Modal
                isOpen={isModalOpen}
                toggle={() => setIsModalOpen(false)}
                size="lg"
              >
                <ModalBody className="p-3 position-relative">
                  {/* Close Button */}
                  <div
                    className="position-absolute d-flex align-items-center justify-content-center"
                    style={{
                      right: "12px",
                      top: "8px",
                      cursor: "pointer",
                      zIndex: 1,
                      backgroundColor: "red",
                      borderRadius: "50%",
                      width: "25px",
                      height: "25px",
                    }}
                    onClick={() => setIsModalOpen(false)}
                  >
                    <FontAwesomeIcon icon={faTimes} className="text-white" />
                  </div>

                  {/* Transaction Details Section */}
                  <div className="mb-3">
                    <h5 className="mb-3">Transaction Details</h5>

                    <div className="border rounded">
                      {transactions.length > 0 ? (
                        <Table responsive striped>
                          <thead className="text-primary">
                            <tr>
                              <th className="text-left">#</th>
                              <th className="text-left">User Name</th>
                              <th className="text-left">
                                Transaction Amount(INR)
                              </th>
                              <th className="text-left">
                                Effective Amount(INR)
                              </th>
                              <th className="text-left">Transaction Id</th>
                              <th className="text-left">Transaction Date</th>
                              <th className="text-left">Type</th>
                              <th className="text-center">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactions.map((transaction, index) => {
                              const rowNumber =
                                records * (activePage - 1) + index + 1;
                              const className =
                                transaction.status === -2
                                  ? "table-danger"
                                  : transaction.status === 2
                                  ? "table-success"
                                  : "";

                              return (
                                <tr 
                                  key={transaction.id} 
                                  // className={className}
                                  >
                                  <td className="text-left">{rowNumber}</td>
                                  <td className="text-left">
                                    {transaction?.user_id?.name || ""}
                                  </td>
                                  <td className="text-left">
                                    ₹{(transaction?.amount || 0) / 100}
                                  </td>
                                  <td className="text-left">
                                    ₹
                                    {((transaction?.amount || 0) -
                                      (transaction?.coupon_discount || 0)) /
                                      100}
                                  </td>
                                  <td className="text-left">
                                    {transaction.pg_txn_id || ""}
                                  </td>
                                  <td className="text-left">
                                    <Moment format="DD MMM YYYY hh:mm:ss a">
                                      {transaction.createdat}
                                    </Moment>
                                  </td>
                                  <td className="text-left">
                                    {transaction.type === "1"
                                      ? "One time"
                                      : "Recurring"}
                                  </td>
                                  <td className="text-left">
                                    {transaction.status === 1
                                      ? "Order Created"
                                      : getStatusText(transaction.status)}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      ) : (
                        <div className="text-center">
                          <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        </div>
                      )}
                    </div>
                  </div>
                </ModalBody>
              </Modal>
        </Card>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </Container>
    </div>
  );
};

export default MembershipSubscriptions;
